<template>
  <v-dialog v-model="showDialog" persistent width="550">
    <v-card class="py-10 px-5">
      <v-icon
        class="mt-20"
        size="128"
        style="align-self: center; color: #ffd68a"
      >
        mdi-alert-circle-outline
      </v-icon>

      <v-expand-transition>
        <div class="text-h6 grey--text text--darken-1 mt-5 mb-7">
          Se cambio el cliente desde otra pestaña, serás redirigido al Inicio.
        </div>
      </v-expand-transition>

      <v-btn class="red-button" width="100px" @click="closeModal">OK</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ValidateCustomer",
  data: () => ({
    showDialog: false,
  }),
  async mounted() {
    window.addEventListener("storage", this.onValidate);
  },
  methods: {
    onValidate(event) {
      if (event.key == "currentCustomerCode" && !this.showDialog) {
        this.showDialog = true;
      }
    },
    closeModal() {
      this.$router.push({ name: "Home" });
    },
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onValidate);
  },
};
</script>
