<template>
    <div>
      <template v-if="loading">
        <v-skeleton-loader  elevation="2" type="table-heading, article, table-tfoot" ></v-skeleton-loader>
      </template>
      <v-simple-table v-else class=" my-table pb-0 mb-0" dense>
                <template v-slot:default>
                  <tbody  class="custom-table-bordered"> 
                  <tr>
                    <td class="custom-td-header">% Pagos en línea</td>
                    <td class="custom-td-header">Mes</td>
                    <td v-for="(date, index) in months" :key="index" class="custom-td-header">{{date.date}}</td> 
                  </tr>
                  <tr>
                    <td rowspan="10"><b class="text-black">
                      <v-tooltip bottom class="align-self-center">
                            <template v-slot:activator="{ on, attrs }">
                              <span  v-bind="attrs"  v-on="on">{{formatFloat(billing.PPAGL,2)}}%  </span>
                            </template>
                            <span>Montos de pago del mes: <b>{{formatNumber(billing.MONPAGPER,"$",0)}}</b> <br>
                                  Montos de pago vía IEL: <b>{{formatNumber(billing.MONPAGIEL,"$",0)}}</b> </span>
                      </v-tooltip>
                    </b> 
                    </td>
                  </tr>
                  <tr v-for="(item,index) in billing.BILLING" :key="index">
                    <td v-html="item.TITULO " class="custom-td-header"></td>
                    <td :class="setItemClass(index,item.MES6M,item.MIN,item.MAX)" >
                      <template v-if="hasLink(index)"> 
                        <a @click="actionLink(index,months[6])">{{setFormatItem(index,item.MES6M,item.PMES6M)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MES6M)}}</template> 
                    </td>
                    <td :class="setItemClass(index,item.MES5M,item.MIN,item.MAX)" >
                      <template v-if="hasLink(index)"> 
                        <a @click="actionLink(index,months[5])">{{setFormatItem(index,item.MES5M,item.PMES5M)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MES5M)}}</template> 
                    </td>
                    <td :class="setItemClass(index,item.MES4M,item.MIN,item.MAX)" >
                      <template v-if="hasLink(index)"> 
                        <a @click="actionLink(index,months[4])">{{setFormatItem(index,item.MES4M,item.PMES4M)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MES4M)}}</template> 
                    </td>
                    <td :class="setItemClass(index,item.MES3M,item.MIN,item.MAX)" >
                      <template v-if="hasLink(index)"> 
                        <a @click="actionLink(index,months[3])">{{setFormatItem(index,item.MES3M,item.PMES3M)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MES3M)}}</template>
                    </td>
                    <td :class="setItemClass(index,item.MES2M,item.MIN,item.MAX)" >
                      <template v-if="hasLink(index)"> 
                        <a @click="actionLink(index,months[2])">{{setFormatItem(index,item.MES2M,item.PMES2M)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MES2M)}}</template> 
                    </td>
                    <td :class="setItemClass(index,item.MESANT,item.MIN,item.MAX)">
                      <template v-if="hasLink(index)"> 
                        <a @click="actionLink(index,months[1])">{{setFormatItem(index,item.MESANT,item.PMESANT)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MESANT)}}</template> 
                    </td>
                    <td :class="setItemClass(index,item.MESACT,item.MIN,item.MAX,true)">    
                      <template v-if="hasLink(index)">
                        <a @click="actionLink(index,months[0])">{{setFormatItem(index,item.MESACT,item.PMESACT)}} </a> 
                      </template>
                      <template v-else>  {{setFormatItem(index,item.MESACT)}}</template>
                    </td>
                  </tr>  
                 <!-- <tr >
                 <!- Se implementará en v2 ->
                    <td colspan="2" class="text-decoration-underline font-weight-bold custom-td-header custom-text-yellow" >
                      <v-progress-circular v-if="loadingAvgDelivery" indeterminate color="primary" /> Dias habiles tiempo de ciclo de venta 
                    </td>
                    <template v-if="isTrim">
                      <td class="custom-td-header font-weight-bold" v-for="(item,index) in avgDelivery.slice(0,7)" :key="index">
                        Trim {{item.SPMON}}
                      </td>
                    </template>
                    <template v-else>
                      <td class=""  v-for="(item,index) in avgDelivery.slice(0,7)" :key="index">{{ number_format(item.AVGORDERS_DAYS,false) }}</td>
                    </template>
                  </tr> 
                  --> 
                  <tr>
                    <td colspan="2">
                      <div class="d-inline-flex">
                        <v-checkbox class="ml-2" :disabled="loadingAvgDelivery" v-model="isTrim" dense>
                          <template v-slot:label>
                            <div class="text-black">Trimestral</div>
                          </template>
                        </v-checkbox>
                        <v-checkbox class="ml-2" :disabled="loadingAvgDelivery" v-model="monthly" dense>
                          <template v-slot:label>
                            <div class="text-black">Mensual</div>
                          </template>
                        </v-checkbox>
                      </div>
                    </td>
                    <template v-if="isTrim">
                    <td v-for="(item,index) in avgDelivery.slice(0,7)" :key="index">{{ number_format(item.AVGORDERS_DAYS,false) }}</td>
                    </template>
                  </tr>
                  </tbody>      
                </template>
      </v-simple-table>  

      <new-products 
      v-model="newProductsModal" 
      :period="newProductsPeriod" 
      :chnl="chnl"
      :regionalManager="regionalManager"
      />
      
      <customers-payment-i-e-l 
         :managerCode="0"
         :employeeChannel = "chnl" 
         v-model="showPaymentsViaIEL"
         :period = "ShowPaymentsViaIELPeriod" 
        :regionalManager="regionalManager"
         />

      </div>
</template>

<script>  
import axios from '@/plugins/axios'
import numbersFormats from '@/mixins/numbersFormats';
import dates from '@/mixins/dates'; 
import service from '@/mixins/service';
import NewProducts from '@/views/diary/director/modals/NewProducts.vue'
import CustomersPaymentIEL from '@/components/modals/manager/CustomersPaymentIEL.vue'

export default({
    name:'Orders',
    components:{ 
      NewProducts,
      CustomersPaymentIEL
  },
  mixins:[numbersFormats,service,dates],
  props: {
      chnl: { 
        required:true,
        String
      },
      regionalManager:{
        required:true,
      }
    },
    data: () => ({ 
    showModal: false, 
    monthly: false, 
    trim: true, 
    paymentHistory: false, 
    customerPerWeek:false,
    loading:true,
    billing:[],
    months:[],
    isTrim:true,
    avgDelivery:[],
    loadingAvgDelivery:false,
    newProductsModal:false,
    newProductsPeriod:"", 
    showPaymentsViaIEL:false,
    ShowPaymentsViaIELPeriod:"",
  }),
  methods:{
  hasLink(index){
    return index == 5 || index == 8;
  },
  actionLink(index,period){ 
    switch (index) {
      case 5 : this.showNewProducts(period); break; 
      case 8 : this.showCustomersViaIEl(period); break; 
    }
    },
    showCustomersViaIEl(date){ 
      this.ShowPaymentsViaIELPeriod = this.periodFormat(date); 
      this.showPaymentsViaIEL =true;
    },
    showNewProducts(period){ 
      this.newProductsPeriod = this.periodFormat(period);
      this.newProductsModal = true;
    },
    setItemClass(index,value,min,max,isCurrent=false){
      switch (true) {
        case index < 4 || index > 8:
          if (value == max && value!=0 && !isCurrent) {
              return 'text-success font-weight-bold';
          }
          if (value ==min && !isCurrent) {
            return 'text-danger font-weight-bold'
          }
        break;
        case index == 5 || index == 6:
          return 'text-secondary text-decoration-underline';
        case index == 8:
          return 'text-decoration-underline'
      }
      return '' 
    },
  async getBilling(){
      this.loading=true;
        try { 
          var params = {
            channel: this.chnl,
          }
          if (this.regionalManager) params.rgManager = this.regionalManager;
          const response = await axios.get("/daily-director/director-billing", {
                params: params
              });
          this.billing = response.data;
        } catch (error) {
          this.error = "No se pudo obtener la información";
          this.loading=false;
        } 
        this.loading=false; 
      },
  setFormatItem(index,item,percent=0){
    switch (index) {
      case 0: case 1: case 2:  case 9:
        return this.formatNumber(item,"$",0);
      case 7:
        return `${this.formatNumber(item,"$",0)} / ${this.formatFloat(percent,2)}%` 
      case 5: case 6: case 8:
        return `${this.formatFloat(item,2)}%`;
      default:
        return item 
    }
  },
    closeModal() {
      this.showModal = false;
    }, 
  },
  mounted(){
    this.getBilling();
  },
  created(){
    this.months = this.calcularUltimosMeses(7).reverse(); 
  }, 
  watch: {
    trim(value) {
      this.monthly = value ? false : true;
    },
    monthly(value) {
      this.trim = value ? false : true;
    },
  },


})
</script>