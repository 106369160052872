<template>
  <div class="row d-flex justify-content-center">
                <div class="col-md-12" id="div-a-imprimir">
                    <v-data-table
                        :headers="headers"
                        :items="items" 
                        :footer-props="footerProps"
                        :options="dataTableOptions"
                        :sort-by="sortBy"
                        :sort-desc="sortDesc"
                        :loading="isLoading"
                        :mobile-breakpoint="null"
                        class="elevation-2 mt-2 table-renglonaje"                
                    >    
                        <!-- <template v-slot:header>
                            <thead>                                
                                <tr>
                                    <th class="text-center" v-for="header in headers" :key="header.value" :align="header.align" @click="sortByColumn(header.value)" :class="{ 'sortable': sortBy === header.value }">
                                        <div class="d-flex justify-content-center"
                                            @mouseover="hoverColumn = header.value"
                                            @mouseleave="hoverColumn = ''">
                                            <b>{{ header.text }}</b>                                    
                                            <div style="width: 20px">
                                                <v-icon small v-show="sortBy === header.value || hoverColumn === header.value" :class="{ 'active': sortDesc }">
                                                    {{ sortDesc ? 'mdi-arrow-up' : 'mdi-arrow-down' }}
                                                </v-icon>
                                            </div>  
                                        </div>                                  
                                    </th>
                                </tr>
                            </thead>
                        </template>  -->

                        <template v-slot:[`item.id`]="{ item }">
                            {{ items.indexOf(item) + 1 }}
                        </template> 
                    
                        <template v-slot:[`item.BEZEI`]="{ item }">
                            <a class="link_table" href="#" @click.prevent="sendFamilia(item)">{{ item.BEZEI }}</a>
                        </template>

                        <template v-slot:[`item.TOTAL`]="{ item }" >
                            <p class="mb-0" style="text-align:center">${{ item.TOTAL | formatCurrency}}</p>
                        </template>

                        <template v-slot:[`item.PORC`]="{ item }" >
                            <p class="mb-0" style="text-align:center">{{ item.PORC }}%</p>
                        </template>

                        <template v-slot:foot>
                            <tfoot>
                                <tr>
                                    <td style="text-align: right" :colspan="5"><b>TOTAL $ / %</b></td>
                                    <td style="text-align:center"><b>${{sumaPeriodo | formatCurrency}}</b></td>
                                    <td style="text-align:center"><b>{{sumaPorcentaje}}%</b></td>
                                </tr>
                            </tfoot>
                        </template>
                    </v-data-table>
                </div>
            </div>
</template>

<script>
import moment from 'moment'
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        isLoading: {
            type: Boolean,
            required: true
        },
        materials: {
            type: Array,
            required: true
        }
    },    
    data(){
        return{
            today: moment().format('YYYY-MM-DD'),
            headers: [
                { text: 'N°', value: 'id', align:"center" },
                { text: 'División', value: 'DIVI', align:"center" },
                { text: 'Subdivisión', value: 'EWBEZ', align:"left" },
                { text: 'División Comercial', value: 'DIVCOM', align:"left" },
                { text: 'Familia', value: 'BEZEI', align:"left" },
                { text: 'Comprados en el periodo', value: 'TOTAL', align:"center" },
                { text: 'Porcentaje Total', value: 'PORC', align:"center" },
            ],
            footerProps: {
                itemsPerPageOptions: [10, 14, 24, 34, { text: 'Todas', value: -1 }],
                itemsPerPageText: 'Mostrar en grupo de:',
                showCurrentPage: false,
                pageText: 'Desplegado {0} / {1} de {2} Familias'           
            }, 
            dataTableOptions: {
                itemsPerPage: 24,
                pagination: {
                    enabled: true,
                    options: {
                        numeric: true,                        
                    },
                },
            },     
            sumaPeriodo: 0,
            sumaPorcentaje: 0,
            sortBy: '',
            sortDesc: false,  
            hoverColumn: '',
            itemCSV: [], 
            page: 24
        }
    },
    watch: {
        items: {
            immediate: true, // Ejecutar el watcher inmediatamente al montar el componente
            handler() {
                this.calcularSumaPeriodo();
                this.calcularProcentaje();                
            },
        },
        materials:{ 
            immediate: true, // Ejecutar el watcher inmediatamente al montar el componente
            handler() {
                this.generarExcel();
            }
        }
    },
    methods:{
        calcularSumaPeriodo() {
            let sumaPeriodo = 0;
            for (let i = 0; i < this.items.length; i++) {
                sumaPeriodo += parseFloat(this.items[i].TOTAL);
            }
            this.sumaPeriodo = sumaPeriodo                     
        },
        calcularProcentaje(){
            let sumaPorcentaje = 0;
            for (let i = 0; i < this.items.length; i++) {
                sumaPorcentaje += parseFloat(this.items[i].PORC);
            }
            this.sumaPorcentaje = Number(sumaPorcentaje.toFixed(2))
        },
        sortByColumn(column) {
            if (this.sortBy === column) {
                this.sortDesc = !this.sortDesc;
            } else {
                this.sortBy = column;
                this.sortDesc = false;
            }
        },
        generarExcel(){
            let newItems = []
            this.itemCSV = []
            let id = 1;
            this.items.forEach(family => {
                this.materials.forEach(material => {
                    if(family.MVGR4 === material.MVGR4 && family.EWBEZ === material.EWBEZ){
                        newItems.push({
                            'ID': id,
                            'DIVISION': family.DIVI,
                            'SUBDIVISION': family.EWBEZ,
                            'DIVISION COMERCIAL': family.DIVCOM,
                            'FAMILIA': family.BEZEI,
                            'CODIGO': material.MATNR,
                            'RENGLONES': material.ARKTX,
                            'COMPRADOS EN EL PERIODO': new Intl.NumberFormat('es-MX', { currency: 'MXN' }).format(material.NETWR1),
                            'PORCENTAJE': this.porcentMaterial(material.NETWR1)
                        });
                        id++;
                    }
                });
            });
            

            const sumaObjeto = {
                'ID': '',
                'DIVISION': '',
                'SUBDIVISION': '',
                'DIVISION COMERCIAL': '',
                'FAMILIA': '',
                'CODIGO': '',
                'RENGLONES': 'TOTAL $ / %',
                'COMPRADOS EN EL PERIODO': `$${this.sumaPeriodo}`,
                'PORCENTAJE': `${this.sumaPorcentaje}%`
            };

            this.itemCSV = [...newItems, {...sumaObjeto}]
            this.$emit('sendItemCSV', this.itemCSV)
        },
        sendFamilia(item){
            this.$emit('filterFamily', {MVGR4: item.MVGR4, EWBEZ: item.EWBEZ, ID: item.id})
        },
        porcentMaterial(material){
            if(material > 0){
                let porcent = material * 100 / this.sumaPeriodo;
                return porcent.toFixed(2);
            }
            return material;
        }
    }
}
</script>

<style scoped> 
    table thead tr{
        background: #f2f2f2 !important;
    }

    @media (max-width: 600px){
        .footer-table{
            display: none;
        }
        .footer-responsive{
            display: block !important;
        }
    }
</style>