<template>
  <div>
    <v-card elevation="0" flat>
      <v-card-text class="d-flex flex-wrap">
        <v-simple-table class="mt-4 my-table" dense>
          <template v-slot:default>
            <thead class="table-heading">
              <tr class="th-auto pt-2">
                <th rowspan="2" class="text-center text-dark boder-x-none">
                  <div class="d-flex d-inline-block mb-2">
                    <v-icon size="25" color="#000">mdi-calendar-month-outline</v-icon>
                    <span class="align-self-center">Periodo: </span>
                    <CustomRedSelect class="ml-1" :disabled="loading" v-model="year" :options="yearsOptions" />
                    <CustomRedSelect :disabled="loading" v-model="month" :options="monthsOption" />
                  </div>
                  <div class="d-flex justify-center ">
                    <div class="align-self-end">
                      Zona
                      <span class="d-flex justify-center ma-0">
                        <sort-by-button :data="sellers" column="VORNA" :isString="true" @sortedData="sellers = $event"
                          iconUp="mdi-arrow-up" iconDown="mdi-arrow-down" />
                      </span>
                    </div>
                  </div>
                </th>
                <th v-for="(item, index) in headers" :key="index"
                  class="text-center text-dark custom-border-right pb-3 pt-2">
                  <div class="d-flex justify-center flex-column">
                    <b v-html="item.title.replaceAll(' ', '&nbsp;')"></b>
                    <sort-by-button class="align-self-end w-100" v-if="item.col" :data="sellers" :column="item.col"
                      :isString="true" iconUp="mdi-arrow-up" iconDown="mdi-arrow-down" @sortedData="sellers = $event" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody v-if="!loading" class="custom-white-table">
              <tr v-for="(seller, index) in sellers" :key="index">
                <td style="border-width: 0px 0px 1px 1px">
                  <span class="d-flex">
                    <span class="mt-3 mr-n2 font-weight-bold" style="width:25px">{{ index + 1 }}</span>&nbsp;
                    <v-btn @click="showBillingByDiv(seller.PERNR)" icon>
                      <v-icon color="#B71C1C">mdi-chart-pie</v-icon>
                    </v-btn>
                    <div class="ml-1 mr-1 d-flex align-start flex-column">
                      <div class="text-capitalize font-weight-bold text-decoration-underline">
                        <a @click="showSeller(seller.PERNR)">
                          {{ capitalize(seller.VORNA + ' ' + seller.NACHN + " " + seller.NACH2).replaceAll(' ', '&nbsp;') }}
                        </a>
                      </div>
                      <div>
                        <v-icon>mdi-car</v-icon>
                        <v-icon :color="traffigLightColor(seller.SEMAFO)" size="15">mdi-circle</v-icon>
                        <span style="font-size: 0.7em; font-weight: 750"
                          class="font-weight-bold text-left text-uppercase">
                          {{ (seller.BZIRK) }}
                        </span>
                      </div>
                    </div>

                    <div class="text-right ml-auto">
                      <b class="text-decoration-underline " @click="evalFactAnt(seller.PERNR, seller.NHAB)">{{
                        number_format(seller.NHAB) }} h</b> <br />

                      <div v-if="editHabSeller == seller.PERNR" class="d-flex d-inline">
                        <div class="text-left" style="font-size: 12px">
                          <v-btn icon :disabled="UpdatingCitizens" @click="editHabSeller = 0"> <v-icon
                              color="red">mdi-close</v-icon> </v-btn>
                        </div>
                        <div class="d-flex align-items-center ">
                          <v-text-field hide-details="auto" v-model="number_citizen" solo dense
                            :disabled="UpdatingCitizens" style="width:70px"
                            @input="filterNumber(number_citizen)"></v-text-field>
                          <v-btn icon @click="updateSellerDetails(index)" :loading="UpdatingCitizens">
                            <v-icon class="ml-1">mdi mdi-send-circle</v-icon>
                          </v-btn>

                        </div>
                      </div>
                      <b v-else> ${{ number_format(seller.DAT01) }}&nbsp;S/h</b>
                    </div>
                  </span>
                </td>
                <td>
                  <span class="font-weight-bold font-size-85">${{ seller.INGPAG }}</span>
                </td>
                <td>
                  <span class="font-weight-bold font-size-85">
                    <span>{{ seller.CTESIS }}&nbsp;/&nbsp;{{ seller.CTEACT }}</span>
                    <br />
                    <span class="text-danger">{{ seller.CTEACT }}</span>&nbsp;/
                    <span class="text-success">{{ seller.CTEACT1 }}</span>&nbsp;/&nbsp;
                    <span class="">{{ seller.CTEACT2 }}</span>&nbsp;/&nbsp;
                    <span class="">{{ seller.CTEACT3 }}</span>&nbsp;/
                    <span class="">{{ seller.CTEACT4 }}</span>
                  </span>
                </td>
                <td style="font-size: 0.75em" class="font-weight-bold">
                  <span>{{ number_format(seller.PORCTEPED, false) }} %</span>
                </td>
                <td class="font-size-85">
                  <span class="text--disabled text-decoration-underline">
                    <a @click="showCustomersViaIEL(seller.PERNR)">{{ number_format(seller.PORPIEL) }}%</a></span>
                </td>
                <td class="font-size-85">
                  <span class="text-black">${{ number_format(seller.PTOMESACT) }}</span>
                </td>
                <td class="font-size-85">
                  <span class="text-black">${{ number_format(seller.PEDMESACT) }}</span>
                </td>
                <td class="font-size-85">
                  <span class="text-black">${{ number_format(seller.FACMESACT ) }}</span>
                </td>
                <td class="font-size-85">
                  <span class="text-black">{{ number_format(seller.PTOFACMESACT,false) }}%</span>
                </td>
                <td class="font-weight-bold text-black font-size-85">
                  <span>${{ number_format(seller.PEDDIAA) }}</span>
                </td>
                <td class="text-decoration-underline font-weight-bold text-black font-size-85">
                  <a class="" @click="showCumulativeGrowth(seller.PERNR)"> <span> {{ number_format(seller.PORCREC -
                      100,false)}}% </span> </a>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="10">
                  <v-progress-linear indeterminate color="cyan"></v-progress-linear>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <hr />
      </v-card-text>
    </v-card>
    <v-dialog v-model="showModal" width="auto">
          <v-card class="pb-4">
            <PaymentsViaIELSeller v-if="paymentInfo" 
            @closeModal="closeModal()"
            :period="months[0]" 
            :seller="sellerSelected" 
            :modal="showPopulationInput"
            :channel="managerData.VTWEG"
            :months="months"
            />
            <CumulativeGrowth v-if="cumulativeGrowthModal" 
            :title="`Detalle de crecimiento constante por asesor ${cumulativeGrowthSeller} del mes: ${months[0].date}`"
            :isBilling="isBillingGrowth ? 1 : 0" 
            :seller="cumulativeGrowthSeller"
            :channel="managerData.VTWEG"
            :isSeller="1"
            :type="growthType"
            :period="months[0]" 
            :modal="cumulativeGrowthModal"
            @closeModal="closeModal()"
         />  
          </v-card>
      </v-dialog>

      <billing-by-area 
            :employeeNumber="billingByDivSeller" 
            :employeeChannel="managerData.VTWEG"
            :period="months[0]"
            v-model="billingByDivModal" 
            :title="`Detalle de zona ${billingByDivSeller}`"
        />
    </div>
</template>
<script>
import CustomRedSelect from './components/CustomSelectRed.vue';
import CumulativeGrowth from './modals/CumulativeGrowth.vue';
import PaymentsViaIELSeller from './modals/PaymentsViaIELSeller.vue';
import BillingByArea from '@/components/modals/BillingByArea.vue';
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers'
import axios from "@/plugins/axios";
import moment from 'moment';
import SortByButton from '@/components/SortByButton.vue';

export default ({
  name: 'SellerList',
  mixins: [MixinService, MixinHelpers],
  props: ['manager', 'managerData'],
  components: {
    CustomRedSelect,
    PaymentsViaIELSeller,
    CumulativeGrowth,
    BillingByArea,
    SortByButton
  },
  data: () => ({ 
    showModal: false, 
    showPopulationInput: false,   
    paymentInfo: false,
    months: [],
    sellers: [],
    loading: true,
    year: 2024,
    month: 3,
    monthsOption: [],
    yearsOptions: [],
    today: moment(),
    cumulativeGrowthModal: false,
    cumulativeGrowthSeller: 0,
    growthType: 0,
    sellerSelected: 0,
    billingByDivModal: false,
    billingByDivSeller: 0,
    editHabSeller: 0,
    number_citizen: 0,
    UpdatingCitizens: false,

  }),
  created() {
    this.months = this.calcularUltimosMeses(5);
    this.monthsOption = this.getMonthsFromYear(this.today.year()).reverse()
    this.yearsOptions = this.getYearsList(2)
    this.year = this.today.year()
    this.month = this.today.format('M')
  },
  watch: {
    manager() {
      this.loadSellers();
    },
    year(newValue, oldValue) {
      if (newValue == this.today.year()) {
        if (Number(this.month) > Number(this.today.format('M'))) {
          this.month = this.today.format('M');
        }
      }
      if (newValue != oldValue) {
        this.monthsOption = this.getMonthsFromYear(this.year)
        this.months = this.calcularUltimosMeses(5, this.month, newValue);
        this.loadSellers();
      }

    },
    month(newValue) {
      if (this.manager) {
        this.loadSellers();
        this.months = this.calcularUltimosMeses(5, newValue, this.year);
      }
    },
    showModal(value) {
      if (!value) {
        this.cumulativeGrowthModal = false;
        this.paymentInfo = false;
        this.billingByDivModal = false;
      }
    }

  },
  computed: {
    headers() {
      return [
        { title: 'Ingresos', col: 'INGPAG' },
        { title: `# de clientes en Sist / Act <br> con compra <br> ${this.getMonths()}`, col: 'ANTIG' },
        { title: '% de clientes con <br> pedidos', col: 'PORCTEPED' },
        { title: '% de <br> clientes con <br> pagos vía <br> IEL', col: 'PORPIEL' },
        { title: `Presupuesto <br>${this.months[0].full_name}`, col: 'PTOMESACT' },
        { title: `Pedidos <br>${this.months[0].full_name}`, col: 'PEDMESACT' },
        { title: `Facturación <br>de ${this.months[0].full_name}`, col: 'FACMESACT' },
        { title: `Avance de <br> facturación <br> VS <br>ppto de ${this.months[0].full_name}`, col: 'PTOFACMESACT' },
        { title: 'Pedidos del <br>día anterior', col: 'PEDDIAA' },
        { title: `Crec. Acum. <br>${this.months[1].date}`, col: 'CRECACU' },
      ];
    }
  },
  methods: {
    showSeller(seller_id) {
      localStorage.setItem('currentSellerCode', JSON.stringify(seller_id));
      this.$router.push({ name: 'SellerResults' });
    },
    filterNumber(number_citizen) {
      //Evitamos escribir todon lo que no sea números
      this.$nextTick(() => {
        this.number_citizen = number_citizen.replace(/\D/g, "")
      });
    },
    async updateSellerDetails(index) {
      this.UpdatingCitizens = true;
      try {
        if (this.number_citizen != '') {
          let response = await axios.put(`seller/home/update/${this.editHabSeller}`, {
            number_citizen: this.number_citizen
          })
          if (response.data) {
            this.UpdatingCitizens = false;
            this.sellers[index].NHAB = this.number_citizen;
            this.sellers[index].DAT01 = this.number_format(this.sellers[index].FACNETA / this.number_citizen, false);
            this.editHabSeller = 0

          }
        }
      } catch (error) {
        this.editHabSeller = 0
        this.UpdatingCitizens = false;
        console.error(error)
      }

    },
    evalFactAnt(seller, value) {
      this.editHabSeller = Number(seller);
      this.number_citizen = value;
    },
    showCumulativeGrowth(seller, type = "") {
      this.cumulativeGrowthModal = true;
      this.cumulativeGrowthSeller = seller;
      this.growthType = type;
      this.showModal = true;
    },
    showBillingByDiv(seller) {
      this.billingByDivModal = true;
      this.billingByDivSeller = seller;
    },
    getMonths() {
      var months = this.months;
      return this.implode(this.arrayPluck(months, 'short_name'), ' / ')
    },
    traffigLightColor(value) {
      switch (value) {
        case "1": return "#FF0000";
        case "2": return "orange";
        case "3": return "#0BDA51";
        default: return "gray";
      }
    },
    loadSellers() {
      this.loading = true;
      axios.post('/manager/home/sellers', {
        manager: this.managerData.PERNR,
        channel: this.managerData.VTWEG,
        month: this.month,
        year: this.year,

      }).then((res) => {
        this.sellers = res.data;
        this.loading = false;
        this.$emit('desactiveLoader');
      })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$emit('desactiveLoader');
        });
    },

    showCustomersViaIEL(customer) {
      this.sellerSelected = customer;
      this.showModal = true;
      this.paymentInfo = true;
    },
    closeModal() {

      this.showModal = false;
    },
  }
})
</script>

<style>
.th-auto th {
  height: auto !important;
  padding-bottom: 0px !important;
}
</style>