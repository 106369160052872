<template>
  <div id="contenedor" ref="printHomePage">
    <v-container fluid class="white lighten-5">
      <GuestView v-if="$hasAnyRole(['Guest'])"></GuestView>
      <ReportsView v-if="$hasAnyRole(['Reports'])"></ReportsView>
       
      <loading-bar :capturedDialogLoading="dialogLoading"></loading-bar>
      <div id="cards">
        <v-row v-if="!$hasAnyRole(['Guest'])">
          <v-col cols="12">
            <CustomerInformation @customerInfoloaded="customerLoaded" :customer="customer"></CustomerInformation>
            <ContactDesk :generalInfoCustomer="customer"></ContactDesk>

            <fill-rate 
            :userId="customer.KUNNR" 
            option="C" 
            :chnl="customer.VTWEG" />

            <order-delivery-time 
            :customer="customer.KUNNR" />

            <WalletInformation :customer="customer" @walletLoaded="walletLoaded" />
            <HistoryShopping :generalInfoCustomer="customer" :walletInformation="walletInformation"  />
            <IndicatorsPayment :generalInfoCustomer="customer" />
            <GrowthIndicators :generalInfoCustomer="customer" />
            <GrowthBenefitHistory :customer="customer.KUNNR" v-if="customer.VTWEG == 'PR'" />
            <QuarterlyBonus :generalInfoCustomer="customer" />
            <TotalAdditionalBenefits :generalInfoCustomer="customer" />
          </v-col>
          <ConfidentialityNotice></ConfidentialityNotice>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import ContactDesk from "@/components/ContactDesk.vue";
import FillRate from "@/views/fill_rate/FillRate.vue";
import WalletInformation from "@/views/wallet/WalletInformation.vue";
import HistoryShopping from "../components/HistoryShopping.vue";
import IndicatorsPayment from "./indicators_payment/IndicatorsPayment.vue";
import QuarterlyBonus from "./quarterly_bonus/QuarterlyBonus.vue";
import GuestView from "@/views/auth/GuestView.vue";
import CustomerInformation from "@/views/auth/CustomerInformation.vue";
import ReportsView from "@/views/auth/ReportsView.vue";
import GrowthBenefitHistory from '@/views/growth_benefit_history/GrowthBenefitHistory.vue'
import TotalAdditionalBenefits from "@/views/total_benefits/TotalAdditionalBenefits.vue";
import GrowthIndicators from './growth_indicators/GrowthIndicators.vue'
import LoadingBar from "./shopping_cart/componets/LoadingBar.vue";
import imagedesk from "@/assets/img/logo_sd.png";  
import { mapGetters, mapActions } from "vuex";
import ConfidentialityNotice from "@/components/ConfidentialityNotice.vue";
import OrderDeliveryTime  from "./oder_delivery_time/OrderDeliveryTime.vue";
export default {
  components: {
    ContactDesk,
    FillRate,
    WalletInformation,
    HistoryShopping,
    IndicatorsPayment,
    QuarterlyBonus,
    GuestView,
    CustomerInformation,
    ReportsView,
    GrowthIndicators,
    GrowthBenefitHistory,
    TotalAdditionalBenefits,
    LoadingBar,
    ConfidentialityNotice,
    OrderDeliveryTime
  },
  beforeRouteEnter(to, from, next) {
      //se ejecuta el loader solo si venimos desde el componente SignIn
      next(vm => {
          if (from.name === 'SignIn') {
              vm.activeLoader();
          }
      });
  },
  data() {
    return {
      data: null,
      bonus: null,
      imageDesk: imagedesk,
      customer: [],
      dialogLoading: false,
      showPrint: true,
      walletInformation:[],
    };
  },
  methods: {
    ...mapActions({
        sendToPDF: 'printer/sendToPDF',
        activeLoader: 'loader/activeLoader'
    }),
    customerLoaded(customer) {      
      this.customer = customer;
      this.sendToPDF({active: true})
    }, 
    walletLoaded(value){
      this.walletInformation = value;
    }
  },
  computed: {
    currentPeriod() {
      const today = new Date;
      let firstDay = 1;
      //   let lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      //   lastDay = lastDay.getDate()
      let year = today.getFullYear();
      let currentMonth = today.toLocaleString('default', { month: 'long' })
      currentMonth = currentMonth.charAt(0).toUpperCase() + currentMonth.slice(1);
      return ` del (${firstDay} de ${currentMonth} ${year} al ${today.getDate()} de ${currentMonth} del ${year})`;
    },
    ...mapGetters({
      user: "auth/user",
    }),

  },

};
</script> 
<style scoped>
.container-contact-card {
  width: 280px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000004F;
  border-radius: 20px;
}

.contact-card-bordered {
  border-width: 0px 0px 0px 1px;
  border-style: solid;
  border-color: #E2E2E2;
}

.contact-email-text {
  text-decoration: underline;
  font-size: 12px;
  letter-spacing: 0px;
  color: #FF000A;
  font-weight: bold;
  opacity: 1;
}

.contact-phone-text {
  text-decoration: underline;
  font-size: 12px;
  letter-spacing: 0px;
  font-weight: bold;
  color: #0F719D;
}
</style>