<template>
    <div>
        <v-simple-table class="mt-4 my-table" dense>
                <template v-slot:default>
                  <thead class="table-heading">
                    <tr>
                      <th  rowspan="2"  class="text-center text-dark custom-border-right">
                        <b> # de clientes <br> Sistema/Activos <br> clientes nuevos <br> 1 / 3 / 6 meses</b>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right">
                        <div class="d-flex justify-center">
                            <b>#&nbsp;de&nbsp;Clientes&nbsp;con&nbsp;compra<br />
                              Ctes&nbsp;con&nbsp;compras&nbsp;sem<br />
                          </b>
                        </div> 
                        <table class="w-100 custom-table-item-sm">
                          <tr>
                            <td> {{ getMonths }} </td>
                            <td > 
                              <b>Prom 3</b>
                            </td>   
                            <td>Dif</td>
                            <td>{{months[2]?.short_name}}</td>
                          </tr>
                        </table>
                      </th>
                      <th rowspan="2" class="text-center text-dark custom-border-right">
                        <b>%&nbsp;de&nbsp;clientes<br />con&nbsp;pedidos</b>
                      </th>
                      <th colspan="6" class="text-center text-dark custom-border-right">
                        <b class="">Facturación Neta</b>
                      </th>
                    </tr>
                    <tr> 
                      <th class="text-center text-dark" v-for="(item,index) in quarters" :key="index"><b>Trimestre<br />{{ item.date }}</b></th> 
                    </tr>
                  </thead>
                  <tbody v-if="!loading" class="custom-table-bordered">
                    <tr class="text-body-2">
                      <td><b class="text-danger">0 / 0 / 0 </b></td>
                      <td>
                        <table class="text-center w-100 custom-table-item-sm">
                          <tr  v-for="(item,index) in billing.CUSTOMERS" :key="index">
                            <td> 
                              <template v-if="index > 1">
                                  <a @click="actionMonth(index,0)">
                                    <span  class="text-decoration-underline">  {{number_format(item.CURRENT)}}</span>
                                  </a>&nbsp;/&nbsp;
                                  <a @click="actionMonth(index,1)">
                                    <span  class="text-decoration-underline">{{number_format(item.MONTH1)}}</span>
                                  </a> 
                                </template>
                                <template v-else> 
                                  <span  class=""> {{number_format(item.CURRENT)}}</span>
                                   &nbsp;/&nbsp; 
                                  <span  class="">{{number_format(item.MONTH1)}}</span> 
                                </template>
                            </td> 
                            <td >
                              <!-- Average 3/12--> 
                               <template v-if="index != 1">
                                  <a @click="actionAverage(index)"  class="text-decoration-underline">{{  number_format(item.AVERAGE3) }}</a>
                                </template>
                                <template v-else>
                                   {{  number_format( item.AVERAGE3) }} 
                                </template>
                              </td>
                            <td>
                              <span>{{ number_format(item.MONTH2 - item.AVERAGE3)}}</span></td>
                            <td>
                              <a @click="actionMonth(index,2)">
                                  <span :class="index > 1 ? 'text-decoration-underline':''">{{number_format(item.MONTH2)}}</span>
                                  </a>
                            </td>
                          </tr> 
                        </table>
                      </td>
                      <td>{{number_format(billing.PCPED,false)}}%</td>
                      <td 
                      :class="index!='FACTRIM' ? billingColorClass(item) : 'text-black'" 
                      v-for="(item,index) in billing.FACT" :key="index">${{number_format(item) }} </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <th colspan="12">
                        <v-progress-linear
      indeterminate
      color="cyan"
    ></v-progress-linear>
                      </th>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>  

      <v-dialog :persistent="persistentModal" v-model="showModal" width="auto">
        <v-card class="pb-4"> 
          <CustomerSalesVue v-if="customersWithSales"  @closeModal="closeModal" @loadingCustomersWithSales="modalLoader"
          :period="periodSelected" 
          :modal="showModal" 
          :managerData="managerData"
          :months="months"
          :threeMonths="true"
          />
          
          <ZoneWithSales v-if="zoneWithSalesModal" v-on:closeModal="closeModal"></ZoneWithSales> 
          <PaymentInfo v-if="paymentInfoModal" v-on:closeModal="closeModal" :period="periodSelected" :modal="showModal" :managerData="managerData"/> 
          <PaymentViaIEL v-if="averageCustomersViaIELModal" v-on:closeModal="closeModal" 
          :period="periodSelected" 
          :modal="showModal" 
          :managerData="managerData"
          :months="months"
          :threeMonths="true"/> 
          <CustomersBuyWeekly v-if="customersBuyWeekly" @loadingCustomerBuyWeekly="modalLoader"   v-on:closeModal="closeModal"
          :manager="manager"
          :modal="showModal" 
          :managerData="managerData"
          :months="months" 
          :period="periodSelected"
           /> 
          <CustomersBuyMonthly v-if="customersBuyMonthly" @loadingCustomerBuyMonthly="modalLoader"   v-on:closeModal="closeModal"
          :manager="manager"
          :modal="showModal" 
          :managerData="managerData"
          :months="months" 
          :period="periodSelected"
          :threeMonths="true"  /> 
           
        </v-card>
      </v-dialog> 

    </div>
</template>

<script>
import CustomerSalesVue from './modals/CustomerSales.vue'
import CustomersBuyWeekly from './modals/CustomersBuyWeekly.vue'; 
import PaymentInfo from './modals/PaymentInfo.vue';
import PaymentViaIEL from './modals/PaymentViaIEL.vue';
import ZoneWithSales from './modals/ZoneWithSales.vue'; 
import CustomersBuyMonthly from './modals/CustomersBuyMonthly.vue'; 
import MixinService from '@/mixins/service'
import MixinHelpers from '@/mixins/helpers';
import axios from "@/plugins/axios";
export default ({
    name:'NetSales',
    props: ['manager','managerData'],
    mixins: [MixinService,MixinHelpers],
    components:{
        CustomerSalesVue,
        CustomersBuyWeekly,
        PaymentInfo,
        ZoneWithSales,
        PaymentViaIEL,
        CustomersBuyMonthly
    },
    watch:{
     manager(){
        this.loadBilling();
      },
      showModal(value){
         if (!value) { 
          this.customersWithSales = false;
          this.customersBuyWeekly = false;
          this.zoneWithSalesModal = false;
          this.paymentInfoModal=false;
          this.averageCustomersViaIELModal=false;
          this.customersBuyMonthly=false;
         }
      } 
    },
    data: () => ({ 
        customersBuyWeekly:false,
        showModal:false,
        zoneWithSalesModal:false,
        paymentInfoModal:false,
        billing:{},
        loading:false,
        months:{},
        quarters:[],
        persistentModal:true,
        periodSelected:[],
        averageCustomersViaIELModal:false, 
        customersBuyMonthly:false,
        customersWithSales:false,
    }),
    created(){
      this.months = this.calcularUltimosMeses(13);
      this.quarters = this.calcularUltimosTrim(6).reverse();
    }, 
    computed:{
      getMonths(){ 
        var months = this.months;  
        return this.implode(this.arrayPluck(months.slice(0, 2),'short_name'),'/') 
      }, 
    },
    methods:{ 
      actionMonth(index,col){
        switch (index) {
          case 2: this.showcustomersBuyWeekly(col); break;
          case 3: this.showPaymentInfo(col); break; 
        }
      },
      actionAverage(item){
        switch (item) {
          case 0:this.showCustomersWithSales(); break;
          case 2:this.showCustomersWithMonthlyBuy(); break;
          case 3:this.showAverageCustomersViaIEL(); break; 
        }
      },
      showcustomersBuyWeekly(period){
        this.periodSelected  = this.months[period]
        this.showModal = true; 
        this.customersBuyWeekly=true; 
      },
      showCustomersWithSales(period=0){
        this.periodSelected  = this.months[period]
        this.showModal = true; 
        this.customersWithSales=true; 
      }, 
      showCustomersWithMonthlyBuy(period=0){  
        this.periodSelected  = this.months[period]
        this.showModal = true; 
        this.customersBuyMonthly=true;
      },
      showAverageCustomersViaIEL(){ 
        this.periodSelected  = this.months[0]
        this.showModal = true; 
        this.averageCustomersViaIELModal=true;
      },
      showPaymentInfo(month){ 
        this.periodSelected  = this.months[month]
        this.showModal = true;
        this.paymentInfoModal=true; 
      },
      modalLoader(value){
        console.log(value);
        this.persistentModal=value;
      },
      billingColorClass(billing){
       if (billing == this.billing.MAXFACT && billing!=0) {
         return 'text-success'
       }
       if (billing == this.billing.MINFACT) {
         return 'text-danger';
       }
       return 'text-black'
     } ,  
      showInfo(item)
        { 
            this.showModal = true;
            switch (item) {
                case 1:
                this.customerSales = true; 
                break;  
                case 2: 
                this.zoneWithSalesModal = true; 
                break;     
                case 3: 
                this.customersBuyWeekly = true;  
                break;     
            } 
        },
        closeModal() {
      this.showModal = false;
    },
    loadBilling(){  
      this.loading=true;
      axios.post('/manager/home/billing',{ 
          manager: this.manager, 
          channel: this.managerData.VTWEG
        }).then((res) => { 
          this.billing = res.data;
          this.loading = false;
      })
      .catch((err) => { 
            console.log(err);
            this.loading = false;
          }) ;  
    }
    }
})
</script>