<template>
	<div class="container-fluid">
			<cardTitle title="TIEMPO DE ENTREGA DE PEDIDOS EN LOS ÚLTIMOS 3 MESES" icon="calendar-clock-outline">
			<template v-slot:content>
				<alert-prominent class="mb-0" v-if="errors" :message="errors" :disabledButton="loading" /> 
	
				<v-progress-linear :active="loading" :indeterminate="loading" top
					color="deep-purple accent-4"></v-progress-linear>
				<v-card>
					<v-simple-table dense class="box-shadow d-none d-sm-block"  v-if="!errors && !loading">
						<template v-slot:default>
							<thead>
								<tr class="font-weight-bold  text-black">
									<td v-for="(item, index) in headers_order_delivery_time" :key="index"
										class="box-shadow">{{ item.text }}</td>
								</tr>
							</thead>
							<tbody>
								<tr class="font-size-x ">
									<td class=" pl-10 no-border" style=""> {{ dataODT?.PED_TOTALES ? dataODT?.PED_TOTALES :
										0
									}}</td>
									<td style="border-width: 0px;  box-shadow: inset 8px 0 5px -5px #00000029; ">
										{{ dataODT.PED_PRODENT ? dataODT.PED_PRODENT : 0 | formatCurrency }} </td>
									<td style="border-width: 0px;">{{ dataODT.PED_ENT1DIA }}
										({{ calculatePercent(dataODT.PED_ENT1DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT.PED_ENT2DIA }}
										({{ calculatePercent(dataODT.PED_ENT2DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT.PED_ENT3DIA }}
										({{ calculatePercent(dataODT.PED_ENT3DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT?.PED_ENT4DIA }}
										({{ calculatePercent(dataODT?.PED_ENT4DIA) }}%)</td>
									<td style="border-width: 0px;">{{ dataODT?.PED_ENT5DIA }}
										({{ calculatePercent(dataODT?.PED_ENT5DIA) }}%)</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<!-- movile desng -->
					<v-simple-table v-if="!loading && !errors" dense class="box-shadow d-sm-none d-block">
						<template v-slot:default>
							<thead>
								<tr class="text-black font-size-x " v-for="(item, index) in headers_order_delivery_time"
									:key="index">
									<td class="box-shadow font-weight-bold">{{ item.text }}</td>
									<td class="box-shadow" v-if="index == 0">{{ dataODT[item.value] }}</td>
									<td class="box-shadow" v-else-if="index == 1">{{ dataODT[item.value] | formatCurrency }}
									</td>
									<td v-else class="box-shadow"> {{ dataODT[item.value] }}&nbsp;({{
										calculatePercent(dataODT[item.value]) }}%) </td>
								</tr>
							</thead>
						</template>
					</v-simple-table>
					<!-- end movile desng -->
				</v-card>
			</template>
		</cardTitle>

	</div>
</template>
  
<script>
import CardTitle from "@/components/CardTitle.vue";
import axios from "@/plugins/axios"; 
import service from "@/mixins/service";
import numbersFormats from "@/mixins/numbersFormats";
import AlertProminent from "@/components/AlertProminent.vue";
export default {
	name: "OrderDeliveryTime",
	components: { CardTitle,AlertProminent },
	mixins:[service,numbersFormats],
	props: {
		customer: {
			required: true,
		},
	},
	watch: {
		customer() {
			this.submitOrderDeliveryTime()
		}
	},
	data() {
		return {
			dataODT: [],
			loading: true,
			errors: "",
			headers_order_delivery_time: [
				{ text: "Pedidos Totales", align: 'center', value: "PED_TOTALES" },
				{ text: "promedio de días de entrega", align: 'center', value: "PED_PRODENT" },
				{ text: "Entregados en 1 día hábil", align: 'center', value: "PED_ENT1DIA" },
				{ text: "Entregados en 2 días hábiles", align: 'center', value: "PED_ENT2DIA", },
				{ text: "Entregados en 3 días hábiles", align: 'center', value: "PED_ENT3DIA" },
				{ text: "Entregados en 4 días hábiles", align: 'center', value: "PED_ENT4DIA" },
				{ text: "Entregados en 5 días hábiles o más", align: 'center', value: "PED_ENT5DIA" },
			], 
		};
	},
	methods: {
	async submitOrderDeliveryTime() {
			this.loading = true;
			const payload = { client:  this.customer };
			const requestConfig = {
							noCancelRequest:true,
							cachable:true,
						};
			try {
				const response = await axios.post("orderDeliveryTime",payload,requestConfig)
				this.dataODT = response.data.dataOrderDeliveryTime[0];
				this.errors = ""
				this.loading = false;
			} catch (error) {
			this.errors = error;
			this.loading = false;
			} 
		},
		calculatePercent(value) {
			value = Number(value) ? value : 0;
			const total = Number(this.dataODT?.PED_TOTALES) ? this.dataODT?.PED_TOTALES : 0;
			if (value == 0 && total == 0 || total == 0) {
				return 0;
			}
			value = (value / total) * 100;
			return this.formatFloat(value,2);
		},
	},
	mounted() {
		this.months = this.calcularUltimosMeses(6);
	},

};
</script>
  
<style scoped>
.custom-table {
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	max-width: 300px;
	/* Ajusta el ancho máximo según tus necesidades */
}

.custom-row {
	display: flex;
	flex-direction: row;
	border-top: 1px solid #ccc;
}

.custom-cell {
	flex: 1;
	padding: 8px;
	text-align: left;
	border: 2px;
}

.header {
	font-weight: bold;
	background-color: #f2f2f2;
	/* Cambia el color de fondo según tus preferencias */
}





.sticky-column {
	border-width: 0px;
	left: 0;
}

.font-size-x>td {
	font-size: 14px !important;
}

.all-borders-bsadow {
	box-shadow: inset -5px 0 5px -5px #00000029;
}

.label {
	font-weight: 700;
}

.no-border {
	border-width: 0px !important;
}

.no-border>td {
	border-width: 0px !important;
}

.box-shadow {
	box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
	opacity: 1;
}

.box {
	height: 150px;
	width: 300px;
	margin: 20px;
	border: 1px solid #ccc;
}

.box-top {
	box-shadow: 0 -5px 5px -5px #00000029;
}

.box-right {
	box-shadow: 5px 0 5px -5px #00000029;
}

.box-bottom {
	box-shadow: 0 5px 5px -5px #00000029;
}

.box-left {
	box-shadow: -5px 0 5px -5px #00000029;
}

.box-left-inset {
	box-shadow: inset -5px 0 5px -5px #00000029;
}

.box-right-inset {
	box-shadow: 5px 0 5px -5px #00000029;
}

.box-all {
	box-shadow: 0 0 5px #00000029;
}

@media (max-width: 600px) {
	.sticky-column {
		border-width: 0px;
		position: sticky;
		left: 0;
		padding: 1px !important;
		background-color: #fff;
		/* Cambia el color de fondo según tus preferencias */
		z-index: 1;
		/* Asegura que la columna fija esté por encima de las demás celdas */
	}

	.font-size-x>td {
		font-size: 11px !important;
	}
}

@media (min-width: 601px) and (max-width:960px) {
	.font-size-x>td {
		font-size: 12px !important;
	}
}
</style>