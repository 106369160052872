<template>
  <v-sheet width="100%" class="pb-4">
    <div class="mx-4 mt-4">
      <v-card>
        <v-card-title class="headline">Gestionar Contraseña</v-card-title>
        <v-card-text class="text-left">
          <v-alert
            v-if="hasError"
            text
            outlined
            color="deep-orange"
            class="text-caption"
          >
            {{ message }}
          </v-alert>

          <v-alert
            v-if="messages.length > 0"
            text
            outlined
            color="deep-orange"
            class="text-caption"
          >
            <div v-for="(m, index) in messages" :key="index">{{ m }}</div>
          </v-alert>

          <v-form
            ref="passwordForm"
            v-model="localValidForm"
            @submit.prevent="changePassword"
          >
            <password-form
              :value="passwordForm"
              @input="updatePasswordForm"
              ref="passwordInputs"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success darken-1"
            @click="changePassword"
            :loading="updatingPassword"
            :disabled="!localValidForm"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-sheet>
</template>

<script>
import PasswordForm from '@/components/forms/PasswordForm'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  name: 'UpdatePasswordModal',

  components: {
    PasswordForm,
  },

  props: {
    value: {
      type: Boolean,
      default: true,
    },
    noPersistent: Boolean,
  },

  data() {
    return {
      localValidForm: false,
    }
  },

  computed: {
    ...mapState('updatePassword', [
      'passwordForm',
      'updatingPassword',
      'hasError',
      'message',
      'messages',
    ]),

    showModal: {
      get() {
        return this.value
      },
      set(newValue) {
        if (!newValue) {
          this.resetForm()
        }
        this.$emit('input', newValue)
      },
    },
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', ['findStepByKey', 'updateStepStatus']),
    ...mapActions('updatePassword', ['updatePassword']),
    ...mapMutations('updatePassword', ['SET_VALID_FORM', 'RESET_FORM', 'UPDATE_PASSWORD_FORM']),

    updatePasswordForm(newValue) {
      // Actualizar cada campo del formulario individualmente
      Object.entries(newValue).forEach(([field, value]) => {
        this.UPDATE_PASSWORD_FORM({ field, value })
      })
    },

    async validate() {
      if (this.$refs.passwordForm) {
        const isValid = await this.$refs.passwordForm.validate()
        this.SET_VALID_FORM(isValid)
        return isValid
      }
      return false
    },

    async changePassword() {
      const isValid = await this.validate()
      if (!isValid) return

      const username = localStorage.getItem('username')
      if (!username) {
        console.error('No se encontró el nombre de usuario')
        return
      }

      const success = await this.updatePassword({
        username,
        successCallback: () => {
          this.showModal = false
          this.$toast.success('La contraseña se ha actualizado correctamente', {
            timeout: 3000,
          })
        },
      })

      if (success) {
        const updateStep = await this.findStepByKey('updatePassword');
      if (updateStep) {
        updateStep.doneRequired = false;
        this.updateStepStatus(updateStep);
      }
        await this.$refs.passwordForm.resetValidation()
        if (this.$refs.passwordInputs?.resetRulesCheckList) {
          this.$refs.passwordInputs.resetRulesCheckList()
        }
      }
    },

    resetForm() {
      this.RESET_FORM()
      if (this.$refs.passwordForm) {
        this.$refs.passwordForm.resetValidation()
      }
      if (this.$refs.passwordInputs?.resetRulesCheckList) {
        this.$refs.passwordInputs.resetRulesCheckList()
      }
      this.localValidForm = false
    },
  },

  watch: {
    localValidForm(newValue) {
      this.SET_VALID_FORM(newValue)
    },
  },
}
</script>