<template>
	<div class="container-fluid ">
		<cardTitle title="NIVEL DE SERVICIO Y TIEMPO DE ENTREGA" icon="calendar-check">
			<template v-slot:content>
				<template v-if="loading">
							<v-progress-linear :active="loading" :indeterminate="loading" top
					color="deep-purple accent-4"></v-progress-linear>
			</template>
				<v-card  v-else class="ma-0">
					<div class="card-body ">
						<v-simple-table dense class="box-shadow ">
							<template v-slot:default>
								<thead>
									<tr class="font-weight-bold  text-black font-size-x">
										<td class="box-shadow sticky-column text-capitalize">Concepto</td>
										<td v-for="(item, index) in months" :key="index"
											class="box-shadow sticky-column text-capitalize"> 
												{{ item.date}}
										</td>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in items" :key="index" class="font-size-x no-border">
										<td class="text-left pa-2 pl-sm-10 no-border sticky-column  all-borders-bsadow"> 
											{{item.text }} 
										</td>
										<td v-for="indx in 6" :key="indx" style="">{{ itemFormat(indx,item.value) }}</td>							
									</tr>
								</tbody>
							</template>
						</v-simple-table>
					</div>
				</v-card>
			</template>

		</cardTitle>
	</div>
</template>
  
<script>
import CardTitle from "@/components/CardTitle.vue";
import axios from "@/plugins/axios";
import service from "@/mixins/service";
import numbersFormats from "@/mixins/numbersFormats";
export default {
	name: "FillRate",
	components: { CardTitle },
	mixins:[service,numbersFormats],
	props: {
		userId: {
			default:null,
		},
		option: {
			default:'C'
		},
		chnl:{
			required:true
		}
	},
	watch: {
		userId() {
			this.fillRate()
		} 
	},
	data() {
		return {
			dataFillRate: [],
			loading: true,
			items: [
				{ text: "Nivel de servicio", value: "NSERV" },
				{ text: "Nivel de servicio de entrega", align: 'center', value: "NSERVENT" }, 
				{ text: "Tiempo de Entrega Pedido", align: 'center', value: "TENTPED" },
			],
		};
	},
	methods: {
	itemFormat(index,value){
		const val =  this.dataFillRate[`${value}${6-index}`];
		switch (value) {
			case 'NSERV':
			case 'NSERVENT':  
				return `${this.formatFloat(val,2)}%`
			case 'TENTPED':  
				return `${this.formatFloat(val,2)}`
		}
	},
	async fillRate() {
			this.loading = true;
			const params = {
						P_USERID: this.userId,
						channel: this.chnl,
						option: this.option,
					};
			const config = {
					noCancelRequest:true,
				}
			try {
				var response  = await 
					axios.post("/cover/fillrate",params,config)
				this.dataFillRate = response.data;
			} catch (error) {
				this.erros = error; 
				this.loading = false;
			}
		this.loading = false;
		},

	},
	mounted() { 
		this.months = this.calcularUltimosMeses(6).reverse(); 
		if (this.option == 'D' || this.option == 'GR') {
			this.fillRate();
		}
	},
};
</script>
  
<style scoped>
.custom-table {
	display: flex;
	flex-direction: column;
	border: 1px solid #ccc;
	max-width: 300px;
	/* Ajusta el ancho máximo según tus necesidades */
}

.custom-row {
	display: flex;
	flex-direction: row;
	border-top: 1px solid #ccc;
}

.custom-cell {
	flex: 1;
	padding: 8px;
	text-align: left;
	border: 2px;
}

.header {
	font-weight: bold;
	background-color: #f2f2f2;
	/* Cambia el color de fondo según tus preferencias */
}





.sticky-column {
	border-width: 0px;
	left: 0;
}

.font-size-x>td {
	font-size: 14px !important;
}

.all-borders-bsadow {
	box-shadow: inset -5px 0 5px -5px #00000029;
}

.label {
	font-weight: 700;
}

.no-border {
	border-width: 0px !important;
}

.no-border>td {
	border-width: 0px !important;
}

.box-shadow {
	box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
	opacity: 1;
}

.box {
	height: 150px;
	width: 300px;
	margin: 20px;
	border: 1px solid #ccc;
}

.box-top {
	box-shadow: 0 -5px 5px -5px #00000029;
}

.box-right {
	box-shadow: 5px 0 5px -5px #00000029;
}

.box-bottom {
	box-shadow: 0 5px 5px -5px #00000029;
}

.box-left {
	box-shadow: -5px 0 5px -5px #00000029;
}

.box-left-inset {
	box-shadow: inset -5px 0 5px -5px #00000029;
}

.box-right-inset {
	box-shadow: 5px 0 5px -5px #00000029;
}

.box-all {
	box-shadow: 0 0 5px #00000029;
}

@media (max-width: 600px) {
	.sticky-column {
		border-width: 0px;
		position: sticky;
		left: 0;
		padding: 1px !important;
		background-color: #fff;
		/* Cambia el color de fondo según tus preferencias */
		z-index: 1;
		/* Asegura que la columna fija esté por encima de las demás celdas */
	}

	.font-size-x>td {
		font-size: 11px !important;
	}
}

@media (min-width: 601px) and (max-width:960px) {
	.font-size-x>td {
		font-size: 12px !important;
	}
}
</style>