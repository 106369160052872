<template>
  <div>

    <v-dialog v-model="showDialog" max-width="600px">
      <v-card>
        <v-card-title>{{ isEdit ? 'Editar publicación' : 'Crear publicación' }}</v-card-title>
        <v-card-text>
          <div class="d-flex align-items-center">
             <p class="mr-2 mb-0">Publicar a:</p>
             <v-checkbox v-model="selectedCanalCreate" label="DT" value="DT" class="mr-2"></v-checkbox>
             <v-checkbox v-model="selectedCanalCreate" label="PR" value="PR" class="mr-2"></v-checkbox>
             <v-select 
               v-model="selectedRolCreate"
               :items="filteredRoles"
               label="Rol"
               multiple
               hide-details
               dense
               class="ml-2"
               style="min-width: 150px;"
             ></v-select>
           </div>
           <div class="center">
           <p>
              <v-checkbox 
              v-model="IfVersion" 
              :label="'Actualización de tu aplicación version ' + version" 
              value="PR" 
              class="mr-2"
          
              ></v-checkbox>
            </p>
          </div>
          <div class="mt-4">
            <p class="mr-4">Publicación</p>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDateTime"
                  label="Selecciona una fecha y hora"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-card>
                <v-card-text>
                  <v-date-picker
                    v-model="selectedDate"
                    no-title
                    scrollable
                    style="flex: 1; margin-right: 10px;"
                    :allowed-dates="allowedDates"
                    :min-date='minDate'
                  ></v-date-picker>
                  <date-picker
                    v-model="selectedTime"
                    :time-picker-options="{
                      start: '08:30',
                      step: '00:30',
                      end: '18:30',
                      is24hr: false
                    }"
                    format="hh:mm a"
                    type="time"
                    placeholder="hh:mm a"
                    style="flex: 1; margin-left: 10px;"
                  ></date-picker>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="red" dark @click="menu = false">Cancelar</v-btn>
                  <v-btn color="green" dark @click="setDateTime">OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div>
            <button @click="toggleBold"><span class="mdi mdi-format-bold" style="font-size: 0.7cm;"></span></button>
            <button @click="alignCenter"><span class="mdi mdi-format-align-center" style="font-size: 0.7cm;"></span></button>
            <button @click="alignLeft"><span class="mdi mdi-format-align-left" style="font-size: 0.7cm;"></span></button>
            <button @click="alignRight"><span class="mdi mdi-format-align-right" style="font-size: 0.7cm;"></span></button>
            <button @click="toggleBulletList"><span class="mdi mdi-format-list-bulleted" style="font-size: 0.7cm;"></span></button>
            <button @click="toggleOrderedList"><span class="mdi mdi-format-list-numbered" style="font-size: 0.7cm;"></span></button>
            <button @click="toggleTextColor"><span class="mdi mdi-brush" style="font-size: 0.7cm;"></span></button>
            <button @click="toggleBackgroundColor"><span class="mdi mdi-brush-variant" style="font-size: 0.7cm;"></span></button>
  
            <Chrome v-if="textColorPickerVisible" v-model="textColor" @input="color => applyColor(color, 'text')" />
            <Chrome v-if="backgroundColorPickerVisible" v-model="backgroundColor" @input="color => applyColor(color, 'background')" />
          </div>
          <editor-content :editor="editor" class="black-margin-user-notification editor-content" />
        </v-card-text>
        <v-card-actions>
          <v-btn color="#FF0000" style="color: white;" @click="savePublication">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import BulletList from '@tiptap/extension-bullet-list';
import Bold from '@tiptap/extension-bold';
import { Chrome } from 'vue-color';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue-color/dist/vue-color';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import moment from 'moment'
import axios from "@/plugins/axios";

export default {
  name: 'UserNotificationForm',
  components: {
    EditorContent,
    DatePicker,
    Chrome,
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    roles: {
      type: Array,
      required: true,
    },
    roleDisplay: {
      type: Object,
      required: true,
    },
    initialData: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      selectedRolCreate: null,
      selectedCanalCreate: [],
      selectedDateTime: '',
      showDialog: false,
      menu: false,
      selectedDate: null,
      selectedTime: null,
      textColorPickerVisible: false,
      backgroundColorPickerVisible: false,
      textColor: '#000000',
      backgroundColor: '#000000',
      selectedColor: '#000000',
      colorPickerVisible: false,
      editor: null,
      content: '',
      localInitialData: null,
      alerts: [],
      minDate:  moment().add('days').format('YYYY/MM/DD'),
      version: '',
    };
  },
  watch: {
    value(newValue) {
      this.showDialog = newValue;
      if (newValue && !this.isEdit) {
        this.resetForm();
      }
      if (newValue) {
      this.loadVersion();
    }
    },
    selectedCanalCreate: {
    immediate: true,
    handler() {
      this.updateRoleOptions();
    }
  },
    initialData: {
      immediate: true,
      handler(newValue) {
        this.localInitialData = newValue;
        if (newValue && this.isEdit) {
          this.populateForm();
        }
      }
    },
  },
  mounted() {
    this.editor = new Editor({
      content: '<p>Escibre contenido de Notificación</p>',
      extensions: [
        StarterKit,
        BulletList,
        Bold,
        TextStyle,
        Color,
        Highlight.configure({
          multicolor: true,
        }),
        TextAlign.configure({
          types: ['paragraph', 'heading'],
          alignments: ['left', 'center', 'right'],
          defaultAlignment: 'center',
          addAttributes() {
            return {
              class: {
                default: null,
                renderHTML: attributes => {
                  if (attributes.textAlign === 'left') {
                    return { class: 'is-align-left' };
                  }
                  if (attributes.textAlign === 'center') {
                    return { class: 'is-align-center' };
                  }
                  if (attributes.textAlign === 'right') {
                    return { class: 'is-align-right' };
                  }
                  return {};
                },
              },
            };
          },
        }),
      ],
      onUpdate: ({ editor }) => {
        this.content = editor.getHTML();
      },
      
    });

    if (this.isEdit && this.localInitialData) {
      this.populateForm();
    }
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    updateRoleOptions() {
    this.selectedRolCreate = this.selectedRolCreate.filter(role =>
      this.filteredRoles.includes(role)
    );
  },
    setDateTime() {
      if (this.selectedDate && this.selectedTime) {
        const hours = this.selectedTime.getHours();
        const minutes = this.selectedTime.getMinutes();
        let adjustedHours = hours - 6;
        if (adjustedHours < 0) {
          adjustedHours += 24;
        }
        const adjustedTime = new Date(this.selectedDate);
        adjustedTime.setHours(adjustedHours);
        adjustedTime.setMinutes(minutes);
        this.selectedDateTime = adjustedTime.toISOString().slice(0, 16);
      }
      this.menu = false;
    },
    savePublication() {
  const errors = this.validateForm();
  
  if (errors.length > 0) {
    this.showErrorMessages(errors);
    return;
  }

  const payload = {
    channel: this.selectedCanalCreate.join(','),
    role: this.selectedRolCreate.join(','),
    published_at: this.selectedDateTime,
    content: this.content,
    version: this.IfVersion ? this.version : '0',
  };

  const fakeResponse = { id: Math.floor(Math.random() * 1000) + 1 };
  this.$emit('save-publication', payload, this.isEdit);
  this.showSuccessMessage(fakeResponse.id);
  this.clearForm();
  this.resetForm();
  this.closeDialog();
},

validateForm() {
  let errors = [];
  if (this.selectedCanalCreate.length === 0) {
    errors.push('Favor de seleccionar un canal');
  }
  if (!this.selectedRolCreate) {
    errors.push('Favor de seleccionar rol');
  }
  if (!this.selectedDateTime) {
    errors.push('Favor de seleccionar fecha y hora');
    }
    if (!this.content || !this.content.trim()) {
      errors.push('Favor de introducir contenido de notificación');
    }
    return errors;
  },
  showErrorMessages(messages) {
    messages.forEach(message => {
      this.$toast.error(message);
    });
  },
  showSuccessMessage(id) {
    this.$toast.success(`Guardado exitosamente. ${id}`);
  },
      closeDialog() {
        this.showDialog = false;
      },
      clearForm() {
        this.selectedRolCreate = '';
      this.selectedCanalCreate = [];
      this.selectedDateTime = '';
      this.editor.commands.setContent('');
    },
    resetForm() {
      this.selectedRolCreate = null;
      this.selectedCanalCreate = [];
      this.selectedDateTime = '';
      this.editor.commands.setContent('');
      this.localInitialData = null;
    },
    populateForm() {
      if (this.localInitialData) {
        this.selectedRolCreate = this.localInitialData.role
    ? this.localInitialData.role.split(',').map(role => role.trim()) 
    : [];
        this.selectedCanalCreate = this.localInitialData.channel.split(',');
        this.selectedDateTime = this.localInitialData.published_at;
        this.editor.commands.setContent(this.localInitialData.content);
      }
    },
    toggleBold() {
      this.editor.chain().focus().toggleBold().run();
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run();
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run();
    },
    toggleTextColor() {
      this.textColorPickerVisible = !this.textColorPickerVisible;
      this.backgroundColorPickerVisible = false;
    },
    toggleBackgroundColor() {
      this.backgroundColorPickerVisible = !this.backgroundColorPickerVisible;
      this.textColorPickerVisible = false;
      if (this.backgroundColorPickerVisible) {
        this.editor.chain().focus().toggleHighlight({ color: this.backgroundColor }).run();
      }
    },
    applyColor(color, type) {
      if (type === 'text') {
        this.editor.chain().focus().setColor(color.hex).run();
        this.textColor = color.hex;
      } else if (type === 'background') {
        this.editor.chain().focus().toggleHighlight({ color: color.hex }).run();
        this.backgroundColor = color.hex;
      }
    },
    alignCenter() {
      this.editor.chain().focus().setTextAlign('center').run();
    },
    alignLeft() {
      this.editor.chain().focus().setTextAlign('left').run();
    },
    alignRight() {
      this.editor.chain().focus().setTextAlign('right').run();
    },
    allowedDates(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return date >= today;
    },
    addAlert(message, type) {
      const index = this.alerts.push({ message, type }) - 1;
      setTimeout(() => {
        this.removeAlert(index);
      }, 2500);
    },
    removeAlert(index) {
      this.alerts.splice(index, 1);
    },
    async loadVersion() {
  try {
    const response = await axios.get('/getversion');
    if (response.data && response.data.version) {
    this.version = response.data.version.version; 
    }else {
      console.warn("No hay versión disponible en la respuesta.");
    }
  } catch (error) {
    console.error("Error al cargar la versión:", error.response || error.message);
  }
}

  },
  computed: {
    rolesWithAll() {
      return[ 'Todos','Director','Subdirector','Gerente Regional','Gerente','Subgerente', 'Asesor','Cliente'];
    },
    filteredRoles() {
  if (this.selectedCanalCreate.includes("DT") && this.selectedCanalCreate.includes("PR")) {
    return this.rolesWithAll;
  } else if (this.selectedCanalCreate.includes("DT")) {
    return this.rolesWithAll.filter(role => role !== "Subgerente");
  } else if (this.selectedCanalCreate.includes("PR")) {
    return this.rolesWithAll.filter(role => role !== "Gerente");
  }
  return this.rolesWithAll;
},
    roleOptions() {
      return this.roles.map(role => ({
        value: role,
        text: this.roleDisplay[role] || role
      }));
    }
  },
};
</script>

<style scoped>
.black-margin-user-notification {
  margin: 10px;
  background-color: rgb(240, 240, 240);
  padding: 10px;
}


.editor-content :deep(p) {
  margin-bottom: 0.5em;
}

.ProseMirror p[style*="text-align: left"] {
  text-align: left !important;
}

.ProseMirror p[style*="text-align: center"],
.ProseMirror p:not([style]) {
  text-align: center !important;
}

.ProseMirror p[style*="text-align: right"] {
  text-align: right !important;
}
</style>
