import axios from "@/plugins/axios";
import { flattenSimpleObject } from "@/plugins/util";

export default {
  namespaced: true,
  state: {
    formStep: 1,
    formLoading: false,
    states: [],
    tax_regimes: [],
    payment_methods: [],
    cfdi_uses: [],
    way_pays: [],
    livetest: {
      uuid: null,
      url: null,
    },
    form: {
      request_credit: null,
      line_request_credit: null,
    },
    formRequest: {
      rfc: null,
      email: null,
      person_type: null,
      facade_photo: null,
      indoor_photo: null,
      bar_photo: null,
      evid_tax_registration: null,
      display_photo: null,
      left_display_photo: null,
      right_display_photo: null,
      official_id: null,
      new_promissory_note: null,
    },
    errors: {},
  },
  getters: {
    step(state) {
      return state.formStep;
    },
    states(state) {
      return state.states;
    },
    tax_regimes(state) {
      return state.tax_regimes;
    },
    payment_methods(state) {
      return state.payment_methods;
    },
    cfdi_uses(state) {
      return state.cfdi_uses;
    },
    way_pays(state) {
      return state.way_pays;
    },
    uuid(state) {
      return state.livetest.uuid;
    },
    formRequest(state) {
      return state.formRequest;
    },
    formLoading(state) {
      return state.formLoading;
    },
    errors(state) {
      return state.errors;
    },
  },
  mutations: {
    SET_FORM_STEP(state, value) {
      state.formStep = value;
    },
    SET_FORM_DATA(state, {key, value}) {
      state.form[key] = value;
    },
    SET_DATA(state, { key, data }) {
      state[key] = data;
    },
    SET_LIVETEST(state, data) {
      state.livetest.uuid = data.uuid;
      state.livetest.url = data.test_url;
    },
    SET_REQUEST_DATA(state, data) {
      state.formRequest = data;
    },
    UPDATE_FORM_VALUE(state, { key, value }) {
      state.formRequest[key] = value;
    },
    SET_FORM_LOADING(state, value) {
      state.formLoading = value;
    },
    SET_ERRORS(state, data) {
      state.errors = data;
    },
  },

  actions: {
    async loadStates({ commit }) {
      return axios
        .get(`request-data`)
        .then((response) => {
          commit("SET_DATA", { key: 'states', data: response.data.states });
          commit("SET_DATA", { key: 'cfdi_uses', data: response.data.cfdi_uses });
          commit("SET_DATA", { key: 'payment_methods', data: response.data.payment_methods });
          commit("SET_DATA", { key: 'tax_regimes', data: response.data.tax_regimes });
          commit("SET_DATA", { key: 'way_pays', data: response.data.way_pays });
          return "Data loaded";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async loadCustomerRequest({ state, commit }) {
      return axios
        .get(`livetest/${state.livetest.uuid}`)
        .then((response) => {
          commit("SET_REQUEST_DATA", flattenSimpleObject(response.data.customer));
          commit("UPDATE_FORM_VALUE", { key: 'request_credit', value: response.data.livetest.request_credit == 1 ? 'Si' : 'No'});
          commit("UPDATE_FORM_VALUE", { key: 'line_request_credit', value: response.data.livetest.line_request_credit});
          return "Data loaded";
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async saveCustomerRequest({ state, rootState, commit }) {
      let formData = new FormData();

      for (const key in state.formRequest) {
        formData.append(key, state.formRequest[key]);
      }
      formData.append('custumer_id', state.formRequest['custumer_id']);
      formData.append('agent_number', rootState.auth.user.employee_number);
      formData.append('agent_name', rootState.auth.user.name);
      formData.delete('live_ine');
      formData.delete('live_pdf');
      commit("SET_FORM_LOADING", true);
      return axios.post(`updateCustomerDetail`, formData).then((response) => {
        commit("SET_REQUEST_DATA", {
          rfc: null,
          email: null,
          person_type: null,
          facade_photo: null,
          indoor_photo: null,
          bar_photo: null,
          display_photo: null,
          left_display_photo: null,
          right_display_photo: null,
          evid_tax_registration: null,
          official_id: null,
        });
        commit("SET_FORM_LOADING", false);
        commit("SET_FORM_STEP", 1);
        return response.data;
      })
        .catch((error) => {
          console.log(error);
          commit("SET_FORM_LOADING", false);
          if(error.response.status == 422) {
            commit("SET_ERRORS", error.response.data.errors);
          }
          throw error;
        });
    },
    async deleteLivetest(_, item) {
      return axios
        .delete(`livetest/${item.uuid}`)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    },
  },
};
