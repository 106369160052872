<template>
  <div class="text-center">
    <!-- To remove console warning -->
    <input autocomplete="username" hidden> 
    <password-input
      v-if="!hideCurrentPassword"
      v-model="passwordForm.currentPassword" 
      label="Contraseña actual *"
      prepend-icon="mdi-lock-alert-outline"
      autocomplete="current-password"
      name="current-password"
      :rules="[passwordRequired]"
    />
    <password-input 
      v-model="passwordForm.password"
      label="Nueva contraseña *"
      prepend-icon="mdi-lock-alert-outline"
      autocomplete="new-password"
      name="new-password"
      hide-details

      :rules="[
        passwordRequired,
        length,
        upperCase,
        lowerCase,
        number,
        specialCharacter
      ]"

      :rules-messages="rulesCheckList"
      @change="validatePasswordRules"
    />
    <password-input 
      v-model="passwordForm.passwordConfirmation"
      label="Confirmar contraseña *"
      prepend-icon="mdi-lock-check-outline"
      placeholder="Confirme la contraseña" 
      name="password-confirm"
      autocomplete="new-password"
      :rules="[
        passwordRequired,
        (v) => passwordMatch(v, this.passwordForm.password)
      ]" 
    />
    <small>* Indica campo requerido</small>
  </div>
</template>

<script>
import PasswordInput from '@/components/PasswordInput';
import Password from '@/mixins/passwordRules';

export default({
  name:'UpdatePasswordForm',

  components: {
    PasswordInput
  },

  mixins: [Password],

  props: {
    value: {
      type: Object,
      default: () => ({
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
      })
    },

    hideCurrentPassword: Boolean
  },

  computed: {
    passwordForm: {
      set(newValue) {
        this.emit('input', { ...this.form, ...newValue })
      },
      get(){
        return this.value;
      }
    }
  }
})
</script>