var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[_c('input',{attrs:{"autocomplete":"username","hidden":""}}),(!_vm.hideCurrentPassword)?_c('password-input',{attrs:{"label":"Contraseña actual *","prepend-icon":"mdi-lock-alert-outline","autocomplete":"current-password","name":"current-password","rules":[_vm.passwordRequired]},model:{value:(_vm.passwordForm.currentPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "currentPassword", $$v)},expression:"passwordForm.currentPassword"}}):_vm._e(),_c('password-input',{attrs:{"label":"Nueva contraseña *","prepend-icon":"mdi-lock-alert-outline","autocomplete":"new-password","name":"new-password","hide-details":"","rules":[
      _vm.passwordRequired,
      _vm.length,
      _vm.upperCase,
      _vm.lowerCase,
      _vm.number,
      _vm.specialCharacter
    ],"rules-messages":_vm.rulesCheckList},on:{"change":_vm.validatePasswordRules},model:{value:(_vm.passwordForm.password),callback:function ($$v) {_vm.$set(_vm.passwordForm, "password", $$v)},expression:"passwordForm.password"}}),_c('password-input',{attrs:{"label":"Confirmar contraseña *","prepend-icon":"mdi-lock-check-outline","placeholder":"Confirme la contraseña","name":"password-confirm","autocomplete":"new-password","rules":[
      _vm.passwordRequired,
      (v) => _vm.passwordMatch(v, this.passwordForm.password)
    ]},model:{value:(_vm.passwordForm.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.passwordForm, "passwordConfirmation", $$v)},expression:"passwordForm.passwordConfirmation"}}),_c('small',[_vm._v("* Indica campo requerido")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }