<template>
  <v-card elevation="0" class="flex-1" :loading="formLoading">
    <v-card-title>SOLICITUD/ALTA DE CLIENTE </v-card-title>
    <v-card-text class="bg-white">
      <v-stepper :value="step" @change="setStep" flat alt-labels>
        <v-stepper-header>
          <v-stepper-step
            step="1"
            append-icon="mdi-chevron-right"
            editable
            class="px-0"
          >
            <p class="text-center">INFORMACIÓN INICIAL</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="2"
            append-icon="mdi-chevron-right"
            editable
            class="px-0"
          >
            <p class="text-center">PRUEBA DE VIDA</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="3"
            append-icon="mdi-chevron-right"
            editable
            class="px-0"
          >
            <p class="text-center">INFORMACIÓN GENERAL</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="4"
            append-icon="mdi-chevron-right"
            editable
            class="px-0"
          >
            <p class="text-center">INFORMACIÓN FISCAL Y DE NEGOCIO</p>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step="5"
            append-icon="mdi-chevron-right"
            editable
            class="px-0"
          >
            <p class="text-center">DOCUMENTACIÓN</p>
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card :loading="initialFormLoading">
              <h5 class="text-left">1. INFORMACIÓN INICIAL</h5>
              <v-form
                class="mt-5"
                ref="form"
                v-model="valid"
              >
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">RFC:</p>
                    <v-text-field
                      v-model="form.rfc"
                      required
                      dense
                      id="rfc_field"
                      :rules="[
                        (v) => !!v || 'El RFC es requerido',
                        (v) => rfcRegex.test(v) || 'El RFC no es válido',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">Email:</p>
                    <v-text-field
                      v-model="form.email"
                      required
                      label=""
                      dense
                      id="email_field"
                      :rules="[
                        (v) => !!v || 'El Email es requerido',
                        (v) => emailRegex.test(v) || 'El Email no es válido',
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      CSF (Constancia de situación fiscal .PDF):
                    </p>
                    <div class="border p-2">
                      <v-btn
                        small
                        @click="openFile('uploader')"
                        id="file_button"
                        ><span
                          :class="!untouched && !form.pdffile ? 'text-red' : ''"
                          >Seleccionar archivo</span
                        ></v-btn
                      >
                      <span class="ml-2" v-if="form.pdffile">{{
                        form.pdffile.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <span style="color: red" v-if="!untouched && !form.pdffile"
                      >La constancia es requerida</span
                    >
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      @change="onFileChanged"
                      accept="application/pdf"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <p class="w-full text-left font-weight-bold mb-0">
                      ¿Requiere crédito?
                    </p>
                    <v-radio-group
                      v-model="form.request_credit"
                      row
                      :rules="[
                        (v) => !!v || 'Debe marcar alguna de las opciones',
                      ]"
                    >
                      <v-radio
                        label="No"
                        value="NO"
                        id="no_option_radio"
                      ></v-radio>
                      <v-radio
                        label="Si"
                        value="SI"
                        id="yes_option_radio"
                      ></v-radio>
                    </v-radio-group>
                    <p class="text-left text-caption">
                      Al marcar "Si" en Requiere crédito, se activan los
                      siguientes campos, sino requiere crédito puede continuar
                    </p>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                      Monto crédito:
                    </p>
                    <v-text-field
                      v-model="form.amount"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="amount_field"
                      :rules="requiredIfRequestCredit ? creditNumberRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                      Días crédito:
                    </p>
                    <v-text-field
                      v-model="form.days"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="days_field"
                      :rules="requiredIfRequestCredit ? requiredRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                      Monto crédito en letra:
                    </p>
                    <v-text-field
                      v-model="form.amount_letter"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="amount_letter_field"
                      :rules="requiredIfRequestCredit ? requiredRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="form.amount > 50000">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                      Nombre del Aval:
                    </p>
                    <v-text-field
                      v-model="form.promissory_note"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="promissory_field"
                      :rules="requiredIfRequestCredit ? requiredRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="form.amount > 50000">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                      Dirección del Aval (Calle):
                    </p>
                    <v-text-field
                      v-model="form.street"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="promissory_street_field"
                      :rules="requiredIfRequestCredit ? requiredRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="form.amount > 50000">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                      Dirección del Aval (Colonia, Municipio, Estado):
                    </p>
                    <v-text-field
                      v-model="form.suburb"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="promissory_suburb_field"
                      :rules="requiredIfRequestCredit ? requiredRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" v-if="form.amount > 50000">
                    <p
                      :class="[
                        'w-full text-left mb-0',
                        form.request_credit == 'NO'
                          ? ''
                          : 'font-weight-bold text--gray',
                      ]"
                    >
                    Dirección del Aval (Código postal):
                    </p>
                    <v-text-field
                      v-model="form.zip_code"
                      label=""
                      dense
                      :disabled="
                        form.request_credit == null ||
                        form.request_credit == 'NO'
                      "
                      id="promissory_zipcode_field"
                      :rules="requiredIfRequestCredit ? requiredRules : []"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :loading="initialFormLoading"
                      @click="validate"
                      >ENVIAR</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <div class="mt-5">
              <h5 class="text-left">2. PRUEBA DE VIDA</h5>
              <!-- <a :href="liveUrl">{{ liveUrl }}</a> -->
              <div class="text-center pt-4">
                <a
                  :href="formLiveTest.liveUrl"
                  target="_blank"
                  @click="markAsSee"
                  ><h4>{{ formLiveTest.liveUrl }}</h4></a
                >
              </div>
              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" @click="validateLivetestInformation"
                    >CONTINUAR</v-btn
                  >
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>

          <v-form @submit.prevent="saveCustomerForm">
            <v-stepper-content step="3">
              <v-card>
                <h5 class="text-left">3. INFORMACIÓN GENERAL</h5>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group :value="formRequest.person_type" row @change="(value) => updateFormValue({ key: 'person_type', value: value })">
                      <v-radio label="Persona Física" value="fisica"></v-radio>
                      <v-radio label="Persona Moral" value="moral"></v-radio>
                    </v-radio-group>
                  </v-col>
                  <p class="text-left">CAPTURA DE DATOS</p>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Razón social:
                    </p>
                    <v-text-field
                      :value="formRequest.company_name"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'company_name', value: value })
                      "
                      :error-messages="errors?.company_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">RFC:</p>
                    <v-text-field
                      :value="formRequest.rfc"
                      required
                      label=""
                      dense
                      @change="
                        (value) => updateFormValue({ key: 'rfc', value: value })
                      "
                      :error-messages="errors?.rfc"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4"></v-col>
                </v-row>
                <hr />

                <p class="text-left">DIRECCIÓN</p>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">Calle:</p>
                    <v-text-field
                      :value="formRequest.a_street"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'a_street', value: value })
                      "
                      :error-messages="errors?.a_street"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Nº Ext.:
                    </p>
                    <v-text-field
                      :value="formRequest.a_number"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'a_number', value: value })
                      "
                      :error-messages="errors?.a_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Nº Int.:
                    </p>
                    <v-text-field
                      :value="formRequest.interior_number"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'interior_number', value: value })
                      "
                      :error-messages="errors?.interior_number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Código postal:
                    </p>
                    <v-text-field
                      :value="formRequest.a_postal_code"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'a_postal_code',
                            value: value,
                          })
                      "
                      :error-messages="errors?.a_postal_code"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Colonia:
                    </p>
                    <v-text-field
                      :value="formRequest.a_suburb"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'a_suburb', value: value })
                      "
                      :error-messages="errors?.a_suburb"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Delegación / Municipio:
                    </p>
                    <v-text-field
                      :value="formRequest.a_town"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'a_town', value: value })
                      "
                      :error-messages="errors?.a_town"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Estado:
                    </p>
                    <v-select
                      :value="formRequest.state_sap_id"
                      required
                      label=""
                      dense
                      :items="states"
                      item-text="state"
                      item-value="sap_id"
                      @change="
                        (value) =>
                          updateFormValue({ key: 'state_sap_id', value: value })
                      "
                      :error-messages="errors?.state_sap_id"
                    ></v-select>
                  </v-col>
                </v-row>
                <hr />

                <p class="text-left">CONTACTO</p>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Nombre del contacto:
                    </p>
                    <v-text-field
                      :value="formRequest.contact_name"
                      required
                      label=""
                      dense
                      persistent-hint
                      @change="
                        (value) =>
                          updateFormValue({ key: 'contact_name', value: value })
                      "
                      :error-messages="errors?.contact_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">Email:</p>
                    <v-text-field
                      :value="formRequest.email"
                      required
                      label=""
                      dense
                      persistent-hint
                      @change="
                        (value) =>
                          updateFormValue({ key: 'email', value: value })
                      "
                      :error-messages="errors?.email"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Teléfono:
                    </p>
                    <v-text-field
                      :value="formRequest.phone"
                      required
                      label=""
                      dense
                      @change="
                        (value) =>
                          updateFormValue({ key: 'phone', value: value })
                      "
                      :error-messages="errors?.phone"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="primary" @click="setStep(4)">CONTINUAR</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card>
                <h5 class="text-left">4. INFORMACIÓN FISCAL Y DE NEGOCIOS</h5>
                <v-row>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">Canal</p>
                    <v-select
                      item-text="value"
                      item-value="id"
                      :value="formRequest.channel"
                      :items="[
                        { id: 'PR', value: 'DISTRIBUCIÓN' },
                        { id: 'DT', value: 'DETALLISTA' },
                      ]"
                      @change="
                        (value) =>{ 
                          updateFormValue({ key: 'channel', value: value })
                          updatePaymentsConditonSelect(value)
                        }
                      "
                      :error-messages="errors?.channel"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Selecciona régimen fiscal:
                    </p>
                    <v-select
                      :value="formRequest.fiscal_regimen"
                      :items="tax_regimes"
                      item-text="label"
                      item-value="code"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'fiscal_regimen',
                            value: value,
                          })
                      "
                      :error-messages="errors?.fiscal_regimen"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Método de pago:
                    </p>
                    <v-select
                      :items="payment_methods"
                      item-text="method"
                      item-value="code"
                      :value="formRequest.payment_method"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'payment_method',
                            value: value,
                          })
                      "
                      :error-messages="errors?.payment_method"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Uso de CFDI:
                    </p>
                    <v-select
                      :items="cfdi_uses"
                      item-text="label"
                      item-value="id"
                      :value="formRequest.cfdi_use"
                      @change="
                        (value) =>
                          updateFormValue({ key: 'cfdi_use', value: value })
                      "
                      :error-messages="errors?.cfdi_use"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Forma de pago:
                    </p>
                    <v-select
                      :items="way_pays"
                      item-text="label"
                      item-value="id"
                      :value="formRequest.way_pay_id"
                      @change="
                        (value) =>
                          updateFormValue({ key: 'way_pay_id', value: value })
                      "
                      :error-messages="errors?.way_pay_id"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Condición de Pago
                    </p>
                    <v-select
                      :items="payments_condition"
                      item-value="value"
                      item-text="label"
                      :value="formRequest.payment_condition"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'payment_condition',
                            value: value,
                          })
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Nombre del representante legal:
                    </p>
                    <v-text-field
                      :value="formRequest.fiscal_rep_name"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'fiscal_rep_name',
                            value: value,
                          })
                      "
                      :error-messages="errors?.fiscal_rep_name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Ventas mensuales del negocio:
                    </p>
                    <v-text-field
                      :value="formRequest.month_sales"
                      @change="
                        (value) =>
                          updateFormValue({ key: 'month_sales', value: value })
                      "
                      :error-messages="errors?.month_sales"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Tamaño del negocio (m2):
                    </p>
                    <v-text-field
                      :value="formRequest.business_size"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'business_size',
                            value: value,
                          })
                      "
                      :error-messages="errors?.business_size"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Giro del negocio:
                    </p>
                    <v-text-field
                      :value="formRequest.line_bussines"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'line_bussines',
                            value: value,
                          })
                      "
                      :error-messages="errors?.line_bussines"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      ¿Las instalaciones son propias?
                    </p>
                    <v-select
                      :items="shortAnswers"
                      item-value="value"
                      item-text="label"
                      :value="formRequest.own_facilities"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'own_facilities',
                            value: value,
                          })
                      "
                      :error-messages="errors?.own_facilities"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <p class="w-full text-left font-weight-bold mb-0">
                      ¿Entregas en dirección fiscal?
                    </p>
                    <v-select
                      :items="shortAnswers"
                      item-value="value"
                      item-text="label"
                      :value="formRequest.delivery_fiscal_addres"
                      @change="
                        (value) =>
                          updateFormValue({
                            key: 'delivery_fiscal_addres',
                            value: value,
                          })
                      "
                      :error-messages="errors?.delivery_fiscal_addres"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="primary" @click="setStep(5)">CONTINUAR</v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card elevation="0">
                <h5 class="text-left">5. DOCUMENTACIÓN</h5>
                <h6 class="text-left text-primary my-5">
                  SOLO SE ACEPTAN IMÁGENES
                </h6>
                <v-row>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Foto 1. Fachada
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('facade_photo')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.facade_photo">{{
                        formRequest.facade_photo.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.facade_photo">{{ errors?.facade_photo[0] }}</p>
                    <input
                      ref="facade_photo"
                      class="d-none"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      @change="
                        (event) => formRequestFileChanged(event, 'facade_photo')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Foto 2. Interior
                    </p>

                    <div class="border p-2">
                      <v-btn small @click="openFile('indoor_photo')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.indoor_photo">{{
                        formRequest.indoor_photo.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.indoor_photo">{{ errors?.indoor_photo[0] }}</p>
                    <input
                      ref="indoor_photo"
                      class="d-none"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      @change="
                        (event) => formRequestFileChanged(event, 'indoor_photo')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Foto 3. Mostrador/Bodega
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('bar_photo')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.bar_photo">{{
                        formRequest.bar_photo.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.bar_photo">{{ errors?.bar_photo[0] }}</p>
                    <input
                      ref="bar_photo"
                      class="d-none"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      @change="
                        (event) => formRequestFileChanged(event, 'bar_photo')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Foto 4. Exhibidor
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('display_photo')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.display_photo">{{
                        formRequest.display_photo.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.display_photo">{{ errors?.display_photo[0] }}</p>
                    <input
                      ref="display_photo"
                      class="d-none"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      @change="
                        (event) => formRequestFileChanged(event, 'display_photo')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Foto 5. Exhibidor Izquierdo
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('left_display_photo')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.left_display_photo">{{
                        formRequest.left_display_photo.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.left_display_photo">{{ errors?.left_display_photo[0] }}</p>
                    <input
                      ref="left_display_photo"
                      class="d-none"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      @change="
                        (event) => formRequestFileChanged(event, 'left_display_photo')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Foto 6. Exhibidor Derecho
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('right_display_photo')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.right_display_photo">{{
                        formRequest.right_display_photo.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.right_display_photo">{{ errors?.right_display_photo[0] }}</p>
                    <input
                      ref="right_display_photo"
                      class="d-none"
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      @change="
                        (event) => formRequestFileChanged(event, 'right_display_photo')
                      "
                    />
                  </v-col>
                </v-row>
                <h6 class="text-left text-primary mt-16 mb-5">
                  SOLO SE ACEPTAN FORMATO PDF
                </h6>
                <v-row>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Constancia de situación fiscal
                    </p>
                    <div class="border p-2" v-if="!formRequest.evid_tax_registration || formRequest.evid_tax_registration == null">
                      <v-btn small @click="openFile('evid_tax_registration')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.evid_tax_registration"
                        >{{ formRequest.evid_tax_registration.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <div class="border p-2" v-else>
                      <a :href="`${$filesUrl}/${formRequest.evid_tax_registration}`" target="_blank">Constancia de situación fiscal</a>
                    </div>
                    <p class="text-red" v-if="errors?.evid_tax_registration">{{ errors?.evid_tax_registration[0] }}</p>
                    <input
                      ref="evid_tax_registration"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'evid_tax_registration')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      ID Oficial de persona física o representante legal
                    </p>
                    <div class="border p-2" v-if="!formRequest.evid_tax_registration || formRequest.evid_tax_registration == null">
                      <v-btn small @click="openFile('official_id')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.official_id">{{
                        formRequest.official_id.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <div class="border p-2" v-else>
                      <a :href="`${$filesUrl}/${formRequest.official_id}`" target="_blank">Identificación Oficial</a>
                    </div>
                    <p class="text-red" v-if="errors?.official_id">{{ errors?.official_id[0] }}</p>
                    <input
                      ref="official_id"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) => formRequestFileChanged(event, 'official_id')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Comprobante de domicilio de la dirección fiscal
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('proof_recidency')"
                        >Seleccionar archivo</v-btn
                      >
                      <span class="ml-2" v-if="formRequest.proof_recidency">{{
                        formRequest.proof_recidency.name
                      }}</span>
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.proof_recidency">{{ errors?.proof_recidency[0] }}</p>
                    <input
                      ref="proof_recidency"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'proof_recidency')
                      "
                    />
                  </v-col>
                  <!-- <v-col cols="12" md="4" class="text-left" v-if="formRequest.request_credit == 'Si'">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Formato de Buró de Crédito
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('credit_bureau')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.credit_bureau"
                        >{{ formRequest.credit_bureau.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.credit_bureau">{{ errors?.credit_bureau[0] }}</p>
                    <input
                      ref="credit_bureau"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'credit_bureau')
                      "
                    />
                  </v-col> -->
                  <v-col cols="12" md="4" class="text-left" v-if="formRequest.request_credit == 'Si'">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Descarga e imprime el Pagaré
                    </p>
                    <!-- <div class="border p-2" v-if="!formRequest.promissory_note || formRequest.promissory_note == null">
                      <v-btn small @click="openFile('promissory_note')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.promissory_note"
                        >{{ formRequest.promissory_note.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div> -->
                    <!-- <div class="border p-2" v-else> -->
                    <div class="border p-2" v-if="formRequest.request_credit == 'Si'">
                      <a :href="`${$filesUrl}/${formRequest.promissory_note}`" target="_blank">Pagaré</a>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4" class="text-left" v-if="formRequest.request_credit == 'Si' && formRequest.line_request_credit > 50000">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Identificación del Aval
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('official_id_endors')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.official_id_endors"
                        >{{ formRequest.official_id_endors.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.official_id_endors">{{ errors?.official_id_endors[0] }}</p>
                    <input
                      ref="official_id_endors"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'official_id_endors')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left" v-if="formRequest.request_credit == 'Si' && formRequest.line_request_credit > 50000">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Comprobante de Domicilio del Aval
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('proof_recidency_endors')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.proof_recidency_endors"
                        >{{ formRequest.proof_recidency_endors.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.proof_recidency_endors">{{ errors?.proof_recidency_endors[0] }}</p>
                    <input
                      ref="proof_recidency_endors"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'proof_recidency_endors')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left" v-if="formRequest.request_credit == 'Si'">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Pagaré firmado en PDF
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('new_promissory_note')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.new_promissory_note"
                        >{{ formRequest.new_promissory_note.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.new_promissory_note">{{ errors?.new_promissory_note[0] }}</p>
                    <input
                      ref="new_promissory_note"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'new_promissory_note')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left" v-if="formRequest.person_type == 'moral'">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Acta constitutiva
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('charter')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.charter"
                        >{{ formRequest.charter.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.charter">{{ errors?.charter[0] }}</p>
                    <input
                      ref="charter"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'charter')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left" v-if="formRequest.person_type == 'moral'">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Poder notarial para ser el representante legal de la persona moral
                    </p>
                    <div class="border p-2">
                      <v-btn small @click="openFile('power_attorney')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.power_attorney"
                        >{{ formRequest.power_attorney.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <p class="text-red" v-if="errors?.power_attorney">{{ errors?.power_attorney[0] }}</p>
                    <input
                      ref="power_attorney"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'power_attorney')
                      "
                    />
                  </v-col>
                </v-row>

                <h6 class="text-left text-primary mt-16 mb-5" v-if="formRequest.line_request_credit > 500000">
                  ESTADOS FINANCIEROS
                </h6>
                <v-row v-if="formRequest.line_request_credit > 500000">
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Balance General
                    </p>
                    <div class="border p-2" v-if="!formRequest.balance_sheet || formRequest.balance_sheet == null">
                      <v-btn small @click="openFile('balance_sheet')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.balance_sheet"
                        >{{ formRequest.balance_sheet.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <div class="border p-2" v-else>
                      <a :href="`${$filesUrl}/${formRequest.balance_sheet}`" target="_blank">Balance General</a>
                    </div>
                    <p class="text-red" v-if="errors?.balance_sheet">{{ errors?.balance_sheet[0] }}</p>
                    <input
                      ref="balance_sheet"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'balance_sheet')
                      "
                    />
                  </v-col>
                  <v-col cols="12" md="4" class="text-left">
                    <p class="w-full text-left font-weight-bold mb-0">
                      Estado de Resultados
                    </p>
                    <div class="border p-2" v-if="!formRequest.income_statements || formRequest.income_statements == null">
                      <v-btn small @click="openFile('income_statements')"
                        >Seleccionar archivo</v-btn
                      >
                      <span
                        class="ml-2"
                        v-if="formRequest.income_statements"
                        >{{ formRequest.income_statements.name }}</span
                      >
                      <span class="ml-2" v-else>Sin selección</span>
                    </div>
                    <div class="border p-2" v-else>
                      <a :href="`${$filesUrl}/${formRequest.income_statements}`" target="_blank">Estado de Resultados</a>
                    </div>
                    <p class="text-red" v-if="errors?.income_statements">{{ errors?.income_statements[0] }}</p>
                    <input
                      ref="income_statements"
                      class="d-none"
                      type="file"
                      accept="application/pdf"
                      @change="
                        (event) =>
                          formRequestFileChanged(event, 'income_statements')
                      "
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-content-end">
                    <v-checkbox
                      :value="formRequest.regulation_data_protect"
                      label="Aviso de protección de datos"
                      color="red"
                      hide-details
                      class="mr-4"
                    ></v-checkbox>
                    <p class="text-red" v-if="errors?.regulation_data_protect">{{ errors?.regulation_data_protect[0] }}</p>
                    <v-checkbox
                      :value="formRequest.credit_policies"
                      label="Políticas de crédito nacionales"
                      color="red darken-3"
                      hide-details
                    ></v-checkbox>
                    <p class="text-red" v-if="errors?.credit_policies">{{ errors?.credit_policies[0] }}</p>
                  </v-col>
                  <v-col cols="12">
                    <v-btn class="mr-5">REGRESAR</v-btn>
                    <v-btn color="primary" type="submit" :loading="formLoading"
                      >GUARDAR Y ENVIAR A REVISIÓN</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card>
            </v-stepper-content>
          </v-form>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "@/plugins/axios";

export default {
  name: "CreateRequest",

  data: () => ({
    rfcRegex: /^[a-zA-Z]{3,4}\d{6}[a-zA-Z0-9]{3}$/,
    emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
    requiredRules: [(value) => !!value || "El campo es requerido"],
    creditNumberRules: [
      (value) => !!value || "El campo es requerido",
      (value) => /^\d+$/.test(value) || "Solo se permiten números",
    ],
    isSelecting: false,
    untouched: true,
    valid: false,
    form: {
      rfc: null,
      email: null,
      amount: null,
      days: null,
      amount_letter: null,
      promissory_note: null,
      street: null,
      suburb: null,
      zip_code: null,
      pdffile: null,
      request_credit: false,
    },
    initialFormLoading: false,
    formLiveTest: {
      watched: false,
      liveUrl: null,
    },
    shortAnswers: [
      { value: "Si", label: "Si" },
      { value: "No", label: "No" },
    ],
    payments_conditon_pr: [
      { value: 'IU03', label: '30 - IU03' },
      // { value: 'IUNR', label: '60 - IUNR' },
    ],
    payments_conditon_dt: [
      { value: 'IU03', label: '30 - IU03' },
      // { value: 'DT33', label: '30 - DT33' },
    ],
    payments_conditon_no_credit: [
      { value: 'IU00', label: '0 - IU00' }
    ],
    payments_condition: [],
  }),

  computed: {
    ...mapGetters({
      step: "livetest/step",
      states: "livetest/states",
      tax_regimes: "livetest/tax_regimes",
      payment_methods: "livetest/payment_methods",
      cfdi_uses: "livetest/cfdi_uses",
      way_pays: "livetest/way_pays",
      uuid: "livetest/uuid",
      formRequest: "livetest/formRequest",
      formLoading: "livetest/formLoading",
      errors: "livetest/errors",
    }),
    fileName() {
      return this.form.file ? this.form.file.name : "Sin selección";
    },
    requiredIfRequestCredit() {
      return this.form.request_credit == "SI";
    },
  },

  mounted() {
    this.loadStates();
  },

  methods: {
    ...mapMutations({
      setStep: "livetest/SET_FORM_STEP",
      setFormData: "livetest/SET_FORM_DATA",
      setLivetest: "livetest/SET_LIVETEST",
      updateFormValue: "livetest/UPDATE_FORM_VALUE",
    }),
    ...mapActions({
      loadStates: "livetest/loadStates",
      loadCustomerRequest: "livetest/loadCustomerRequest",
      saveCustomerRequest: "livetest/saveCustomerRequest",
    }),
    openFile(refName) {
      this.isSelecting = true;

      if (refName == "uploader") {
        this.untouched = false;
      }

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs[refName].click();
    },
    onFileChanged(e) {
      this.form.pdffile = e.target.files[0];
    },
    setValuesForm() {
      this.formRequest.rfc = this.form.rfc;
      this.formRequest.email = this.form.email;
    },
    sendInitialForm() {
      this.initialFormLoading = true;
      this.setValuesForm();

      let formData = new FormData();

      for (const key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(`/livetest`, formData)
        .then((response) => {
          if (response.status == 201) {
            this.setStep(2);
            this.formLiveTest.liveUrl = response.data.test_url;
            this.setLivetest(response.data);
            this.setFormData({ key: 'line_request_credit', value: this.form.amount});
          }
        })
        .catch((error) => {
          this.$toast.warning(
            "Ocurrio un error con la solicitud." +
              (error?.response?.data?.error || error)
          );
          console.log(error);
        })
        .finally(() => (this.initialFormLoading = false));
    },
    markAsSee() {
      this.formLiveTest.watched = true;
    },
    formRequestFileChanged(e, key) {
      this.formRequest[key] = e.target.files[0];
      this.updateFormValue({ key: key, value: e.target.files[0] });
    },
    validate() {
      this.$refs.form.validate();
      this.untouched = false;

      if (this.valid == true) {
        this.sendInitialForm();
      }
    },
    validateLivetestInformation() {
      this.loadCustomerRequest().then((status) => {
        if (status == "Data loaded") {
          this.setStep(3);

          this.$toast.success("Información encontrada.");

          this.updatePaymentsConditonSelect(this.formRequest.channel);
        } else {
          this.$toast.error("La información aún no ha sido recibida.");
        }
      });
    },
    saveCustomerForm() {
      let vm = this;
      this.saveCustomerRequest().then(data => {
        this.$toast.success("Solicitud guardada correctamente con id: " + data.id);
        setTimeout(() => {
          vm.$router.replace('/solicitudes');
        }, 2000);
      }).catch(error => {
        if(error.response.status == 422) {
          this.$toast.warning("Error en la validación de datos. Verifica los campos.");
        } else if(error.response.status == 500) {
          this.$toast.error("Error inesperado en el servidor.");
        }
      });
    },
    updatePaymentsConditonSelect(value) {
      if(value == 'PR' && this.form.request_credit == 'SI') {
        this.payments_condition = this.payments_conditon_pr
      } else if(value == 'DT' && this.form.request_credit == 'SI') {
        this.payments_condition = this.payments_conditon_dt
      } else if(this.form.request_credit == 'NO') {
        this.payments_condition = this.payments_conditon_no_credit
      } else {
        this.payments_condition = [...this.payments_conditon_pr, ...this.payments_conditon_dt];
      }
    }
  },
};
</script>

<style lang="css">
.text-red {
  color: red;
}
</style>