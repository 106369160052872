import { mapGetters } from "vuex";
export default {
  methods: {
    validateCustomerCode() {
      return this.$getLocalStorageCustomer() !== null
        ? this.$getLocalStorageCustomer()
        : this.user.custumer_code;
    },
    validateRegionalManagerCode() {
      const regionalCode = this.$getLocalStorageRegional();

      if (regionalCode !== null)
        return regionalCode;      

      if (!this.$hasAnyRole(['IELRegManager']))
        return this.user.employee_number;      

      return '';
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
