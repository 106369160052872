<template>
  <v-card-text>
    <v-icon
      class="mt-30"
      color="#FACEA8A8"
      size="128"
      style="align-self: center"
    >
      mdi-alert-circle-outline
    </v-icon>
    <p>Se descontará de su bono POP:</p>
    <div class="justify-center">
      <v-layout justify-center>
        <v-simple-table class="my-table">
          <thead class="table-heading">
            <tr>
              <td style="text-align: center">Facturas</td>
              <td style="text-align: center">Montos</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in selectedInvoice" :key="i.number">
              <td class="table-body">{{ item.number }}</td>
              <td class="table-body">{{ formatNumber(item.amount) }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-layout>
    </div>
    <p class="text-total mt-3">Monto aplicado: <span>{{formatNumber(totalAmout)}}</span></p>
  </v-card-text>
</template>
<script>
//import axios from "@/plugins/axios";
import numbersFormats from "@/mixins/numbersFormats";

export default {
  name: "BonusCard",
  props: {
    selectedInvoice: {
      required: true,
    },
  },
  mixins: [numbersFormats],
  computed : {
    totalAmout: function() {
      return this.selectedInvoice.map((item) => parseFloat(item.amount)).reduce((a, b) => a + b, 0);
    }
  }
};
</script>
<style scoped src="../../../assets/css/accountmove.css"></style>
