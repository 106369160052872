<template>
  <v-timeline>
    <v-timeline-item
      v-for="observation in observations"
      :key="'obs-' + observation.id"
    >
      {{ observation.observations }} <br />
      {{ formatDate(observation.created_at) }} hrs. <br />
      <strong>{{
        observation.author ? observation.author.name : observation.user
      }}</strong>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
export default {
  props: ["observations"],
  data() {
    return {};
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
  },
};
</script>
