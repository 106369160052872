<template>    
    <v-btn outlined color="grey" :disabled="disabledButton">
        <vue-json-to-csv :json-data="itemCSV" :csv-title="nameCSV">                                            
            Georeferencia
            <v-icon color="green" class="ml-2" small>mdi mdi-file-download</v-icon>            
        </vue-json-to-csv> 
    </v-btn>    
</template>

<script>
import axios from "@/plugins/axios";
import VueJsonToCsv from 'vue-json-to-csv';
import moment from "moment";
import { mapGetters } from "vuex";
export default {
    name: 'DownloadStatusGeolocation',
    components: { VueJsonToCsv },
    data(){
        return {
            itemCSV: [],
            employeeNumber: '',
            disabledButton: true
        }
    },  
    mounted(){        
        this.validateEmployeeNumber();
        this.getStatusGeolocation();
    },  
    methods:{
        async getStatusGeolocation(){
            try{
                const response = await axios.get('/status-geolocation-weeks', {
                    params: {
                        P_VTWEG: this.user.VTWEG,
                        P_GERREG: this.employeeNumber
                    }
                });

                this.itemCSV = response.data.data;
                this.disabledButton = false;
            }catch(error){
                console.log(error);
            }
        },
        validateEmployeeNumber(){
            if (this.$route.meta.role === "Regional") {
                const employeeNumber = this.$hasAnyRole(['IELRegManager']) ? '' : "0" + this.user.employee_number;
                const currentCode = this.$getLocalStorageRegional();
                this.employeeNumber = currentCode ? currentCode : employeeNumber;
            }
        }
    },
    computed:{
        ...mapGetters({
            user: "auth/user",
        }),
        nameCSV(){
            return `Estatus-georeferencia-${moment().format('DD-MM-YYYY')}`; 
        }
    }
}
</script>

<style>

</style>