<template>
  <v-card-text class="d-flex flex-column justify-center" style="height: 100%;">
    <h5 class="text-center"><b>Actualización IEL</b></h5>
    <br />
    <div class="notification-content" v-html="processedContent"></div>
    <v-card-actions class="d-flex justify-center">
      <v-checkbox v-model="localDontShowAgain" label="No volver a mostrar" class="text-center"></v-checkbox>
    </v-card-actions>
  </v-card-text>
</template>

<script>
export default {
  name: 'SystemUpdatesList',
  props: {
    notificationContent: {
      type: String,
      required: true
    },
    dontShowAgain: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      processedContent: ''
    }
  },
  computed: {
    localDontShowAgain: {
      get() {
        return this.dontShowAgain;
      },
      set(value) {
        this.$store.commit('userNotification/SET_DONT_SHOW_AGAIN', value);
      }
    }
  },
  watch: {
    notificationContent(newContent) {
      this.processedContent = this.alignCenter(newContent);
    }
  },
  created() {
    this.processedContent = this.alignCenter(this.notificationContent);
  },
  methods: {
    alignCenter(content) {
      const div = document.createElement('div');
      div.innerHTML = content;
      div.querySelectorAll('p').forEach((p) => {
        const hasInlineAlignment = p.style.textAlign;
        const hasAlignmentClass = p.classList.contains('is-align-left') ||
                                  p.classList.contains('is-align-center') ||
                                  p.classList.contains('is-align-right');

        if (!hasInlineAlignment && !hasAlignmentClass) {
          p.classList.add('is-align-center');
        }
      });
      return div.innerHTML;
    }
  }
}
</script>

<style scoped>
.notification-content :deep(p) {
  margin-bottom: 0.5em;
}

.notification-content :deep(.is-align-left) {
  text-align: left !important;
}

.notification-content :deep(.is-align-center) {
  text-align: center !important;
}

.notification-content :deep(.is-align-right) {
  text-align: right !important;
}

h5 {
  color: #000000;
}

h6 {
  color: #00000099;
}
</style>
