<template>
  <tr
    :class="{
      'table__tr--background': validateIsInserted(),
      'table__tr--red': invoice.ESTAT === 'VENCIDO',
    }"
  >
    <!-- Número de cliente -->
    <td v-if="isGroup">
      {{ !validateIsInserted() ? `${formatCustomer(invoice.KUNNR)}` : "" }}
    </td>
    <!-- RFC -->
    <td v-if="isGroup">
      {{ !validateIsInserted() ? invoice.STCD1 : "" }}
    </td>
    <!-- Número de documento -->
    <td style="position: relative;">
      {{invoice.BELNR}}
      <!-- POP -->
      <div class="td__tooltip--absolute">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span 
              v-if="invoice.AUAR == 'PSPO'"
              v-bind="attrs"
              v-on="on">
              <img src="@/assets/img/indicators_invoice/POP.svg">
            </span>
          </template>
          <span>Pedido especial de apoyo a la marca</span>
        </v-tooltip>
      </div>      
      <!-- Productos no comprados -->
      <div class="td__tooltip--absolute">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span 
              v-if="invoice.AUAR == 'PSNW'"
              v-bind="attrs"
              v-on="on">
              <img src="@/assets/img/indicators_invoice/producto_no_comprado.svg">
            </span>
          </template>
          <span>Producto no comprado</span>
        </v-tooltip>
      </div>
    </td>
    <!-- Orden de compra -->
    <td>
      {{ !validateIsInserted() ? invoice.ORDCOM : '-' }}
    </td>
    <!-- Descargas -->
    <td>
      <div class="d-flex justify-content-center">
        <div class="d-flex align-items-start" style="width: 70px;">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                v-bind="attrs"
                v-on="on"
                v-if="invoice.BELNR != ''"
                @click="getPDFXMLInvoice(invoice.BELNR, 1)"
              >
                <v-icon dense large color="red" style="font-size: 22px">
                  mdi-file-download
                </v-icon></a
              >
            </template>
            <span>Descargar PDF</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a
                href="#"
                v-bind="attrs"
                v-on="on"
                v-if="invoice.BELNR != ''"
                @click="getPDFXMLInvoice(invoice.BELNR, 2)"
              >
                <v-icon dense large color="blue" style="font-size: 22px">
                  mdi-file-download
                </v-icon>
              </a>
            </template>
            <span>Descargar XML</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a 
                :href="invoice.ACUSEDOC"
                target="_blank"
                v-bind="attrs"
                v-on="on"
                v-if="invoice.ACUSEDOC != ''">
                <v-icon dense large color="green" style="font-size: 22px">
                  mdi-file-download
                </v-icon>
              </a>
            </template>
            <span>Descargar Acuse</span>
          </v-tooltip>
        </div>
      </div>
    </td>
    <!-- Tipo de documento -->
    <td>
      
        <div class="d-flex align-items-center">   
          <div style="min-width: 30px" v-if="!validateIsInserted()">       
            <v-icon
              v-if="invoice.DETFAC !== undefined && invoice.DETFAC.length > 0"
              small
              color="#5600e8"
              @click="$emit('actionButton')"              
            >
              {{ invoice.isOpen ? "mdi-minus-circle" : "mdi-plus-circle" }}
            </v-icon>
          </div>
          <span class="w-100">{{ invoice.TIPO }}</span> 
          <div style="min-width: 32px;">
            <span class="badge rounded-pill" style="background: #306b75" v-if="invoice.AUGRU">{{invoice.AUGRU}}</span>
          </div>         
        </div>  
      
    </td>
    <!-- Fecha de documento -->
    <td>{{ invoice.BUDAT }}</td>
    <!-- Fecha de Vencimiento -->
    <td>{{ !validateIsInserted() ? invoice.FVENC : "-" }}</td>
    <!-- Días vencidos -->
    <td>{{ !validateIsInserted() ? invoice.DVENC : "-" }}</td>    
    <!-- Monto -->
    <td>${{ invoice.DMBTR | formatCurrency }}</td>
    <!-- Saldo sin vencer -->
    <td>
      {{
        !validateIsInserted() ? `$${formatCurrency(invoice.por_vencer)}` : "-"
      }}
    </td>
    <!-- Saldo vencido -->
    <td>
      {{ !validateIsInserted() ? `$${formatCurrency(invoice.vencido)}` : "-" }}
    </td>
  </tr>
</template>

<script>
import axios from "@/plugins/axios";
export default {
  props: {
    isGroup: {
      type: Number,
      required: true,
    },
    invoice: {
      type: Object,
      required: true,
    },
  },
  methods: {
    validateIsInserted() {
      return this.invoice.inserted;
    },
    async getPDFXMLInvoice(invoice, type) {
      try {
        const response = await axios.post("InvoicePDFXML", {
          invoice: invoice,
          type: type,
        });
        this.url = response.data;
        window.open(this.url, "_blank");
      } catch (error) {
        console.error(error);
      }
    },
    formatCurrency(value) {      
      return this.$options.filters.formatCurrency(value);
    },
    formatCustomer(value){
      return this.$options.filters.formatCustomer(value);
    }
  },
};
</script>

<style scoped>
.table__tr--red {
  color: red;
}
.table__tr--background td {
  background: #f2f2f2;
}
.td__tooltip--absolute{
  position: absolute; 
  right: 5px; 
  top: 5px;
}
</style>