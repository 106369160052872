import axios from '@/plugins/axios'

export const updatePassword = {
  namespaced: true,

  state: () => ({
    wantUpdatePassword: false,
    passwordForm: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validForm: false,
    updatingPassword: false,
    passwordUpdated: false,
    hasError: false,
    message: '',
    messages: [],
  }),

  getters: {
    wantUpdatePassword: (state) => state.wantUpdatePassword,
    passwordForm: (state) => state.passwordForm,
    validForm: (state) => state.validForm,
    updatingPassword: (state) => state.updatingPassword,
    passwordUpdated: (state) => state.passwordUpdated,
    hasError: (state) => state.hasError,
    message: (state) => state.message,
    messages: (state) => state.messages,
  },

  mutations: {
    SET_WANT_UPDATE_PASSWORD(state, value) {
      state.wantUpdatePassword = value
    },
    SET_VALID_FORM(state, isValid) {
      state.validForm = isValid
    },
    SET_UPDATING_PASSWORD(state, status) {
      state.updatingPassword = status
    },
    SET_PASSWORD_UPDATED(state, status) {
      state.passwordUpdated = status
    },
    SET_ERROR(state, { hasError, message }) {
      state.hasError = hasError
      state.message = message
    },
    SET_ERRORS(state, messages) {
      state.messages = messages
    },
    RESET_FORM(state) {
      state.passwordForm = {
        currentPassword: '',
        password: '',
        passwordConfirmation: '',
      }
      state.messages = []
      state.hasError = false
      state.validForm = false
      state.passwordUpdated = false
    },
    UPDATE_PASSWORD_FORM(state, { field, value }) {
      state.passwordForm[field] = value
    },
  },

  actions: {
    checkMustChangePassword({commit, rootGetters}){
      const mustChangePassword = rootGetters['auth/mustchangepassword'];
      if (mustChangePassword === 1){  
        commit('SET_WANT_UPDATE_PASSWORD', true); 
      }else{  
        commit('SET_WANT_UPDATE_PASSWORD', false);
      }
    },
    setWantUpdatePassword({ commit }, value) {
      commit('SET_WANT_UPDATE_PASSWORD', value)
    },

    setFormValidity({ commit }, isValid) {
      commit('SET_VALID_FORM', isValid)
    },

    updatePasswordField({ commit }, { field, value }) {
      commit('UPDATE_PASSWORD_FORM', { field, value })
    },

    async updatePassword({ commit, state }, { username, successCallback }) {
      if (!state.validForm) return false

      commit('SET_UPDATING_PASSWORD', true)

      const params = {
        username,
        current_password: state.passwordForm.currentPassword,
        password_confirmation: state.passwordForm.passwordConfirmation,
        password: state.passwordForm.password,
      }

      try {
        await axios.post('/user/updatePassword', params)
        commit('RESET_FORM')
        commit('SET_PASSWORD_UPDATED', true)
        commit('SET_WANT_UPDATE_PASSWORD', false)

        if (successCallback) {
          successCallback()
        }

        return true
      } catch ({ response }) {
        await this.dispatch('updatePassword/catchError', response)
        return false
      } finally {
        commit('SET_UPDATING_PASSWORD', false)
      }
    },

    async passwordReset({ commit, state }, { token, tokenM, recaptchaVerified = true }) {
      if (!state.validForm) return false
      if (!recaptchaVerified) {
        await this.dispatch('updatePassword/catchError', { 
          status: 400, 
          data: { message: 'El campo CAPTCHA es obligatorio.' }
        })
        return false
      }

      commit('SET_UPDATING_PASSWORD', true)

      try {
        const form = {
          ...state.passwordForm,
          token,
          tokenM,
        }

        await axios.post(`${process.env.VUE_APP_BASE_SERVER}/auth/password-recovery-confirm`, form)
        commit('SET_PASSWORD_UPDATED', true)
        commit('RESET_FORM')
        commit('SET_WANT_UPDATE_PASSWORD', false)
        return true
      } catch ({ response }) {
        await this.dispatch('updatePassword/catchError', response)
        return false
      } finally {
        commit('SET_UPDATING_PASSWORD', false)
      }
    },

    async catchError({ commit }, error) {
      if (!error) {
        commit('SET_ERROR', { 
          hasError: true, 
          message: 'Hubo un error al restablecer la contraseña.' 
        })
        return
      }

      if (error.status === 422) {
        const errorKeys = Object.keys(error.data.errors)
        const messages = errorKeys.reduce((accum, k) => [...accum, ...error.data.errors[k]], [])
        commit('SET_ERRORS', messages)
      } else if (error.status === 400) {
        commit('SET_ERROR', { 
          hasError: true, 
          message: error.data.message 
        })
      }

      setTimeout(() => {
        commit('SET_ERROR', { hasError: false, message: '' })
        commit('SET_ERRORS', [])
      }, 8000)
    },

    resetForm({ commit }) {
      commit('RESET_FORM')
    },
  },
}

export default updatePassword