<template>
  <v-container fluid class="white pb-10 pt-7 px-5">
    <div class="d-flex align-center gap-2">
      <back-btn />
      <div class="outlined-card pa-4">
        <icon-title text="Backorder carretes" icon="mdi-bookmark-check"/>
      </div>
    </div>

    <blocked-by-backorders-alert v-if="userIsBlockedByBackorders" class="mt-5" />

    <div v-if="!orderCreated">
      <customer-order-information 
        :customer-data="customerData"
        :customer-channel="customerData?.CUSTOMER?.channel || ''"
        :past-due-balance="walletPastDueBalanceFormated"
        :current-order-status="orderStatus"
        :current-order-total="cartTotals.total"
        :available-credit="availableCreditFormated"
        :loading="loadingCustomerData"
        :error="customerDataError || loadCustomerError"
      />

      <v-card class="pa-5 my-5 card-shadow" rounded="0">
        <shipping-form 
          v-model="orderForm"
          :address-list="shippingAddressList"
        />
      </v-card>

      <v-card class="pa-5 my-5 card-shadow" rounded="0">
        <add-material-form
          @onAddMaterial="getMaterialData"
          :materials="materials"
          title="Crear nuevo Backorder"
          button-text="Agregar Backorder"
          :disabled="loadingMaterial || !canStartUsingUI || userIsBlockedByBackorders"
          :loading="loadingMaterial"
        />

        <div class="mt-4" v-if="materialErrorMessage.length > 0">
          <v-alert text color="warning"> {{ materialErrorMessage }}</v-alert>
        </div>
      </v-card>

      <div v-if="cart.length > 0" class="my-5 mt-10">
        <reel-order-cart-table
          title="Carrito de recordatorios"
          :cart-items="cart"
          :hide-headers="hideHeaders"
          hide-cart-totals
          editable-units
          @onDeleteItem="(item, index) => removeFromCart(index)"
          @updateUnitsItem="updateCartItemQty"
        />
       
        <!-- Alerts -->
        <div class="mt-10">
          <v-alert text color="error" v-if="invalidOrderDataMessage.length > 0"> {{ invalidOrderDataMessage }}</v-alert>
        </div>

        <v-row class="mt-10" justify="end">
          <div class="button-container">
            <v-btn @click="storeOrder" class="card-shadow" dark color="#007D36">
              Confirmar recordatorio <v-icon color="white">mdi-checkbox-multiple-marked-circle</v-icon>
            </v-btn>
          </div>
        </v-row>
      </div>
    </div>
    
      <!-- Created order success message -->
    <div v-else>
      <v-card class="pa-5 mt-10 ma-5 card-shadow">
        <icon-title text="Recordatorio creado" />
        <div class="text-left">Orden de recordatorio : {{ orderForm.orderConcept }}</div>

        <v-row class="mt-15">
          <v-col cols="6">
            <v-btn block color="success" to="/">Ir a inicio</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary" to="/history-orders">Ir a historial</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <overlay :show="updatingMaterial" text="Actualizando material..."/>
    <overlay :show="savingOrder" text="Generando recordatorio..."/>
    <validate-customer-storage />
  </v-container>
</template>

<script>
  import ShippingForm from '@/components/forms/ShippingForm';
  import AddMaterialForm from '@/components/forms/AddMaterialForm';
  import ReelOrderCartTable from '@/components/tables/CartOrderTable';
  import CustomerOrderInformation from '@/components/CustomerOrderInformation.vue';
  import IconTitle from '@/components/IconTitle.vue';
  import Overlay from '@/components/Overlay.vue'
  import BackBtn from '@/components/BackBtn.vue';
  import BlockedByBackordersAlert from '@/components/BlockedByBackordersAlert'

  import numbersFormats from '@/mixins/numbersFormats';
  import userBlockedByBackorders from '@/mixins/userBlockedByBackorders';
  import ValidateCustomerStorage from "@/components/modals/ValidateCustomerStorage.vue"; 


  import api from '@/plugins/axios';
  import { mapGetters, mapActions } from 'vuex';
  import { generatePurchaseOrderId } from '@/repositories/Orders';

  const finantialErrorsCountInit = { commercial: 0, manager: 0, gpom4: 0 }

  export default {
    name: 'CreateReelReminderOrder',

    components: { 
      ValidateCustomerStorage,
      IconTitle, 
      ShippingForm,
      AddMaterialForm,
      CustomerOrderInformation, 
      ReelOrderCartTable,
      Overlay,
      BackBtn,
      BlockedByBackordersAlert
    },

    mixins: [numbersFormats, userBlockedByBackorders],

    data(){
      return {
        cart: [],
        materials: [],
        loadingMaterial: false,
        updatingMaterial: false,

        customerData: null,
        customerCreditData: null,
        loadingCustomerData: false,
        loadingReels: false,
        customerDataError: false,
        materialErrorMessage: '',

        paymentValues: {
          cfdiRelac: [],
          paymentMethod: [],
          wayToPay: [],
          cfdiUse: [],
        },

        orderForm: {
          orderConcept: '',
          shippingAddressId: '',
        },

        shippingAddressList: [],
        invalidOrderDataMessage: '',

        finantialErrorsCount: { ...finantialErrorsCountInit },

        orderWillBeBlockedMessage: '',
        blockStatus: 1,
        orderCreated: false,
        savingOrder: false,
      }
    },

    async created() {
      this.loadingCustomerData = true;

      this.getAvailableMaterials();
      this.orderForm.orderConcept = await generatePurchaseOrderId('backorder');

      await this.loadCustomer(); // Loads user data if the user is a seller it will load data of a preselected customer
      
      if (this.noCustomerSelected) // If seller is signed and no preselected customer, we redirect to home
        this.$router.replace('/');
      else if(!this.loadCustomerError) { // If a error happens while loading preselected customer
        await this.fetchCustomerInfo();
        this.validateUserBlockedByBackorders();
      }

      this.sendToPDF({ active: true });      
      this.loadingCustomerData = false;
    },

    methods: {
      ...mapActions('selectedUser', ['loadCustomer']),
      ...mapActions('printer', ['sendToPDF']),

      async fetchCustomerInfo() {
        try {
          const customerInfo = api.post('/get-customer-info', this.customer, { cachable: true });
          const crediData = api.post('/get-customer-credit-data', this.customer);
          const addresses = api.post('/destinyCustomer', this.customer, { cachable: true });

          const [{ data }, { data: creditData }, { data: customerDestinyList }] = await Promise.all([customerInfo, crediData, addresses]);
          
          this.customerData = data.customerData;
          this.customerCreditData = creditData;
          this.shippingAddressList = customerDestinyList;
        } catch (error) {
          this.customerDataError = true;
        }
      },

      async getMaterialData(form) {
        // validate form 
        this.loadingMaterial = true;

        const material = await this.fetchMaterial(form.materialId, form.requiredQty);

        if (material)
          this.cart.push(material);
        
        form.materialId = '';
        form.requiredQty = null;
        this.loadingMaterial = false;
      },

      async updateCartItemQty(item, index) {
        this.updatingMaterial = true;
        const material = await this.fetchMaterial(item.materialId, item.unitsRequired);

        if (material)
          this.cart.splice(index, 1, material)
        else 
          this.cart.splice(index, 1);

        this.updatingMaterial = false;
      },

      removeFromCart(index) {
        this.cart.splice(index, 1);
      },

      async fetchMaterial(materialId, qty) {
        const requiredQty = parseInt(qty).toString();
        this.materialErrorMessage = '';
        
        try {
          const response = await api.post('/reel-reminder-material', { ...this.customer, materialId, requiredQty });
          return response.data
        } catch (error) {
          this.materialErrorMessage = this.resolveHttpError(error, [400], 'Sucedió un error al recuperar la información del material');
        }

        return null;
      },

      async storeOrder(){
        this.invalidOrderDataMessage = '';
        const dataValidMessage = this.validateOrderData();
        const orderConceptGpom4 = this.finantialErrorsCount.gpom4 >= 1 ? '-BC' : '' ;

        if (dataValidMessage != null) {
          this.invalidOrderDataMessage = dataValidMessage;
        } else {
          const form = {
            ...this.customer,
            ...this.orderForm,
            orderConcept: `${this.orderForm.orderConcept}${orderConceptGpom4}`,
            cart: [],
            blockStatus: this.blockStatus,
            newReelReminderList: this.cart,
            createdBySeller: !this.loggedinUserIsCustomer,
            sellerCode: this.userCode,
          }

          try {
            this.savingOrder = true;
            await api.post('/store-reel-order-reminder', form);
            this.orderCreated = true;

            this.goToHistory();
          } catch ({ response }) {
            this.invalidOrderDataMessage = this.resolveHttpError(response, [400], 'No se pudo crear el recordatorio');
          }

          this.savingOrder = false;
        }
      },

      async getAvailableMaterials(){
        this.loadingReels = true;

        try {
          const response = await api.get('/materials-in-reel-list');
          const materials = response.data;

          this.materials = materials.map(r => ({ value: r.MATNR, text: `${this.removeLeftZeros(r.MATNR)} - ${r.MAKTX}` } ))
        } catch (error) {
          this.materialErrorMessage = 'No se pudo obtener la lista de materiales'
        }

        this.loadingReels =  false;
      },

      validateOrderData(){
        const invalidOrderCharacter = /[|]|[~]|[¬]|[']/.test(this.orderForm.orderConcept);

        if (invalidOrderCharacter)
          return 'Carácter inválido en orden de compra';
        else if (this.orderForm.orderConcept == '' && this.orderForm.shippingAddressId == '') 
          return 'Favor de completar orden de compra y destino';
        else if (this.orderForm.orderConcept == '') 
          return 'Favor de completar orden de compra';
        else if (this.orderForm.shippingAddressId == '')
          return 'Favor de completar destino';

        return null;
      },

      checkOrderBlocks(){
        this.finantialErrorsCount = { ...finantialErrorsCountInit };
        this.orderWillBeBlockedMessage = '';

        const { commercial, manager } = this.finantialErrorsCount;

        this.cart.forEach(({ finantialErrors }) => {
          this.finantialErrorsCount.commercial += finantialErrors.commertial ? 1 : 0;
          this.finantialErrorsCount.manager += finantialErrors.manager ? 1 : 0;
          this.finantialErrorsCount.gpom4 += finantialErrors.gpom4 ? 1 : 0;
        })
        
        this.blockStatus = 1;

        if(this.noCreditProblems){
          if(commercial != 0 && manager == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización gerencia de planeación.';
            this.blockStatus = 3;
          } else if (commercial == 0 && manager != 0) {
            this.orderWillBeBlockedMessage  = 'El pedido será bloqueado para autorización de la gerencia.';
            this.blockStatus = 5;
          } else if (commercial != 0 && manager != 0) {
            this.blockStatus = 7;
            this.orderWillBeBlockedMessage  = 'El pedido será bloqueado para autorización de la gerencia y comercial.';
          }
        } else {
          if (manager == 0 && commercial == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado por crédito';
            this.blockStatus = 2;
          } else if (commercial != 0 && manager == 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización comercial y crédito.';
            this.blockStatus = 4;
          } else if (commercial == 0 && manager != 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización de la gerencia y crédito.';
            this.blockStatus = 6;
          } else if (commercial != 0 && manager != 0) {
            this.orderWillBeBlockedMessage = 'El pedido será bloqueado para autorización de la gerencia, comercial y crédito.';
            this.blockStatus = 8;
          }
        }
      },

      formatReelsData(reels){
        return reels.map(reel => ({
          ...reel,
          matnr: this.removeLeftZeros(reel.MATNR),
          kwmeng: this.formatNumber(reel.KWMENG, ''),
          POSNR: this.removeLeftZeros(reel.POSNR)
        }))
      },

      resolveHttpError(error, statusCodes = [], defaultMessage = ''){
        const { response } = error;

        if(response && statusCodes.includes(response.status))
          return response.data.message;

        return defaultMessage;
      },

      goToHistory(){
        setTimeout(() => {
          this.$router.push('/history-orders')
        }, 5000);
      },
    },

    computed: {
      cartSubtotal() {
        return this.cart.reduce((sum, currentValue) => sum + parseFloat(currentValue.productPrice), 0);
      },

      cartTotalIva() {
        const iva = 0.16;
        return this.cartSubtotal * iva;
      },

      cartTotal() {
        return this.cartSubtotal + this.cartTotalIva;
      },

      cartTotals() {
        return { total : this.formatNumber(this.cartTotal), subtotal: this.formatNumber(this.cartSubtotal), iva: this.formatNumber(this.cartTotalIva)}
      },

      customerCredit(){
        const credit = this.customerData?.CREDD || '0';
        const creditNoCommas = credit.replace(/,/gi, '');

        return parseFloat(creditNoCommas);
      },

      availableCredit(){
        return this.customerCredit - this.cartTotal;
      },

      availableCreditFormated(){
        return this.formatNumber(this.availableCredit);
      },

      walletPastDueBalance(){
        return this.customerCreditData?.wallet?.T7 || null
      },

      pastDueBalanceCount(){
        return this.customerCreditData?.pastDueBalancesCount || 0;
      },

      walletPastDueBalanceFormated(){
        if(this.walletPastDueBalance === null) return '';

        return this.formatNumber(this.walletPastDueBalance);
      },

      noCreditProblems(){
        if(this.pastDueBalanceCount > 0 ||  this.customerCredit < 0 || this.availableCredit < 0){
          return false;
        }

        return true;
      },
      
      orderStatus(){
        if(this.walletPastDueBalance === null)
          return 'Análisis Crediticio 2'
        else if(this.availableCredit < 0 || this.walletPastDueBalance < 0)
          return 'Análisis Crediticio'
        else
          return 'Correcto'
      },

      hideHeaders(){
        const headers = ['reelNumber', 'unitsConfirmed', 'textBox'];
        if(this.loggedinUserIsCustomer) headers.push('stockCdpt', 'stock');

        return headers;
      },

      canStartUsingUI(){
        return !this.loadingCustomerData && !this.noCustomerSelected && !this.loadCustomerError
      },

      ...mapGetters('auth', ['channel', 'userCode', 'user']),
      ...mapGetters('selectedUser', ['customer', 'loadCustomerError', 'noCustomerSelected', 'loggedinUserIsCustomer']),
    },
  }
</script>

<style scoped>
.button-container {
  display: flex;
  justify-content: right;
}

.button-container > button {
  width: 400px;
  max-width: 400px;
  border-radius: 8px;
}

@media screen and (max-width: 960px) {
  .button-container > button {
    width: 100%;
    max-width: auto;
  }
}
</style>