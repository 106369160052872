<template>
  <div class="text-center content-wrapper">
    <div class="title-container d-flex justify-center align-center">
     <h5  style="margin-top: 15px;"><b>Actualización de datos</b></h5>
    </div>

    <h6><b>¡Bienvenido (a) "{{ userName }}"!</b></h6>

    <p class="h6">
      Es importante que verifiques y actualices tu número de teléfono y correo
      electrónico. Esto garantiza que los clientes de IEL se puedan comunicar de
      manera satisfactoria contigo. La actualización es rápida y sencilla, solo
      te tomará un momento.
    </p>

    <v-form ref="form" class="form-container">
      <v-text-field
      class="ma-3"
        label="Número de Teléfono *"
        v-model="form.phone"
        :rules="[rules.required, rules.phone]"
        required
        dense
      />

      <v-text-field
      class="ma-3"
        label="Correo Electrónico *"
        v-model="form.email"
        :rules="[rules.required, rules.email]"
        required
        dense
      />

      <p>*Indica campo obligatorio</p>
    </v-form>

    <div class="button-container">
      <v-btn
        :disabled="loading"
        class="green-button w-xs-100"
        @click="submitForm"
      >
        <template v-if="!loading">
          Guardar
          <v-icon color="white" class="ml-2">mdi-check-bold</v-icon>
        </template>
        <template v-else>
          <v-progress-circular indeterminate color="white" size="20"></v-progress-circular>
          Cargando...
        </template>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: "DataUpdateModalContent",
  props: {
    userName: {
      type: String,
      required: true,
    },
    loading: Boolean,
  },
  data() {
    return {
      form: {
        phone: null,
        email: null,
      },
      rules: {
        required: (value) => !!value || "Campo requerido",
        phone: (value) => {
         const valid = /^\d{10}$/.test(value);  
          return valid ? true : "Número de teléfono inválido o incorrecto (deben ser 10 dígitos)";
        },
        email: (value) =>
          /.+@.+\..+/.test(value) || "Correo electrónico inválido",
      },
    };
  },

  methods: {
    async submitForm() {
      if (this.$refs.form.validate()) {
        this.$emit('on-save', this.form);
      } else {
        alert(
          "Por favor, completa todos los campos obligatorios correctamente."
        );
      }
    },
  },
  computed:{
    ...mapGetters({ 
        user: "auth/user",
      }),
  },
  mounted(){ 
    this.form.email = this.user.email;
    this.form.phone = this.user.phone;
  }
};
</script>

<style scoped>
.content-wrapper {
  position: relative;
  padding-bottom: 60px; 
}

.v-form {
  width: 100%;
}

.v-text-field {
  width: 100%;
}

h5 {
  color: #000000;
}

.button-container {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px;
}

h6, p {
    color: #00000099;

}
</style>