<template>
  <v-dialog v-model="isOpen" max-width="800px">
    <v-card>
      <v-card-text class="pt-5 pb-0">
        <iframe
          :src="videoSrc"
          style="width: 100%; height: 30em"
        ></iframe>
      </v-card-text>      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="closeModal">
          Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
        type: Boolean,
        default: false,
    },
  },
  computed:{
    isOpen: {
      get(){        
				return this.value;
			},
			set(newValue){
				this.$emit('input', newValue);
			}			
		},
  },
  data() {
    return {
      videoSrc: ''
    };
  },
  methods:{
    closeModal() {
      this.isOpen = false;
      this.videoSrc = '';
    }
  },
  watch:{
    isOpen(newValue){
      if(newValue){
        this.videoSrc = 'https://www.youtube.com/embed/LHrrudI5zdc';
      }
    }
  }
};
</script>

<style>
</style>