<template>
    <div class="my-select">
      <search-customer-seller :showGoback="false">
          <template v-slot:right-actions v-if="isAvaliable">
            <ChangePasswordControl /> 
            <data-update-step-launcher :is-large="true" v-if="isRegionalManager"/>
          </template>  
      </search-customer-seller>   
    </div>
  </template>
  
  <script>
  import SearchCustomerSeller from '@/components/SearchCustomerSeller.vue'
  import ChangePasswordControl from '@/components/ChangePasswordControl.vue';
  import DataUpdateStepLauncher from "@/template/partials/stepsAfterLogin/DataUpdateStepLauncher.vue";

  export default {
    name: "SearchBar", 
    components: { 
      SearchCustomerSeller,
      ChangePasswordControl,
      DataUpdateStepLauncher
  }, 
    data() {
      return { 
      };
    },
    computed: { 
      isAvaliable(){ 
        //Esta disponible solo cuando la vista corresponde al rol. 
        // hay vistas que no son del mismo rol y suelen compartirse (Diario director)
      switch (this.$route.meta.role) {
        case 'Director':
            if (this.$hasAnyRole(["Director", "IELDirector","IELCommercialPR", "IELCommercialDT","Subdirector"]))  return true; 
            break;   
        case 'Regional':
            console.log('roles', this.isRegionalManager);
            return this.isRegionalManager;  
        } 
      return false;
    },
    isRegionalManager(){
      return this.$hasAnyRole(["Regional", "RegionalManager","IELRegManager"]); 
    } 
    },

  };
  </script>
