<template>
  <div>
      <v-simple-table class="mt-0 my-table pb-0" dense>
              <template v-slot:default>
                <thead class="table-heading">
                  <tr>
                    <th v-for="(item,index) in headers" :key="index" class="text-center text-dark custom-border-right">
                      <b v-html="item.title" />
                    </th>     
                  </tr>  
                </thead>
                <tbody class="custom-table-bordered">
                  <template v-if="!loading">
                    <tr class="text-body-2" v-if="!orderErrors">
                      <td v-for="(item,index) in headers" :key="index" class="text-center text-dark">
                        <div :class="setItemClass(item.value)" @click="setEvents(item.value)"> 
                          {{formatItem(item.value)}} 
                        </div>
                      </td>     
                    </tr> 
                  </template> 
                  <template v-else>
                    <tr> 
                      <th colspan="6">
                        <v-progress-linear indeterminate color="cyan" />
                      </th>
                  </tr>
                  </template>
               
                </tbody>
              </template>
            </v-simple-table> 
            <alert-prominent  
              v-if="orderErrors" 
              indeterminate type="error"
              :message="orderErrors"
              :dismissible="false"
              :withAction="true"
              :disabledButton="loading"
              @click="loadOrders()"
              /> 

  <v-dialog  v-model="showModal" width="auto">
      <v-card class="pb-4"> 
        <CumulativeGrowth 
        :title="`Detalle de crecimiento constante de Gerencia del mes: ${months[0].date}`"
        :isBilling="1"
        :manager="manager"
        :type="''"
        :channel="managerData.VTWEG"
        :period="months[0]" 
        :modal="showModal"
        @closeModal="showModal = false"
         /> 
      </v-card>
    </v-dialog> 
  </div>
</template>

<script>
  
import CumulativeGrowth from './modals/CumulativeGrowth.vue';
import AlertProminent from '@/components/AlertProminent.vue';
import numbersFormats from '@/mixins/numbersFormats';
import service from '@/mixins/service';
import axios from "@/plugins/axios";
import MixinHelpers from '@/mixins/helpers';
export default ({
  name:'NetSales',
  props: ['manager','managerData'],
  mixins: [MixinHelpers,numbersFormats,service],
  components:{ 
  CumulativeGrowth,
  AlertProminent
},
  data: () => ({
      customerSales:false,
      showModal:false,
      orders:[],
      loading:true,
      months:[],
      orderErrors:"",
  }),
  computed: {
    headers() {
      return [
        { 
          title: `Pedidos <br> de ${this.months[0].full_name}`, 
          value: 'PEDMES' 
        },
        { 
          title: `Promedio mensual de pedidos <br> ${this.getMonths}`, 
          value: 'PROP3M' 
        },
        { 
          title: `Pedidos del <br> día anterior`, 
          value: 'PEDDANT' 
        },
        { 
          title: `Acum. <br> ${this.months[1].date}`, 
          value: 'CREACUFAC' 
        },
        { 
          title: `Avance facturación <br> vs <br> presupuesto ${this.months[0].full_name}`, 
          value: 'AVPTOFACACT' 
        },
        { 
          title: `Avance facturación <br> vs <br> presupuesto acumulado`, 
          value: 'AVPTOFACACU' 
        },
      ]
    },
    getMonths() {
      var months = this.months;
      return this.implode(this.arrayPluck(months.slice(1, 4), 'short_name'), '/')
    },
  },
  watch:{
    manager(){
      this.loadOrders()
      }
  },
  created(){
        this.months = this.calcularUltimosMeses(4); 
      }, 
  methods:{
    formatItem(index){
    const value = this.orders[index] || '?';
      switch (index) {
        case 'PEDMES':
        case 'PROP3M':
        case 'PEDDANT':
          return `${this.formatNumber(value,'$',0)}`
        case 'CREACUFAC':
        //Se resta 100 solo cuando el valor es positivo
          var billing = value > 0 ? (value - 100) : value;
          return `${this.formatFloat(billing,2)}%`
        case 'AVPTOFACACT':
        case 'AVPTOFACACU':
        //Se resta 100 solo cuando el valor es positivo 
          return `${this.formatFloat(value,2)}%`
      }
      return value;
    },
    setEvents(index){
      switch (index) {
        case 'CREACUFAC':
          this.showModal = true; 
        break;  
      }
    },
    setItemClass(index) {
      switch (index) {
        case 'CREACUFAC':
            return 'text-decoration-underline cursor-pointer'  
      } 
    },
    async  loadOrders(){  
      this.loading=true;
      try {
        const res = await axios.post('/manager/home/orders',{
          manager: this.managerData.PERNR,
          channel: this.managerData.VTWEG
        });
        this.orders = res.data;
        this.orderErrors="";
      } catch (error) {
        console.error(error) 
        this.orders = [];
        this.orderErrors = `Código: ${error.response.status} `
      } 
      this.loading = false;
        
    },
  }
})
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>