<template>
      <v-card>
        <v-card-text>
          <v-row class="mb-5">
            <v-col>
              <v-card class="mx-auto">
                <v-divider></v-divider>
                <v-card-text>
                  <iframe
                    src="https://www.youtube.com/embed/LHrrudI5zdc"
                    style="width: 100%; height: 30em"
                  ></iframe>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeForm"> Cerrar </v-btn>
        </v-card-actions>
      </v-card>
  </template>
  <script>
  export default {
    name: "BonusCard",
    methods:{
        closeForm(){
            this.$emit("closeModa",true);
        }
    }
  
  };
  </script>
  <style scoped src="../../../assets/css/accountmove.css"></style>
  