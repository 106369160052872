<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <a :href="getUrl" :class="linkClass" target="_blank" rel="noopener noreferrer">
          <v-icon v-if="!text" v-bind="attrs" color="black" v-on="on" :size="iconSize">
            mdi-{{ icon ? icon : policies[policyType].icon }}
          </v-icon>
          <p v-else v-bind="attrs" class="black--text underline-black text-decoration-color-black" v-on="on">DESCARGAR PDF</p>
        </a>
      </template>
      <span>{{ title ? title : policies[policyType].title }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
name: 'SalesPolicyFileDownload',
props: {
  color: { 
    default: "gray"
  },
  icon: { 
    default: ""
  },
  iconSize: { 
    default: 25
  },
  linkClass: {
    default: ''
  },
  policyType: {
    default: 'delivery'
  },
  title: {
    default: ''
  },
  text: { 
    type: Boolean,
    default: false
  }
},
data() {
  return {
    policies: {
      delivery: { 
        url: `${this.$filesUrl}/documentation/POLITICA_ENTREGAS_A_CLIENTES.pdf`,
        target: "_blank",
        download: "POLITICA_VENTAS_CRÉDITO_MINORISTAS.pdf",
        title: 'Política de entrega', 
        icon: 'truck-delivery-outline',
        color: "black" 
      },
      sellCredit: { 
        url: `${this.$filesUrl}/documentation/POLITICA_VENTAS_CRÉDITO_MINORISTAS .pdf`,
        target: "_blank",
        download: "POLITICA_VENTAS_CRÉDITO_MINORISTAS.pdf",
        title: 'Política de crédito',
        icon: 'file-certificate-outline'  
      },
      sell: { 
        url: '', 
        title: 'Política de ventas', 
        icon: '' 
      },
      car: { 
        url: '', 
        title: 'Política de auto', 
        icon: '' 
      }
    }
  };
},
computed: {
  ...mapGetters({
    user: "auth/user",
  }),
  userChannel() {
    return this.user?.VTWEG;
  },
  getUrl() {
    if (this.policyType === 'sellCredit') {
      if (this.userChannel === 'PR') {
        return `${this.$filesUrl}/documentation/POLITICA_VENTAS_CRÉDITO_DISTRIBUCIÓN.pdf`;
      }
      return this.policies.sellCredit.url;
    }
    return this.policies[this.policyType]?.url || '#';
  }
}
}
</script>