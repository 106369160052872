<template>
	<v-alert text prominent :type="type" dense :dismissible="dismissible" icon="mdi-alert-decagram-outline">
		{{ title }} <b> {{ message }} </b>
		<div class="shrink" v-if="withAction && totalClick <= maxClicks && !disabledButton">
			<v-btn :loading="disabledButton" @click="actionHandle">{{ buttonLabel }}
				<template v-slot:loader>
					<span>{{ loadingLabel }}</span>
				</template>
			</v-btn>
		</div>
		<b v-if="totalClick > maxClicks  && !disabledButton"> <br>
			<v-icon :color="type">mdi-information-outline</v-icon> Por favor intente más tarde o contacte a soporte técnico si el problema persiste
			</b>
	</v-alert>
</template>

<script>
const buttonActions = ['error', 'info'];

export default {
	name: 'AlertProminent',
	props: {
		type: {
			String,
			default:'error',
			validator: (value) => buttonActions.includes(value)
		},
		dismissible: { 
			default:false
		},
		message: {
			String, 
			default:''
		},
		withAction: {
			default: false
		},
		title: {
			String,
			default: 'Ops, Hubo problemas al intentar cargar la información.',
		},
		buttonLabel: {
			String,
			default: 'Reintentar'
		},
		disabledButton:{
			default:false,
		},
		maxClicks:{
			default:1
		},
		loadingLabel:{
			default:'Cargando...'
		}
	},
	data() {
      return {
		totalClick:0, 
      };
    },
	methods:{
		actionHandle(){
			this.totalClick++;
			this.$emit('click');
		}
	},
	computed: {
	}
}
</script>