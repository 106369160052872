<template>
  <div>
    <p></p>
    <v-row>
      <v-col md="6" style="margin-top: 25px">
        <v-img src="img/logo-banorte.svg" alt="BANORTE"></v-img>
        <input
          type="radio"
          style="margin-top: 10px"
          :id="userChannel == 'DT' ? 1 : 11"
          :value="userChannel == 'DT' ? 1 : 11"
          v-model="selectedBank"
          v-on:change="alertAmount($event)"
        />
      </v-col>
      <v-col md="6">
        <v-img src="img/logo-bbva.svg" alt="BBVA"> </v-img>
        <input
          type="radio"
          :id="userChannel == 'DT' ? 2 : 12"
          :value="userChannel == 'DT' ? 2 : 12"
          v-model="selectedBank"
          v-on:change="alertAmount($event)"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <v-img src="img/logo_santander.png" alt="SANTANDER"> </v-img>
        <input
          type="radio"
          :id="userChannel == 'DT' ? 3 : 13"
          :value="userChannel == 'DT' ? 3 : 13"
          v-model="selectedBank"
          v-on:change="alertAmount($event)"
        />
      </v-col>
      <v-col md="6" style="margin-top: 15px">
        <v-img src="img/logo_banamex.png" alt="BANAMEX"> </v-img>
        <input
          :disabled="false"
          type="radio"
          :id="userChannel == 'DT' ? 4 : 14"
          :value="userChannel == 'DT' ? 4 : 14"
          style="margin-top: 10px"
          v-model="selectedBank"
          v-on:change="alertAmount($event)"
        />
      </v-col>
    </v-row>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "AlertModal",
  props: {
    value: {
      default: 0,
    },
    userChannel: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedBank: this.value,
    };
  },
  methods: {
    alertAmount() {
      this.$emit("alertAmountChange", this.selectedBank);
    },
  },
  watch: {
    value(newVal) {
      this.selectedBank = newVal;
    },
  },
};
</script>
