<template>
  <v-dialog v-model="isOpen" persistent max-width="1000px">
    <v-card v-if="customers.length <= 0" class="p-5">
        <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
        ></v-progress-circular>
        <p class="mt-2 mb-0">Cargando datos...</p>   
    </v-card>

    <v-card class="card__dialog" v-else>
      <v-card-title class="d-flex justify-content-center">
        <h6>Ubicaciones de Clientes 
            <span v-if="option == 1">de Zona {{ customers[0].PBZIRK }} [{{ customers[0].BZIRK }}]</span>
            <span v-if="option == 2">por Gerencia</span>
            <span v-if="option == 3">por Gerente Regional</span>
            <span v-if="option == 4">por Director</span>
        </h6>

        <v-card-actions class="card__close">
          <v-btn icon dark @click="isOpen = false">
            <v-icon color="white">mdi-close-circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card-title>

      <v-card-text class="card__text">
        <div class="row">
          <div class="col-12 col-sm-7 col-md-8">
            <location-customers 
                :locations="locationArray">
            </location-customers>
          </div>
          <div class="col-12 col-sm-5 col-md-4">
            <filter-location-customers
              :customers="setCustomers"
              :namesLabels="namesLabels"
              @filterLocation="filterLocation">
            </filter-location-customers>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import LocationCustomers from './components/LocationCustomers.vue'
import FilterLocationCustomers from './components/FilterLocationCustomers.vue'
export default {
    props:{
        value: {
            type: Boolean,
            default: false,
        },
        customers:{
            type: Array,
            required: true
        },
        type:{
            type: String,
            default: 'byDaysOfWeek'
        }
    },
    components: {
        LocationCustomers, 
        FilterLocationCustomers
    },
    data(){
        return{
            checkGroup: [],
            locationArray: [],
            namesLabels: [
                { name: 'lunes', color: '#FFFF00' },
                { name: 'martes', color: '#f44336' },
                { name: 'miércoles', color: '#9c27b0' },
                { name: 'jueves', color: '#4caf50' },
                { name: 'viernes', color: '#2196f3' },
                { name: 'sábado', color: '#ff9800' }
            ],
            option: 1
        }
    },
    methods:{
        filterLocation(data){
            this.checkGroup = data;
            this.createLocationArray(this.setCustomers);
        },
        createLocationArray(customers) {
            this.locationArray = customers.filter(customer => 
                customer.location && 
                this.checkGroup.includes(customer.label)
            ).map(customer => ({
                lat: customer.location.latitude,
                lng: customer.location.longitude,
                color: this.getDayColor(customer.label),
                zone: customer.BZIRK,
                name: customer.NAME1,
                num_customer: this.$options.filters.formatCustomer(customer.KUNNR),
                address: `${customer.STREET} ${customer.HOUSE_NUM1} ${customer.CITY2} CP. ${customer.POST_CODE1}`,
                fact_neta: `${this.$options.filters.formatCurrency(customer.FNETA)}`
            }));
        },
        getDayColor(name){ 
            const nameLabel = this.namesLabels.find(label => label.name === name);
            return nameLabel ? nameLabel.color : null;
        },
        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },
        generateNamesLabels() {            
            const uniqueNames = [];
            for (const customer of this.customers) {
                if (customer.NAMEV !== '' && !uniqueNames.includes(customer.NAMEV)) {
                    uniqueNames.push(customer.NAMEV);                    
                }
            }

            this.namesLabels = uniqueNames.map(name => ({
                name: name,
                color: this.getRandomColor()
            }));
        },
        validateTypeNameLabel(customer){
            return this.option == 1 ? customer.planner?.[0]?.day || '' : customer.NAMEV;
        }
    },
    computed: {
		isOpen: {
            get(){
				return this.value
			},
			set(newValue){
				this.$emit('input', newValue)
			}			
		},
        setCustomers(){
            return this.customers.map(customer => {
                customer.label = this.validateTypeNameLabel(customer);
                return customer;
            });
        }
	},
    watch:{
        setCustomers:{
            deep: true,
            immediate: true, 
            handler(newVal) {
                switch(this.type){
                    case 'byDaysOfWeek': 
                        this.option = 1;
                        break;
                    case 'byManager':
                        this.option = 2;
                        this.generateNamesLabels(); 
                        break;
                    case 'byRegionalManager':
                        this.option = 3;
                        this.generateNamesLabels();
                        break;
                    case 'byDirector':
                        this.option = 4;
                        this.generateNamesLabels();
                        break;
                    default:
                        this.option = 1;
                        break;
                }                               
                this.checkGroup = this.namesLabels.map(label => label.name);
                this.createLocationArray(newVal);
            }
        }
    }
};
</script>

<style scoped>
.card__dialog{
    background: #583636 !important;
    color: #fff !important;
}
.card__close{
    position: absolute;
    top: 0;
    right: 0;
}
.card__text{
    color: #FFF !important;
    text-align: left;
}  
</style>