<template>
  <div>
    <v-card class="mt-5 custom-red-card" >
      <v-card-text>         
          <v-simple-table class=" my-t">
            <template v-slot:default>
              <thead class="table-heading">
                <tr>
                  <td colspan="2"> <!--Datos netos --> </td>
                  <td colspan="5">
                    <v-card class="mx-auto rounded-lg mt-3 mb-3 custom-card-border">
                      <v-card-title style=" " class="py-0 custom-card-title">
                        {{'PROMEDIO DIARIO DE PEDIDOS (DÍAS HÁBILES)'.replaceAll(' ','&nbsp;') }}
                      </v-card-title>
                    </v-card>
                  </td> 
                  <td colspan="2" /> 
                </tr>
                <tr>
                  <th v-for="(item,index) in ordersHeader" :key="index" 
                  class="text-center text-dark"
                  :style="index == 1 ?' border-width: 0px 2px 0px 0px;':''">
                    <b v-html="item.title.replaceAll(' ','&nbsp;')" />
                  </th> 
                  </tr>
              </thead> 
                <tbody>
                  <tr  class="text-center">
                    <template v-if="!loading && !errors" >
                    <td v-for="(item,index) in ordersHeader" :key="index" >
                      <b>{{ formatItem(item.value) }}</b>
                    </td>
                  </template>
                    <template v-else-if="!errors">
                      <td colspan="12">
                        <span class="text-secondary">Cargando información...</span>
                        <v-progress-linear :indeterminate="loading" color="teal"></v-progress-linear>
                      </td> 
                    </template>  
                  </tr>
              </tbody>  
            </template>
          </v-simple-table>
          <v-simple-table class="mt-4 my-table">
            <template v-slot:default>
              <thead class="table-heading">
                <tr>
                  <th colspan="3" style="height:0px;"></th>
                  <th colspan="4" style="height:0px;" class="mb-n10 text-center text-dark"> <b class="text-header">
                      Facturación neta </b></th>
                </tr>
                <tr>
                  <th v-for="(item,index) in customersHeader" :key="index" rowspan="2" class="text-center text-dark">
                    <b v-html="item.title.replaceAll(' ','&nbsp;')"> </b>
                  </th>  
                </tr>
              </thead>
              <tbody>

                <tr  class="text-center">
                    <template v-if="!loading && !errors" >
                    <td v-for="(item,index) in customersHeader" :key="index" >
                      <span :class="setItemClass(item.value[0])"  
                      v-html="outputFormat(item.value)"> </span>
                      <v-tooltip bottom 
                      v-if="item.value[0] == 'MAXPCTM'">
                            <template v-slot:activator="{ on, attrs }">
                              <span  v-bind="attrs" v-on="on" >  / {{ formatItem('MAXPPAM') }}</span>
                            </template>
                            <span class="text-left">
                              Monto pagos línea {{months[1].short_name }}: {{formatItem('PIUSALM')}} <br>
                              Monto pagos línea ultimo trimestre: {{formatItem('PIUSAL')}} <br>
                              Porcentaje de pagos en línea ultimo trimestre : {{formatItem('MAXPPA')}}
                            </span>
                          </v-tooltip>
                    </td>
                  </template>
                    <template v-else-if="!errors">
                      <td colspan="12">
                        <span class="text-secondary">Cargando información...</span>
                        <v-progress-linear :indeterminate="loading" color="teal"></v-progress-linear>
                      </td> 
                    </template>
                  </tr>
                  <tr v-if="errors">
                    <td colspan="12"> 
                        <alert-prominent
                          :message="errors"
                          :disabledButton="loading"
                        />
                      </td> 
                  </tr> 
              </tbody>
            </template>
          </v-simple-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>

import axios from "@/plugins/axios"; 
import moment from "moment";
import MixinService from "@/mixins/service"
import numbersFormats from "@/mixins/numbersFormats";
import AlertProminent from "@/components/AlertProminent.vue";
export default ({
  mixins:[MixinService,numbersFormats],
  props:['seller'],
  components:{AlertProminent},
  watch: { 
    seller(){
      this.getData();
    }
  },
  name: 'AverageOrders',
  data() {
    return {
      loading: true,
      averageOrders: [],
      now: new moment(),
      maxTrim: 0,
      minTrim: 0,
      months:[],
      lastQuarters:[],
      errors:"",
      customersIndexes:
        [
          ['MAXPCTM','MAXPPAM','PIUSALM','MAXPPA'],
          ['NTCS','NTCA','NTCN1','NTCN3','NTCN6'],
          ['NTCTE4','NTCTE3','NTCTE2','NTCTE1','NTCTEA','NCTEC4','NCTEC3','NCTEC2','NCTEC1','NCTECA'],
          ['FATR03','FATR02','FATR01','FATRAC']
        ]
      ,
    }
  },
  computed: { 
    ordersHeader(){  
      return[
          {title: `Pedidos de <br> ${this.months[0].full_name}`, value:'PMONOM'},
          {title: `Promedio mens <br> de pedidos <br> ${this.lastMonths(3)}`, value:'PMON3M'},
          {title: `${ this.customFormatedDate(this.months[12]) } - ${ this.customFormatedDate(this.months[1]) } (${ this.NHAB12 })`, value:'PMON12'},
          {title: `${ this.customFormatedDate(this.months[1]) } - ${this.customFormatedDate(this.months[2]) } (${ this.NHMAMA })`, value:'POMAMA'},
          {title: `Monto de pedidos <br> día anterior`, value:'PMONOA'},
          {title: `Backorder <br> Cancelado <br> Del mes`, value:'BOCANM'},
          {title: `Backorder <br> Cancelado <br> Del día ant.`, value:'BOCADA'},
          {title: `Avance de <br> facturación <br> VS <br> Ppto mes actual`, value:'AVPTOFACACT'},
          {title: `Crecimiento <br> acumulado a mes <br> cerrado/anterior`, value:'CRECACU'},
      ];  
    },
 
    customersHeader(){  
    var headers = [
          {title: `% del Cte. Más <br> Grande / % de <br> pagos en Línea`, 
            value:this.customersIndexes[0]},
          {title: `# de Clientes <br> Sistema / Activos <br>Clientes Nuevos <br> 1  / 3 / 6 Meses`, 
            value:this.customersIndexes[1]},
          {title: `# de Clientes<br> /Con compra <br> ${this.lastMonths(4)}`, 
            value:this.customersIndexes[2]},
      ];
      for (let index =  0; index < this.lastQuarters.length  ; index++) { 
        const item = this.lastQuarters[index];
        headers.push({
          title:`Trim ${item?.quarter}/${item?.year} (${this.getBusinessdatesFromQuarter(item?.quarter,item?.year)})`,
          value:[this.customersIndexes[3][index]]
        });
      }   
      return headers;
    },

  },
  methods: {
    getBusinessDays(substractFinishDate,substractStartDate){
      var startDate =  moment().startOf('month').subtract(substractStartDate, 'month').startOf('month'); 
      var finishDate =  moment().subtract(substractFinishDate, 'month').endOf('month');  
      return this.getBusinessDaysFromDates(startDate,finishDate);
    },  
    customFormatedDate(date){
      
      return date.date.replace(' ',' / ');
    },
    setItemClass(key){
      var billing = this.customersIndexes[3];

      if (billing.includes(key)) { 
          if (this.maxTrim == this.averageOrders[key] && this.maxTrim > 0) return 'text-success';
          if (this.minTrim == this.averageOrders[key] )  return 'text-danger';
      }
    return '';
    },
    outputFormat(item){
      const firstValue = item[0];
      const cstInSistK = this.customersIndexes[1];
      const cstWBK = this.customersIndexes[2];
 
      switch (firstValue) {
        case cstInSistK[0]: 
        // formato para umero de clientes en sistema
           return `${this.averageOrders[cstInSistK[0]]} / ${this.averageOrders[cstInSistK[1]]} <br> 
            ${this.averageOrders[cstInSistK[2]]} / ${this.averageOrders[cstInSistK[3]]} / ${this.averageOrders[cstInSistK[4]]} `;
        case cstWBK[0]: 
        // formato para Clientes con compra
        return `${this.averageOrders[cstWBK[0]]} / ${this.averageOrders[cstWBK[1]]} / ${this.averageOrders[cstWBK[2]]} / ${this.averageOrders[cstWBK[3]]} / ${this.averageOrders[cstWBK[4]]} <br>
        ${this.averageOrders[cstWBK[5]]} / ${this.averageOrders[cstWBK[6]]} / ${this.averageOrders[cstWBK[7]]} / ${this.averageOrders[cstWBK[8]]} / ${this.averageOrders[cstWBK[9]]} `;
      }     
      return this.formatItem(firstValue); 
    },
    lastMonths(months,separator = "/"){
      var label = "";
      for (let index = months; index >= 0; index--) {
        label = `${label} ${index < months ? separator:''} ${this.months[index].short_name}`
      } 
      return label;
    },
    formatItem(index){
      var value = this.averageOrders[index] || 0;
      switch (index) {
      //porcentajes
      case 'MAXPCTM':
      case 'MAXPPAM':
      case 'MAXPPA':
      case 'CRECACU':
      case 'AVPTOFACACT':
          return `${this.formatFloat(value,2)}%`
      //operaciones
      case 'PMON12': 
          value = parseFloat(value) / this.NHAB12;
          break;
      case 'POMAMA': 
          value = parseFloat(value) / this.NHMAMA;
          break;
      }
      //monedas por default
      return this.formatNumber(value,"$",0);
    }, 
    getData() {
      axios
        .post("/seller/home/orders", {
          seller_id: this.seller.PERNR,
          channel: this.seller.VTWEG
        },{
          noCancelRequest:true,
            cachable:true,
        })
        .then((response) => {
          if (response.data?.success == false) {
            this.errors = response.data.message;
          } else {
            this.averageOrders = response.data[0];
            let trims = [this.averageOrders.FATR01, this.averageOrders.FATR02, this.averageOrders.FATR03];
            this.minTrim = Math.min.apply(null, trims);
            this.maxTrim = Math.max.apply(null, trims);
            this.errors = "";
          }
          this.loading = false;
        })
        .catch((e) => {
          this.errors = e.response.status;
          console.log(e);
        }).finally(() => {
          this.loading = false;
        });
    },
  }, 
  created(){
    this.months = this.calcularUltimosMeses(13); 
    this.lastQuarters = this.getQuartersList(4).reverse();
    this.NHAB12 =  this.getBusinessDays(1,12);
    this.NHMAMA =  this.getBusinessDays(1,2); 
  }
})
</script>

<style scoped> .text-header {
   display: flex;
   margin-top: 0px;
   margin-bottom: -10%;
   justify-content: center;
 }
 .custom-card-title {
  color: #000;
  font-weight: bold;  
  justify-content: center;
  font-size: 0.9em;        
 }
 .custom-red-card{
  box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45) !important;
  -webkit-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45) !important;
  -moz-box-shadow: -1px 1px 7px 0px rgba(224,37,37,0.45) !important;
 }
 .custom-card-border{
  width: 95% !important; 
  border: 4px solid #000 !important;
  background-color: transparent !important; 
  box-shadow: inset 0px 0px 4px 2px rgba(0,0,0,0.1) !important;
 }
 </style>