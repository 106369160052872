import axios from '@/plugins/axios';

export const getBackorders = async (userId, channel, roles, axiosConfig = { noCancelRequest: true }) => {
  const response = await axios.post('/get-backorders-history', { userId, channel,roles }, axiosConfig );
  return response.data;
}

export const deleteBackorder = async (userId, idOrder, { firstReason, secondReason }) => {
  const deleteReminderData = {
    customer: userId,
    id_order: idOrder,
    comments: firstReason,
    comments_0: secondReason,
  }
  
  const response = await axios.post('/deleteReminder', deleteReminderData);
  return response.data;
}