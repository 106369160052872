<template>
    <v-card
    transition="dialog-bottom-transition"
    width="700px">

    <update-password-modal
    />
  </v-card>
  </template>
  
  <script>
  import UpdatePasswordModal from '@/components/modals/UpdatePasswordModal.vue';
  
  export default {
  
    components: {
      UpdatePasswordModal,
    },
  
  
  };
  </script>
  