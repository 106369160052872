<template> 
    <span class="d-flex mt mb-n4 justify-center">
      <v-btn :disabled="disabled" icon @click="sortData()">
        <v-icon :size="size" v-if="orderAsc">{{ iconUp }}</v-icon>
        <v-icon :size="size" v-else>{{iconDown}}</v-icon>
      </v-btn>
    </span> 
</template>

<script>
  
import MixinHelpers from '@/mixins/helpers'; 

export default {
  name: "SortByButton",
  props: {
    disabled:{
      default:false
    },
    iconUp:{
      default:"mdi-menu-up"
    },
    iconDown:{
      default:"mdi-menu-down"
    },
    data: {
      required:true,
    },
    column: {
      required:true,
    }, 
    isString: {
      default:true,
    },
    size:{
      default:"15"
    } 
  }, 
  data: () => ({
    orderAsc:true, 
  }),
  mixins: [ MixinHelpers],
  watch: {},
  methods: {
    sortData(){ 
          const config = {
              data:this.data,
              column: this.column,
              isString: this.isString,
              orderAsc:this.orderAsc
            }
        const sortedData = this.sortByCol(config);
        this.orderAsc = !this.orderAsc;
          this.$emit('sortedData',sortedData) 
      },
  },
  created() { 
  },
  mounted() { 
  }, 

};
</script>
