<template>
  <div class="data__container">    
    <v-row>
      <v-col>
        <!-- Titulo Cliente -->
        <title-header-table>
            <span>Datos del Cliente</span>
        </title-header-table>

        <div v-if="loader" style="background: #f5f5f5; padding: 0 10px">
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <p class="mb-0 py-2">Cargando... Por favor espere</p>
        </div>
        <div v-else class="pt-4">
          <v-row>
            <v-col cols="12" md="4" class="d-flex justify-content-center">
              <div class="text-center text-md-left d-table">
                Nombre del cliente:
                <slot></slot>
                {{ dataClient.NAME1 }}
                <p class="mb-0">
                  Domicilio: {{ dataClient.STREET }} N°
                  {{ dataClient.HOUSE_NUM1 }}, {{ dataClient.CITY2 }}. CP.
                  {{ dataClient.POST_CODE1 }}
                </p>
              </div>
            </v-col>
            <v-col cols="12" md="4">
              Número de Cliente: {{ dataClient.KUNNR | formatCustomer }}
              <p class="mb-0">RFC: {{ dataClient.STCD1 }}</p>
            </v-col>
            <v-col cols="12" md="4">
              <a href="#" @click="modalOpen = true">
                <v-icon color="green"> mdi-cash </v-icon>
              </a>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <!-- Título Cartera -->
        <title-header-table>
            <span>Cartera y línea de crédito al {{ dateCurrent }}</span>
        </title-header-table>          

        <div v-if="loader" style="background: #f5f5f5; padding: 0 10px">
          <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          <p class="mb-0 py-2">Cargando... Por favor espere</p>
        </div>
        <div v-else class="d-flex justify-content-center">
          <v-row style="max-width: 1200px" class="d-flex justify-content-between pt-3">
            <v-col md="5" cols="12">
              <div class="d-flex justify-content-between">
                <span>Límite de crédito:</span>
                <span>${{ dataClient.KLIMK | formatCurrency }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Línea de crédito comprometida:</span>
                <span>${{ dataClient.SKFOR | formatCurrency }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Línea de crédito disponible:</span>
                <span :style="'color:' + (dataClient.CREDD.includes('-') ? 'red' : 'green')">
                  ${{ dataClient.CREDD | formatCurrency }}
                </span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Plazo en días:</span>
                <span>{{ dataClient.VTEXT }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>PPDP últimos 3 meses (#docs):</span>
                <span>{{ dataClient.PPDP }} ({{ dataClient.DOCDP }})</span>
              </div>
            </v-col>
            <v-col md="5" cols="12">
              <div class="d-flex justify-content-between">
                <span>Saldo Vencido:</span>
                <span style="color: red">${{ dataClient.SVENC | formatCurrency }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Saldo Total:</span>
                <span>${{ dataClient.STOTA | formatCurrency }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Notas de Crédito por aplicar:</span>
                <span>${{ dataClient.DMBTRNC | formatCurrency }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <span>Pagos pendientes por aplicar:</span>
                <span>${{ dataClient.DMBTRDZ | formatCurrency }}</span>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>   

    <!-- Modal Video IUSA -->
    <modal-video-invoices v-model="modalOpen" />    
  </div>
</template>

<script>
import moment from "moment";
import ModalVideoInvoices from "@/components/modals/ModalVideoInvoices.vue";
import TitleHeaderTable from '@/components/tables/TitleHeaderTable';
export default {
  props: ["loader", "dataClient"],
  components: { ModalVideoInvoices, TitleHeaderTable },
  data() {
    return {
      dateCurrent: moment().format("D [de] MMMM [del] YYYY HH:mm:ss a"),
      modalOpen: false      
    };
  }
};
</script>

<style scoped>
  .data__container{
    font-size: 14px;
  }
</style>