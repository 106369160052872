<template>
  <div>
    <v-container fluid style="background-color: #fff">  
      <modal-select-manager v-if="$hasAnyRole(['IELManager'])" v-model="isOpenSelectManager"/>     
      <div v-if="!isDisablePage">
        <v-row class="mt-3 ml-5 mr-5 pa-2" style="box-shadow: inset 0px 0px 6px #FE080829, 0px 0px 6px #F8090929;" >
          <v-col cols="12" sm="6" md="5" lg="2" class="justify-center mt-2">
          <ManagerCard @managerLoaded="setManagerData" />
          </v-col>
          <v-col cols="12" sm="12" md="7" lg="10" class="mb-0 pb-0"> 
                <AverageOrders  :manager="manager" :managerData="managerData"  />
                <NetSales :managerData="managerData" :manager="manager" />
          </v-col>
          <v-col cols="12" > 
                <Billing :manager="manager"  :managerData="managerData" @desactiveLoader="finishBilling = true"/>
          </v-col>
 
        </v-row>
        <v-row>
          <v-col cols="12">
            <fill-rate 
            v-if="managerData"
            :userId="managerData.PERNR" 
            :option="managerData.VTWEG=='PR'?'SG':'G'"
            :chnl="managerData.VTWEG" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <SellerList :manager="manager" :managerData="managerData" @desactiveLoader="finishSeller = true"></SellerList>
          </v-col>
        </v-row>
        <v-dialog
          v-model="showModal"
          :max-width="paymentHistory ? 1100 : customerSales ? 1100 : 500"
          :persistent="showPopulationInput"
        >
          <v-card class="pb-4">
            <CustomerSalesVue
              v-if="customerSales"
              v-on:closeModal="closeModal"
            ></CustomerSalesVue>
            <CustomersBuyWeekly
              v-if="customersBuyWeekly"
              v-on:closeModal="closeModal"
            ></CustomersBuyWeekly> 
            <PaymentInfo
              v-if="paymentInfo"
              v-on:closeModal="closeModal"
            ></PaymentInfo>
          </v-card>
        </v-dialog>
      
        <LegacyFooter></LegacyFooter>
      </div>            
    </v-container>
  </div>
</template>
<script>
import CustomerSalesVue from "./modals/CustomerSales.vue";
import CustomersBuyWeekly from "./modals/CustomersBuyWeekly.vue"; 
import PaymentInfo from "./modals/PaymentInfo.vue"; 
//import MenuPrint from "@/components/MenuPrint.vue";
import ManagerCard from './ManagerCard.vue'
import AverageOrders from './AverageOrders.vue'
import LegacyFooter from "@/views/seller/components/LegacyFooter.vue"
import NetSales from './NetSales.vue'
import Billing from './Billing.vue'
import SellerList from './SellerList.vue'
import MixinService from '@/mixins/service'
import { mapActions } from "vuex";
import ModalSelectManager from './components/ModalSelectManager.vue';
import FillRate from "@/views/fill_rate/FillRate.vue";
export default {
  name: "ManagerHome",
  mixins: [MixinService],
  components: {
    CustomerSalesVue,
    CustomersBuyWeekly, 
    PaymentInfo, 
   // MenuPrint,
    ManagerCard,
    AverageOrders,
    NetSales,
    Billing,
    SellerList,
    LegacyFooter,
    ModalSelectManager,
    FillRate
  },
  beforeRouteEnter(to, from, next) {
      //se ejecuta el loader solo si venimos desde el componente SignIn
      next(vm => {
          if (from.name === 'SignIn') {
              vm.activeLoader();
          }
      });
  },
  created(){    
    this.manager= 0;
  },
  mounted(){
    if(this.$hasAnyRole(['IELManager']) && !this.$getLocalStorageManager()){
      this.isDisablePage = true;
      this.isOpenSelectManager = true;
      this.desactiveLoader();
    }
  },
  watch: {
    trim(value) {
      this.monthly = value ? false : true;
    },
    monthly(value) {
      this.trim = value ? false : true;
    },
    finishSeller(){
      this.validateLoader();
    },
    finishBilling(){
      this.validateLoader();
    },
  },


  data: () => ({
    finishSeller: false,
    finishBilling: false,
    manager:0, //Mientras se resuelve el servicio de información
    valid: true,
    showModal: false,
    checkbox: false,
    monthly: false,
    showPopulationInput: false,
    populationInput: "",
    trim: true,
    hidden: true,
    paymentHistory: false,
    customerSales: false,
    customersBuyWeekly: false,
    paymentInfo: false,
    populationRules: [
      (v) => !!v || "Este campo es requerido",
      (v) =>
        (v && v.length <= 10) || "Este campo debe ser menor a 10 caracteres",
    ],
    managerData:[],
    channel:"",
    isDisablePage: false,
    isOpenSelectManager: false
  }),
  methods: { 
    ...mapActions({
        activeLoader: 'loader/activeLoader',
        desactiveLoader: 'loader/desactiveLoader'
    }),
    setManagerData(managerData){ 
      this.managerData = managerData;
      this.manager = managerData.PERNR;
      this.channel = managerData?.VTWEG ?? 'DT';
    },

printPage(){

},
closeModal() {
  this.showModal = false;
},
validateLoader() {
    if(this.finishSeller && this.finishBilling){
        this.desactiveLoader();
    }
  },
},
};
</script>
<style scoped src="../../../public/css/managercustom.css"></style>
