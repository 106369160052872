<template>
  <v-dialog
      v-model="showMap"
      persistent
      transition="dialog-bottom-transition"
      :max-width="900">  

      <v-card>
        <!-- Header -->
        <v-toolbar :dark="true">
          <v-toolbar-title>Ubicación</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="right" @click="showMap = false" icon>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- End Header -->
        
        <v-card-text class="pt-5">
          <div class="row d-flex align-items-center">
            <div class="col-12 col-md-8 text-left">
              <h5 class="font-weight-bold">Domicilio fiscal:</h5>
              <h3 style="font-size: 16px">{{ taxAddress }}</h3>
            </div>
            <div class="col-12 col-md-4 text-center">
              <v-btn
                v-if="showEditMap && !$hasAnyRole(['Customer', 'CustomerPR'])"
                class="text-capitalize custom-red-button font-size-x w-100"
                style="max-width: 350px"
                color="error"
                dark
                small
                rounded
                @click="setMapLocation()"
              >
                {{ updateLocation ? "Actualizar" : "Registrar" }} Coordenadas
              </v-btn>
            </div>
          </div>

          <div class="py-5">
            <div v-if="!showEditMap && geolocation.lat == '' && geolocation.lng == ''">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
              <small class="text-secondary">Cargando mapa</small>
            </div>

            <div v-else-if="geolocation.lat != '' && geolocation.lng != ''">
              <!-- Map -->
              <GmapMap
                :center="geolocation"
                :zoom="16"
                style="width: 100%; height: 330px">
                <GmapMarker 
                  :position="geolocation"
                  :draggable="!showEditMap"
                  @dragend="draggableMarker">

                  <GmapInfoWindow :options="{ maxWidth: 200 }" :opened="!showEditMap">
                    <p>Puede arrastrar el marcador a la posición correcta</p>                    
                  </GmapInfoWindow>
                </GmapMarker>
              </GmapMap>
              <!-- End Map -->
            </div>

            <div v-else class="text-center">
              <small class="text-secondary">No se ha registrado una ubicación</small>
            </div>
          </div>

          <div class="text-left">
            <span v-if="geolocation.updatedAt != ''">Última actualización {{ formatDate(geolocation.updatedAt) }}</span>
          </div>
          <div v-if="showBtnActions" class="d-flex justify-content-center">
            <!-- Button Accept -->
            <v-btn
              v-if="geolocation.lat != '' && geolocation.lng != ''"
              @click="acceptLocation()"
              color="success"
              class="ma-2 white--text"
              style="border-width: 2px; background: #4caf50 0% 0% no-repeat padding-box; border: 2px solid #05741f !important;">
                Confirmar
                <v-icon right dark> mdi-check-bold </v-icon>
            </v-btn>
            <!-- End Button Accept -->

            <!-- Button Cancel -->
            <v-btn
              @click="cancelLocation()"
              color="red"
              class="ma-2 white--text"
              style="border-width: 2px; background: #e20028 0% 0% no-repeat padding-box; border: 2px solid #890707 !important;">
                Cancelar
                <v-icon right dark> mdi-close </v-icon>
            </v-btn>
            <!-- End Button Cancel -->
          </div>
        </v-card-text>
      </v-card>
  </v-dialog>
</template>

<script>
import axios from "@/plugins/axios";
import moment from "moment";
export default {
  name: "LocationMaps",
  props: {
    taxAddress:{
      type: String,
      required: true
    },
    value: { 
      type: Boolean,
      default: false
    },
    customerCode:{
      type: String,
      required: true
    }
  },
  
  data(){
    return {     
      geolocation: {
        lat: '',
        lng: '',
        updatedAt: ''
      },
      updateLocation: false,
      showEditMap: false,
      showBtnActions: false,      
      lastGeolocation: null,
      apiKey: process.env.VUE_APP_GOOGLE_MAPSKEY      
    }
  },

  methods: { 
    async getLocationCustomer() {
      this.geolocation = { lat: '', lng: '', updatedAt: ''};
      this.updateLocation = false;
      this.showEditMap = false;
      this.showBtnActions = false; 

      try{
        const response = await axios.get(`cover/location-customer/${this.customerCode}`);        
        const data = response.data.data;   
        if(data != null){
          this.geolocation = {
            lat: data.latitude,
            lng: data.longitude,
            updatedAt: data.updated_at
          };                
          this.updateLocation = true;
        }   

        this.showEditMap = true;
      }catch(error){
        this.showMap = false;
        this.$toast.error('Error al obtener la ubicación del mapa. Por favor inténtelo nuevamente.');
      }
    },

    async storeLocationCustomer(){  
      try{
        await axios.post('cover/location-customer', {          
            customer: this.customerCode,
            latitude: this.geolocation.lat,
            longitude: this.geolocation.lng                  
        });        
        this.geolocation.updatedAt = moment(); 
        this.updateLocation = true; 

        this.$toast.success('Ubicación registrada con éxito.');
      }catch(error){         
        this.$toast.error('Error al registrar la ubicación. Inténtelo nuevamente.');
      }  
    },

    async updateLocationCustomer(){
      try{
        await axios.put('cover/location-customer', {
          customer: this.customerCode,
          latitude: this.geolocation.lat,
          longitude: this.geolocation.lng
        });

        this.geolocation.updatedAt = moment();
        this.$toast.success('Ubicación actualizada con éxito.');
      }catch(error){    
        this.$toast.error('Error al actualizar la ubicación. Inténtelo nuevamente.');
      }  
    },        

    cancelLocation(){
      this.geolocation = this.lastGeolocation;
      this.resetButtonState();
    },

    acceptLocation(){
      this.resetButtonState();  
      this.updateLocation ? this.updateLocationCustomer() : this.storeLocationCustomer()
    },

    async setMapLocation(){
      //se guardan los datos al cancelar el cambio
      this.lastGeolocation = this.geolocation;
      this.geolocation = { lat: '', lng: '', updatedAt: ''};
      this.showEditMap = false;
      this.showBtnActions = true;   

      const permissionsGranted = await this.validateBrowserGeolocation();
      if (!permissionsGranted) {
        this.$swal({
          text: "Antes de registrar coordenadas tienes que activar tu geolocalización",
          icon: "warning",
          confirmButtonColor: "#ec0707",
        });
      }
      // Obtener la ubicación del usuario
      navigator.geolocation.getCurrentPosition((position) => {
        this.geolocation.lat = position.coords.latitude;
        this.geolocation.lng = position.coords.longitude;      
      });
    },  

    async validateBrowserGeolocation() {
      if ("permissions" in navigator && "geolocation" in navigator) {
        const permissionStatus = await navigator.permissions.query({ name: "geolocation" }); 
        return permissionStatus.state === "granted";
      } 
      return false;      
    },

    resetButtonState() {
      this.showEditMap = true;
      this.showBtnActions = false;      
    },

    draggableMarker(event) {
      this.geolocation.lat = event.latLng.lat();
      this.geolocation.lng = event.latLng.lng();  
    },

    formatDate(value) {
      return moment(value).format("DD-MMMM-YYYY");
    },
  },

  computed:{
    showMap: { 
      set(value) { 
        this.$emit('input', value);
      },
      get(){
        return this.value;
      }
    }    
  },

  watch:{
    showMap(newValue){
      if(newValue){
        this.getLocationCustomer();
      }
    }
  }
};
</script>