<template>
  <v-data-table
    :search="search"
    :headers="tableHeaders"
    :items="items"
    :items-per-page="15"
    class="elevation-2 table-renglonaje  tabla-zebra mt-0"
  >
  <template v-slot:[`item.amount`]="{ item }">
    <span>$ {{item.amount}}</span>
  </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        large
        @click="$emit('onPDFClick', item.actions, 2)"
        color="#218838"
      >
        mdi-file-xml-box
      </v-icon>
      <v-icon
        large
        @click="$emit('onPDFClick', item.actions, 1)"
        color="red darken-2"
      >
        mdi-file-pdf-box
      </v-icon>
      <a
      :href="item.accuse"
      target="_blank"
      v-if="item.accuse != ''"
      >
      <v-icon
      dense
      large
      color="green"
      >
      mdi-file-download      
      </v-icon>
      </a>
    </template>
    <template v-slot:[`item.pay_complement`]="{ item }">
      <a
        href="#"
        v-if="item.pay_complement != ''"
        @click="$emit('onPDFClick', item.pay_complement, 2)"
      >
        <v-icon dense large color="#218838"> mdi-file-xml-box </v-icon>
      </a>
      <a
        v-if="item.pay_complement != ''"
        @click="$emit('onPDFClick', item.pay_complement, 1)"
      >
        <v-icon dense large color="red"> mdi-file-pdf-box </v-icon>
      </a>
    </template>
  </v-data-table>
</template>
<script>
import moment from 'moment';

const headerHistory = [
  { text: "Tipo Documento", align: "start", sortable: false, value: "type" },
  { text: "# Documento", value: "number" },
  { text: "Fecha de Factura", value: "date",
    sort: (a, b) => {
      const dateA = moment(a, 'DD/MM/YYYY');
      const dateB = moment(b, 'DD/MM/YYYY');
      return dateA.diff(dateB);
    }
   },
  { text: "Fecha de Vencimiento", value: "date_overdue",
    sort: (a, b) => {
      const dateA = moment(a, 'DD/MM/YYYY');
      const dateB = moment(b, 'DD/MM/YYYY');
      return dateA.diff(dateB);
    }
   },
  { text: "Importe", value: "amount" },
  { text: "XML/PDF/ACUSE", value: "actions", sortable: false },
  { text: "Complemento de Pago", sortable: false, value: "pay_complement" },
];

export default {
  name: "AccountStatusTable",
  props: {
    items: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      default: null,
    },
    loading: Boolean,
    fullTable: Boolean,
    variant: Boolean,
  },
  data() {
    return {
      tableHeaders: headerHistory,
    };
  },
};
</script>
