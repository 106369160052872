<template>
  <div>
    <v-btn
      @click="launchPasswordStep"
      class="text-capitalize button-red-round"
      dark
      small
    >
      Cambiar password
    </v-btn> 
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ChangePasswordControl',

  props: {
    isLarge: {

    }
  },

  data() {
    return {
      loading: false, 
    };
  },

  methods: {
    ...mapActions('modalStepsAfterLogin', [
      'updateModalState',
      'findStepByKey',
      'changeCurrentStepValue',
      'updateStepStatus',
      'loadUpdatePasswordStep'
    ]),
    ...mapActions("updatePassword", ["setWantUpdatePassword"]), 
    
    async launchPasswordStep () {
      this.loading = true; 
      try {
        this.setWantUpdatePassword(true); 
        await this.loadUpdatePasswordStep();

        const updatePasswordStep = await this.findStepByKey('updatePassword');

        if (updatePasswordStep) {
          updatePasswordStep.visited = true;

          if (updatePasswordStep.doneRequired === true) {
            updatePasswordStep.doneRequired = false;
          }

          this.updateStepStatus(updatePasswordStep);
          this.changeCurrentStepValue(updatePasswordStep.value);
          this.updateModalState(true);
        }
      } finally {
        this.loading = false; 
      }
    }
  }
};
</script>