<template>
  <div v-if="authenticated">
    <v-app-bar color="grey darken-3" elevation="24" >
      <div class="d-flex align-items-center">
        <v-toolbar-title>
          <router-link :to="{ name: 'Home' }" class="nav-link active">
            <img src="/img/propuesta-1-x2.svg" style="height: 45px" alt="" class="mr-4">
          </router-link>
        </v-toolbar-title>

        <img src="img/service_desk.svg" style="height: 40px" alt="">
      </div>

      <v-spacer></v-spacer>     

      <span
        class="d-none d-md-block"
        style="
          color: white;
          font-weight: bold;
          padding-right: 1em;
          font-size: 16px;
        "
        >{{ currentDate }} {{clock}}</span
      >
      <router-link v-if="isCustomer" :to="{ name: 'ProfileUser' }" class="nav-link">
        <v-btn icon>
          <v-icon style="color: #FFF;">fas fa-user-circle</v-icon>
        </v-btn>
      </router-link>
      <span class="d-none d-md-block" style="text-transform: none; color: #FFF">{{ user.name }}</span>

      <notifications></notifications>

      <reminder-step-launcher v-if="$hasAnyRole(['Seller', 'Customer', 'CustomerPR','Manager','Submanager','RegionalManager','IELExpressSales'])" />
      <!--<v-btn
        icon
        style="color: #FFF;"
        v-if="!$hasAnyRole(['Administrator']) && this.user.VTWEG == 'DT'"
        @click="goTo('ShoppingCart')"
      >
        <v-badge :content="cartItems" :value="cartItems" color="green" overlap>
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>
      <v-btn
        icon
        style="color: #FFF;"
        v-if="!$hasAnyRole(['Administrator']) && this.user.VTWEG == 'PR'"
        @click="goTo('ShoppingCartPR')"
      >
        <v-badge :content="messages" :value="messages" color="green" overlap>
          <v-icon>mdi-cart</v-icon>
        </v-badge>
      </v-btn>-->

      <MenuPrint></MenuPrint>

      <v-btn icon @click.prevent="signOut" color="grey darken-3">
              <v-icon style="color: #FFF;">mdi-logout</v-icon>
      </v-btn>      

    </v-app-bar>

    
    
      <!-- start seller  
      <Breadcrumb   v-if="$hasAnyRole(['Seller'])"></Breadcrumb>
      end seller -->

    
    <!--
    <v-toolbar
      flat
      style="background-color: red"
      class="d-flex d-lg-none justify-center"
      elevation="5"
      v-if="!$hasAnyRole(['Manager', 'Seller'])"
    >
      <span style="color: white; font-weight: bold; font-size: 0.7em">{{
        currentDate
      }} {{clock}}</span>
      <v-btn
        type="submit"
        rounded
        :disabled="dialogLoading"
        style="
          background-color: #28a745;
          color: #fff;
          text-transform: none;
          font-weight: 500;
        "
        @click="getCoverPDF"
      >
        {{ buttonDowloandView }}
      </v-btn>
    </v-toolbar> -->

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <template v-for="(item, index) in menu">
            <router-link
              :key="`menu-${index}-${item.routeName}`"
              v-if="
                $hasAnyRole(item.roles) || $hasAnyPermission(item.permissions)
              "
              :to="{ name: item.routeName, params: { doctype:item.param, idq:item.idq}}"
              :class="item.class"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </template>
          <!-- <v-list-item
            @click.prevent="showLoginDialog"
            v-if="$hasAnyRole(['Manager', 'Seller']) || $hasAnyPermission([])"
          >
            <v-list-item-icon>
              <v-img src="/img/logo_iusa.png"></v-img>
            </v-list-item-icon>
            <v-list-item-title>Ir a VEL</v-list-item-title>
          </v-list-item> -->
          <v-list-item @click.prevent="signOut">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog v-model="loginVelDialog.show" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Iniciar sesión en Vendedor en línea
        </v-card-title>

        <v-card-text>
          <v-row class="mt-4">
            <v-col cols="12">
              <v-text-field
                outlined
                dense
                label="Contraseña VEL"
                v-model="loginVelDialog.password"
                type="password"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancelLoginVel"> Cancelar </v-btn>
          <v-btn color="primary" @click="loginVel"> Entrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Notifications from "./Notifications.vue";
import ReminderStepLauncher from './stepsAfterLogin/ReminderStepLauncher.vue';

import moment from "moment";
import axios from "@/plugins/axios";
import MenuPrint from "./MenuPrint.vue"
import global from "@/mixins/global";

const permissionsOrder = [
  "order.show",
  "order.create",
  "order.update",
  "order.delete",
];

export default {
  components: { Notifications, MenuPrint, ReminderStepLauncher },
  props: {
    visible:{default:false},
    onlyNav:{default:false},
  },
  mixins:[global],
  data: () => ({
    clock: '',
    velUrl: process.env.VUE_APP_URL_PORTAL_VENDEDOR,
    drawer: false,
    group: null,
    messages: null,
    permissionsOrder: permissionsOrder,
    currentDate: null,
    dialogLoading: false,
    buttonDowloandView: "Descargar Vista",
    cartItems: null,
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
    menu: [
      {
        routeName: "Home",
        class: "nav-link",
        text: "Inicio",
        icon: "mdi-home",
        roles: [
          "Administrator",
          //"Customer",
          "Guest",
          // "Manager",
          "Reports",
          //"CustomerPR",
        ],
        permissions: [],
      },
      {
        routeName: "Administrator",
        class: "nav-link",
        text: "Administrador",
        icon: "mdi-home",
        roles: ["Administrator"],
        permissions: [],
      },
      {
        routeName: "EmployeesView",
        class: "nav-link",
        text: "Empleados",
        icon: "mdi-account-multiple",
        roles: ["Administrator"],
        permissions: [],
      },
      {
        routeName: "ProfileUser",
        class: "nav-link",
        text: "Cuenta",
        icon: "mdi-account",
        roles: [
          "Administrator",
          //"Customer",
          "Guest",
          // "Manager",
          "Reports",
          //"CustomerPR",
        ],
        permissions: [],
      },
      {
        routeName: "ReportStock",
        class: "nav-link",
        text: "Reporte Existencias",
        icon: "mdi-text-box-outline",
        roles: [""],
        permissions: [],
      },
      {
        routeName: "CustomerRegistration",
        class: "nav-link",
        text: "Solicitud Alta Cliente",
        icon: "mdi-bookmark-check",
        roles: [
          "Guest",
          //  "Manager"
        ],
        permissions: [],
      },
      {
        routeName: "ShoppingCart",
        class: "nav-link",
        text: "Pedido Normal",
        icon: "mdi-cart",
        roles: [
         // "Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },
     /* {
        routeName: "ShoppingCart",
        class: "nav-link",
        text: "Pedido",
        icon: "mdi-cart",
        param: "PSDT",
        idq:0,
        roles: [
          "Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },*/
      {
        routeName: "ShoppingCartPop",
        class: "nav-link",
        text: "Pedido POP",
        icon: "mdi-cart",
        roles: [
          //"Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },
      {
        routeName: "ShoppingCartPdr",
        class: "nav-link",
        text: "Pedido de Reorden",
        icon: "mdi-cart",
        roles: [
          //"Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },
      /*{
        routeName: "ShoppingCartPsma",
        class: "nav-link",
        text: "Pedido Apoyo a Marca",
        icon: "mdi-cart",
        roles: [
          "Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },*/
      {
        routeName: "ShoppingCartPsnw",
        class: "nav-link",
        text: "Pedido Nuevos Productos",
        icon: "mdi-cart",
        roles: [
          "Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },

     /* {
        routeName: "ShoppingCartPstn",
        class: "nav-link",
        text: "Pedido Tienda Nueva",
        icon: "mdi-cart",
        roles: [
          "Customer",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },*/
      {
        routeName: "ShoppingCartPR",
        class: "nav-link",
        text: "Pedido PR",
        icon: "mdi-cart",
        roles: [""],
        permissions: permissionsOrder,
      },
      {
        routeName: "ShoppingCartPopPr",
        class: "nav-link",
        text: "Pedido POP",
        icon: "mdi-cart",
        roles: [
          //"CustomerPR",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },
      {
        routeName: "ShoppingCartPdrPr",
        class: "nav-link",
        text: "Pedido de Reorden",
        icon: "mdi-cart",
        roles: [
          //"CustomerPR",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },
      /*{
        routeName: "ShoppingCartPsmaPr",
        class: "nav-link",
        text: "Pedido Apoyo a Marca",
        icon: "mdi-cart",
        roles: [
          "CustomerPR",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },*/
      {
        routeName: "ShoppingCartPsnwPr",
        class: "nav-link",
        text: "Pedido Nuevos Productos",
        icon: "mdi-cart",
        roles: [
          //"CustomerPR",
          // "Manager"
        ],
        permissions: permissionsOrder,
      },
      {
        routeName: "upload-file",
        class: "nav-link",
        text: "Pedido CSV",
        icon: "mdi-file",
        roles: [
          //"Customer",
          // "Manager"
        ],
        permissions: [],
      },
      {
        routeName: "QuoterHistory",
        class: "nav-link",
        text: "Cotizador",
        icon: "mdi-cart-arrow-down",
        roles: [""],
        permissions: [],
      },
      {
        routeName: "DiscontinuedMaterial",
        class: "nav-link",
        text: "Materiales Descontinuados",
        icon: "mdi-cart-off",
        roles: [""],
        permissions: [],
      },
      {
        routeName: "DiscontinuedMaterialPR",
        class: "nav-link",
        text: "Materiales Descontinuados",
        icon: "mdi-cart-off",
        roles: [""],
        permissions: [],
      },
      {
        routeName: "HistoryOrders",
        class: "nav-link",
        text: "Historial de Pedidos",
        icon: "mdi-cart",
        roles: [
          //"Customer",
          //"CustomerP",
          // "Manager"
        ],
        permissions: [],
      },
      {
        routeName: null,
        class: "nav-link",
        text: "Promociones y descuentos",
        icon: "mdi-star",
        roles: ["Guest", 
        //"CustomerPR"
      ],
        permissions: [],
      },
      {
        routeName: "Manager",
        class: "nav-link",
        text: "Solicitudes Alta",
        icon: "mdi-account-box-outline",
        roles: [ "Guest", "Manager", "Reports"],
        permissions: [],
      },
      {
        routeName: "Planeador",
        class: "nav-link",
        text: "Planeador",
        icon: "mdi-calendar",
        roles: ["Manager", "Seller"],
        permissions: [],
      },
      {
        routeName: "MultimediaCenter",
        class: "nav-link",
        text: "Lista de Precios Vigentes",
        icon: "mdi-format-list-bulleted-square",
        roles: [
          //"Customer",
          "Guest",
          "Manager",
          "Seller",
          "Reports",
          //"CustomerPR",
        ],
        permissions: [],
      },
      {
        routeName: "PromissoryNotes",
        class: "nav-link",
        text: "Solicitudes Pagaré",
        icon: "mdi-account-box-outline",
        roles: ["Manager"],
        permissions: [],
      },
      // { routeName: null, class: 'nav-link', text: 'Productos', icon: 'mdi-tag-multiple', roles: ["Administrator", 'Customer', 'Guest'] },
      // { routeName: null, class: 'nav-link', text: 'Familias', icon: 'mdi-card-bulleted-outline', roles: ["Administrator", 'Customer'] },
      {
        routeName: null,
        class: "nav-link",
        text: "Oportunidad de Mercado",
        icon: "mdi-chart-areaspline",
        roles: [""],
        permissions: [],
      },
      {
        routeName: null,
        class: "nav-link",
        text: "Movimientos Cuenta",
        icon: "mdi-arrow-collapse-down",
        roles: [""],
        permissions: [],
      },
      {
        routeName: null,
        class: "nav-link",
        text: "Tienda de Éxito",
        icon: "mdi-storefront-outline",
        roles: [],
        permissions: [],
      },
      {
        routeName: "MultimediaCenterVideo",
        class: "nav-link",
        text: "Multimedia Center",
        icon: "mdi-folder-multiple-image",
        roles: [
          //"Customer",
          "Guest",
          "Manager",
          "Seller",
          "Reports",
          //"CustomerPR",
        ],
        permissions: [],
      },
      {
        routeName: "ContactList",
        class: "nav-link",
        text: "Contacto",
        icon: "mdi-account",
        roles: [""],
        permissions: [],
      },
      {
        routeName: "CustomerAccountStatement",
        class: "nav-link",
        text: "Estados",
        icon: "mdi-account-multiple",
        roles: [""],
        permissions: [],
      },
    ],
    customerBreadcrumb: [
      {
        text: "Carátula IEL",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Vendedores",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Sureste",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Zona (12730)",
        disabled: false,
        href: "breadcrumbs_dashboard",
      },
      {
        text: "Jesús Gutierrez Perez (2535171)",
        disabled: true,
        href: "breadcrumbs_dashboard",
      },
    ],    
    loginVelDialog: {
      show: false,
      password: null,
    },
    customer_code:null,
  }),
  watch: {
  // user: {
  //   handler(newValue) {
  //     this.menuDropdown.pedidos[0].path = newValue.VTWEG == 'DT' ? "ShoppingCart" : "ShoppingCartPR";
  //   },
  //   deep: true,
  //   immediate: true
  // }
},
  async mounted() {     
    this.currentDate = moment().format("D [de] MMMM [del] YYYY");
    setInterval(() => this.clock = moment().format("h:mm a"), 1000)

    this.cartItems = this.cartIte.length;
    //this.customer_code = this.user.custumer_code;
    
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      cartIte: "ordercart/cart",
    }),
    isCustomer(){  
    return this.$route.meta.role == 'Customer' ? true :false;
    },
  },
  methods: {
    ...mapActions({
      signOutAction: "auth/signOut",
      signInVel: "auth/sigInVel",
    }),
    getCoverPDF() {
      this.dialogLoading = true;
      this.buttonDowloandView = "Descargarndo...";
      axios
        .post(
          "get-cover-info",
          {
            order_id: 96,
            customer: this.user.custumer_code,
            VKORG: this.user.VKORG,
            VTWEG: this.user.VTWEG,
            VKBUR: this.user.VKBUR,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          this.buttonDowloandView = "Descargar Vista";
          console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "caratula.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.buttonDowloandView = "Descargar Vista";
          console.log(error);
          this.dialogLoading = false;
        })
        .finally(
          () => ((this.dialogLoading = false), (this.submitted = false))
        );
    },
    signOut() {
      this.signOutAction().then(() => {
        this.$router.replace({
          name: "SignIn",
        });
      });
    },
    goTo(routeName) {
      this.$router.push({ name: routeName });
    },
    showLoginDialog() {
      this.signInVel(this.loginVelDialog.password).then((data) => {
        console.log(data);
        this.cancelLoginVel();
        if (data.user == 2) {
          window.open(`${this.velUrl}/validate?token=${data.token}`, "_blank");
        } else {
          this.$toast.warning("Los datos no son correctos.");
        }
      });
    },
    loginVel() {
      console.log("login vel");
      this.signInVel(this.loginVelDialog.password).then((data) => {
        console.log(data);
        this.cancelLoginVel();
        if (data.user == 2) {
          window.open(`${this.velUrl}/validate?token=${data.token}`, "_blank");
        } else {
          this.$toast.warning("Los datos no son correctos.");
        }
      });
    },
    cancelLoginVel() {
      this.loginVelDialog.password = null;
      this.loginVelDialog.show = false;
    },
    
  },
};
</script>
<style>
/* .grey.darken-3 {
  background-color: #2b2b2b !important;
  border-color: #2b2b2b !important;
}
.v-menu__content {
  overflow-y: inherit !important;
  overflow-x: inherit !important;
  contain: inherit !important;
  min-width: auto !important;
} */

/* @media (max-width: 1263px) {
  .v-menu__content {
    width: auto;
    left: 0 !important;
    right: 0 !important;
    margin: 0 auto;
    position: absolute !important;
  }
} */
</style> 